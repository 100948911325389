import React, { useContext, useEffect, useState } from "react";
import "./style.css";

import image1 from "../../Assets/item 1.png";
import image2 from "../../Assets/item 2.png";
import image3 from "../../Assets/item 3.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataContext } from "../../Context/Context";
import DynamicSeoPage from "../DynamicSeoPage";

const BlogPage = () => {
  const { setBlogs, blogs } = useContext(DataContext);
  const [section, setSection] = useState({});

  const fetchSection = async () => {
    try {
      const response = await axios.get(
        `https://365luxuryhomes.com/api/sections/blog`
      );

      // Ensure the response has data
      if (response.data) {
        setSection(response.data); // Assuming `setSections` is the correct state setter
      } else {
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    fetchSection();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`https://365luxuryhomes.com/api/blogs`);

      // Ensure the response has data
      if (response.data) {
        setBlogs(response.data); // Assuming `setSections` is the correct state setter
      } else {
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const truncateDescription = (content, wordLimit) => {
    const plainText = stripHtmlTags(content);
    const words = plainText.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return plainText;
  };

  return (
    <div className="bg-white">
      {/* Header Section */}
      <DynamicSeoPage page="blog" />
      <header
        className="blog_banner relative w-full h-[400px] bg-cover bg-center py-10"
        style={{
          backgroundImage: `url(${section?.imageUrl || ""})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container mx-auto h-full flex items-end justify-start">
          <h1 className="text-white text-4xl font-bold uppercase">
            Luxury Dubai Real Estate Blog
          </h1>
        </div>
      </header>

      {/* Articles Section */}
      <section className="container mx-auto px-4 md:px-8 lg:px-16">
        <div className="mt-14">
          <div className="flex items-end gap-2 mb-4">
            <div className="w-20 h-[2px] bg-[#aca9a5]"></div>
            <h2 className="lg:text-2xl md:text-2xl text-xl font-thin text-[#aca9a5] uppercase leading-none -mt-1">
              RECENT ARTICLES
            </h2>
          </div>
          <p className="font-thin text-[#aca9a5] text-[14px] leading-[18px]">
            Discover the finest luxury home rentals in Dubai’s most exclusive
            neighborhoods. Our blogs offer insights into premium properties,
            lifestyle tips, and the best of Dubai living. Stay updated on the
            ultimate luxury rental experiences.
          </p>
        </div>

        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 py-10">
          {blogs.map((blog) => (
            <Link
              to={`/blog/${blog.slug}`}
              key={blog?._id}
              className="hover:no-underline active:text-[#aca9a5] focus:text-[#aca9a5] active:no-underline focus:no-underline"
            >
              <div className="w-full">
                <img
                  src={blog?.mainImage}
                  alt=""
                  className="h-48 w-full relative z-10"
                />
              </div>
              <div className="flex flex-col items-start justify-center">
                <h3 className="font-medium text-[#aca9a5] text-[16px] leading-[24px] my-2">
                  {blog?.title}
                </h3>
                <p className="font-thin text-[14px] leading-[24px] text-[#aca9a5]">
                  {truncateDescription(blog?.content, 24)}
                </p>
                <div className="flex items-center justify-between w-full mt-3">
                  <div className="text-[#aca9a5] flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={24}
                      height={24}
                      className="text-inherit"
                      fill={"none"}
                    >
                      <path
                        d="M18 2V4M6 2V4"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.9955 13H12.0045M11.9955 17H12.0045M15.991 13H16M8 13H8.00897M8 17H8.00897"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.5 8H20.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.5 12.2432C2.5 7.88594 2.5 5.70728 3.75212 4.35364C5.00424 3 7.01949 3 11.05 3H12.95C16.9805 3 18.9958 3 20.2479 4.35364C21.5 5.70728 21.5 7.88594 21.5 12.2432V12.7568C21.5 17.1141 21.5 19.2927 20.2479 20.6464C18.9958 22 16.9805 22 12.95 22H11.05C7.01949 22 5.00424 22 3.75212 20.6464C2.5 19.2927 2.5 17.1141 2.5 12.7568V12.2432Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 8H21"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="text-[15px] leading-none">
                      {blog?.date}
                    </span>
                  </div>
                  <Link
                    to={`/blog/${blog.slug}`}
                    className="flex items-end bg-[#aca9a5] px-4 text-[14px] font-thin py-[4px] text-[#fff] gap-2 hover:no-underline hover:text-[#fff] hover:bg-[#6c675b]"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </section>
      <div className="flex flex-col items-center justify-center lg:py-20 md:py-14 lg:mb-0 md:mb-0 mb-10">
        <a
          href="#banner"
          className="text-[#aca9a5] hover:no-underline hover:text-[#aca9a5]"
        >
          Go To Top
        </a>
        <div className="w-[2px] h-14 bg-[#aca9a5]"></div>
      </div>
    </div>
  );
};

export default BlogPage;
