import axios from "axios";

export const fetchListingFeeSettings = async (listingId) => {
  try {
    const tokenResponse = await axios.post(
      "https://365luxuryhomes.com/api/accessToken"
    );
    const accessToken = tokenResponse.data.access_token;

    const url = `https://api.hostaway.com/v1/listingFeeSettings/${listingId}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Cache-Control": "no-cache",
      },
    });

    if (response.data && response.data.result) {
      return response.data.result;
    } else {
      throw new Error(
        "Failed to fetch listing settings: Unexpected API response."
      );
    }
  } catch (error) {
    console.error(
      "Error fetching listing fee settings:",
      error.response?.data || error.message
    );
    throw new Error(
      error.response?.data?.message || "Failed to fetch listing fee settings."
    );
  }
};
