// import React, { useState, useEffect, useContext } from "react";
// import "../Styles/customDateRangePicker.css";
// import { DataContext } from "../Context/Context";

// const CustomDateRangePicker = ({ onChange }) => {
//   const { calender, setEndDate, endDate, setStartDate, startDate } =
//     useContext(DataContext);

//   const [hoveredDate, setHoveredDate] = useState(null);
//   const [isOpen, setIsOpen] = useState(false);
//   const [currentMonth, setCurrentMonth] = useState(new Date());
//   const [reservedDates, setReservedDates] = useState([]);

//   useEffect(() => {
//     // Map the calendar data to extract reserved dates
//     if (calender?.result?.length > 0) {
//       const reserved = calender?.result
//         .filter(
//           (entry) => entry.isAvailable === 0 || entry.status === "reserved"
//         )
//         .map((entry) => new Date(entry.date));
//       setReservedDates(reserved);
//     }
//   }, [calender?.result]);

//   const togglePicker = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleDateClick = (date) => {
//     if (!startDate || (startDate && endDate)) {
//       setStartDate(date);
//       setEndDate(null);
//     } else if (startDate && !endDate && date > startDate) {
//       setEndDate(date);
//       setTimeout(() => setIsOpen(false), 200); // Auto-close after selection
//     }
//     onChange?.({ startDate: date, endDate }); // Notify parent of changes
//   };

//   const handleClear = (e) => {
//     e.stopPropagation();
//     setStartDate(null);
//     setEndDate(null);
//     setHoveredDate(null);
//     setIsOpen(false);
//     onChange?.({ startDate: null, endDate: null });
//   };

//   const handlePrevMonth = () => {
//     setCurrentMonth(
//       new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1)
//     );
//   };

//   const handleNextMonth = () => {
//     setCurrentMonth(
//       new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1)
//     );
//   };

//   const isReservedDate = (date) => {
//     return reservedDates.some(
//       (reservedDate) => date.toDateString() === reservedDate.toDateString()
//     );
//   };

//   const isPastDate = (date) => {
//     const today = new Date();
//     today.setHours(0, 0, 0, 0);
//     return date < today;
//   };

//   const renderCalendar = () => {
//     const daysInMonth = new Date(
//       currentMonth.getFullYear(),
//       currentMonth.getMonth() + 1,
//       0
//     ).getDate();

//     const startDay = new Date(
//       currentMonth.getFullYear(),
//       currentMonth.getMonth(),
//       1
//     ).getDay();

//     const days = [];
//     for (let i = 0; i < startDay; i++) {
//       days.push(null);
//     }
//     for (let i = 1; i <= daysInMonth; i++) {
//       days.push(
//         new Date(currentMonth.getFullYear(), currentMonth.getMonth(), i)
//       );
//     }

//     return (
//       <div className="calendar" id="calendar">
//         <div className="month-navigation">
//           <span className="nav-arrow" onClick={handlePrevMonth}>
//             &lt;
//           </span>
//           <div className="month">
//             {currentMonth.toLocaleDateString("en-US", {
//               month: "long",
//               year: "numeric",
//             })}
//           </div>
//           <span className="nav-arrow" onClick={handleNextMonth}>
//             &gt;
//           </span>
//         </div>
//         <div className="days">
//           {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
//             <div key={index} className="day-label">
//               {day}
//             </div>
//           ))}
//           {days.map((date, index) => {
//             const isReserved = date && isReservedDate(date);
//             const isPast = date && isPastDate(date);

//             return (
//               <div
//                 key={index}
//                 className={`day ${
//                   date
//                     ? isReserved || isPast
//                       ? "disabled-date"
//                       : date >= startDate &&
//                         ((!endDate && date <= hoveredDate) ||
//                           (endDate && date <= endDate))
//                       ? "hover-range"
//                       : ""
//                     : "empty-cell"
//                 } ${
//                   date?.toDateString() === startDate?.toDateString()
//                     ? "selected-start"
//                     : ""
//                 } ${
//                   date?.toDateString() === endDate?.toDateString()
//                     ? "selected-end"
//                     : ""
//                 }`}
//                 onClick={() =>
//                   date && !isReserved && !isPast && handleDateClick(date)
//                 }
//                 onMouseEnter={() => date && setHoveredDate(date)}
//               >
//                 {isReserved ? <del>{date?.getDate()}</del> : date?.getDate()}
//               </div>
//             );
//           })}
//         </div>
//       </div>
//     );
//   };

//   return (
//     <div className="date-picker-container">
//       <div className="date-picker-input" onClick={togglePicker}>
//         {startDate && endDate ? (
//           <>
//             {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}
//             <button className="clear-btn" onClick={handleClear}>
//               &times;
//             </button>
//           </>
//         ) : (
//           <span className="placeholder text-[15px]">
//             Select Start - End Date
//           </span>
//         )}
//       </div>

//       {isOpen && (
//         <div className="date-picker-popup shadow-lg">{renderCalendar()}</div>
//       )}
//     </div>
//   );
// };

// export default CustomDateRangePicker;

import React, { useState, useEffect, useContext } from "react";
import "../Styles/customDateRangePicker.css";
import { DataContext } from "../Context/Context";

const CustomDateRangePicker = ({ onChange }) => {
  const { calender, setEndDate, endDate, setStartDate, startDate } =
    useContext(DataContext);

  const [hoveredDate, setHoveredDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [reservedDates, setReservedDates] = useState([]);

  useEffect(() => {
    // Map the calendar data to extract reserved dates
    if (calender?.result?.length > 0) {
      const reserved = calender?.result
        .filter(
          (entry) => entry.isAvailable === 0 || entry.status === "reserved"
        )
        .map((entry) => new Date(entry.date));
      setReservedDates(reserved);
    }
  }, [calender?.result]);

  const togglePicker = () => {
    setIsOpen(!isOpen);
  };

  const handleDateClick = (date) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate(null);
    } else if (startDate && !endDate && date > startDate) {
      setEndDate(date);
      setTimeout(() => setIsOpen(false), 200); // Auto-close after selection
    }
    onChange?.({ startDate: date, endDate });
  };

  const handleClear = (e) => {
    e.stopPropagation();
    setStartDate(null);
    setEndDate(null);
    setHoveredDate(null);
    setIsOpen(false);
    onChange?.({ startDate: null, endDate: null });
  };

  const handlePrevMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1)
    );
  };

  const isReservedDate = (date) => {
    return reservedDates.some(
      (reservedDate) => date.toDateString() === reservedDate.toDateString()
    );
  };

  const isPastDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  };

  const renderCalendar = () => {
    const daysInMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      0
    ).getDate();

    const startDay = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      1
    ).getDay();

    const days = [];
    for (let i = 0; i < startDay; i++) {
      days.push(null);
    }
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(
        new Date(currentMonth.getFullYear(), currentMonth.getMonth(), i)
      );
    }

    return (
      <div className="calendar" id="calendar">
        <div className="month-navigation">
          <span className="nav-arrow" onClick={handlePrevMonth}>
            &lt;
          </span>
          <div className="month">
            {currentMonth.toLocaleDateString("en-US", {
              month: "long",
              year: "numeric",
            })}
          </div>
          <span className="nav-arrow" onClick={handleNextMonth}>
            &gt;
          </span>
        </div>
        <div className="days">
          {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
            <div key={index} className="day-label">
              {day}
            </div>
          ))}
          {days.map((date, index) => {
            const isReserved = date && isReservedDate(date);
            const isPast = date && isPastDate(date);

            return (
              <div
                key={index}
                className={`day ${
                  date
                    ? isReserved || isPast
                      ? "disabled-date"
                      : date >= startDate &&
                        ((!endDate && date <= hoveredDate) ||
                          (endDate && date <= endDate))
                      ? "hover-range"
                      : ""
                    : "empty-cell"
                } ${
                  date?.toDateString() === startDate?.toDateString()
                    ? "selected-start"
                    : ""
                } ${
                  date?.toDateString() === endDate?.toDateString()
                    ? "selected-end"
                    : ""
                }`}
                onClick={() =>
                  date && !isReserved && !isPast && handleDateClick(date)
                }
                onMouseEnter={() => date && setHoveredDate(date)}
              >
                {isReserved ? <del>{date?.getDate()}</del> : date?.getDate()}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="date-picker-container">
      <div className="date-picker-input" onClick={togglePicker}>
        {startDate && endDate ? (
          <>
            {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}
            <button className="clear-btn" onClick={handleClear}>
              &times;
            </button>
          </>
        ) : (
          <span className="placeholder text-[15px] flex justify-between w-full">
            Select Start - End Date{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={20}
              height={20}
              className="text-inherit"
              fill={"none"}
            >
              <path
                d="M18 2V4M6 2V4"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.9955 13H12.0045M11.9955 17H12.0045M15.991 13H16M8 13H8.00897M8 17H8.00897"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.5 8H20.5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.5 12.2432C2.5 7.88594 2.5 5.70728 3.75212 4.35364C5.00424 3 7.01949 3 11.05 3H12.95C16.9805 3 18.9958 3 20.2479 4.35364C21.5 5.70728 21.5 7.88594 21.5 12.2432V12.7568C21.5 17.1141 21.5 19.2927 20.2479 20.6464C18.9958 22 16.9805 22 12.95 22H11.05C7.01949 22 5.00424 22 3.75212 20.6464C2.5 19.2927 2.5 17.1141 2.5 12.7568V12.2432Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3 8H21"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        )}
      </div>

      {isOpen && (
        <div className="date-picker-popup shadow-lg">{renderCalendar()}</div>
      )}
    </div>
  );
};

export default CustomDateRangePicker;
