import React, { useContext, useState } from "react";
import { DataContext } from "../Context/Context";

const Login = () => {
  const { login, loading } = useContext(DataContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    login(email, password);
  };

  return (
    <form onSubmit={handleLogin} className="bg-white p-6 rounded w-full">
      <h2 className="text-lg font-bold mb-4 text-[#aca9a5]">Login</h2>
      <div className="mb-4">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="border p-2 rounded w-full text-[14px] text-[#aca9a5]"
        />
      </div>
      <div className="mb-4">
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="border p-2 rounded w-full text-[14px] text-[#aca9a5]"
        />
      </div>
      <button
        type="submit"
        className="bg-[#aca9a5] text-white px-4 py-2 rounded"
      >
        {loading ? (
          <span className="loading loading-spinner loading-md"></span>
        ) : (
          "Login"
        )}
      </button>
    </form>
  );
};

export default Login;
