import React from "react";
import { Link, Outlet } from "react-router-dom";
import logo from "../Assets/Vector Smart Object.png";

const Dashboard = () => {
  return (
    <section className="">
      <div className="drawer lg:drawer-open">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content relative bg-[#f8faff]">
          <Outlet />
          <div className="lg:hidden fixed flex items-center justify-between top-0 p-4 bg-white w-[100vw]">
            <h2 className="text-[18px] text-[#aca9a5] uppercase">Dashboard</h2>
            <label htmlFor="my-drawer-2" className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={32}
                height={32}
                color={"#aca9a5"}
                fill={"none"}
              >
                <path
                  d="M20 12L10 12"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M20 5L4 5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M20 19L4 19"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </label>
          </div>
        </div>
        <div className="drawer-side rounded-none">
          <label
            htmlFor="my-drawer-2"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>
          <ul className="menu relative bg-white lg:h-auto h-full text-white w-80 p-4 rounded-none">
            {/* Sidebar content here */}
            <li className="py-2">
              <Link to={"/"} className="flex items-center gap-2">
                <img src={logo} alt="" className="w-36" />
              </Link>
            </li>
            <div className="w-full h-[1px] bg-[#aca9a5] my-6"></div>
            <li className="py-2">
              <Link
                to={"/dashboard"}
                className="text-[#aca9a5] hover:text-[#aca9a5] hover:no-underline border-l-2 border-white hover:border-[#aca9a5] rounded-none "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={20}
                  height={20}
                  className="text-inherit rounded-none"
                  fill={"none"}
                >
                  <path
                    d="M16 10L18.1494 10.6448C19.5226 11.0568 20.2092 11.2628 20.6046 11.7942C21 12.3256 21 13.0425 21 14.4761V22"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 9L11 9M8 13L11 13"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 22V19C12 18.0572 12 17.5858 11.7071 17.2929C11.4142 17 10.9428 17 10 17H9C8.05719 17 7.58579 17 7.29289 17.2929C7 17.5858 7 18.0572 7 19V22"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2 22L22 22"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M3 22V6.71724C3 4.20649 3 2.95111 3.79118 2.32824C4.58237 1.70537 5.74742 2.04355 8.07752 2.7199L13.0775 4.17122C14.4836 4.57937 15.1867 4.78344 15.5933 5.33965C16 5.89587 16 6.65344 16 8.16857V22"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                My Reservations
              </Link>
            </li>
            <li className="py-2">
              <Link
                to={"/dashboard/profile"}
                className="text-[#aca9a5] hover:text-[#aca9a5] hover:no-underline border-l-2 border-white hover:border-[#aca9a5] rounded-none "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={20}
                  height={20}
                  className="text-inherit rounded-none"
                  fill={"none"}
                >
                  <path
                    d="M6.57757 15.4816C5.1628 16.324 1.45336 18.0441 3.71266 20.1966C4.81631 21.248 6.04549 22 7.59087 22H16.4091C17.9545 22 19.1837 21.248 20.2873 20.1966C22.5466 18.0441 18.8372 16.324 17.4224 15.4816C14.1048 13.5061 9.89519 13.5061 6.57757 15.4816Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                  />
                </svg>
                Profile Setting
              </Link>
            </li>
            <li>
              <Link className="text-[#aca9a5] hover:text-[#aca9a5] hover:no-underline border-l-2 border-white hover:border-[#aca9a5] rounded-none ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={20}
                  height={20}
                  className="text-inherit rounded-none"
                  fill={"none"}
                >
                  <path
                    d="M14 3.09502C13.543 3.03241 13.0755 3 12.6 3C7.29807 3 3 7.02944 3 12C3 16.9706 7.29807 21 12.6 21C13.0755 21 13.543 20.9676 14 20.905"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M21 12L11 12M21 12C21 11.2998 19.0057 9.99153 18.5 9.5M21 12C21 12.7002 19.0057 14.0085 18.5 14.5"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
