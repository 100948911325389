import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../Pages/Single Listing/style.css";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Helmet } from "react-helmet";

const GallerySlider = ({ images }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <>
      {images?.length > 0 && (
        <Helmet>
          <link rel="preload" as="image" href={images[0]?.url} />
        </Helmet>
      )}

      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        spaceBetween={10}
        navigation={true}
        // Add a conditional check for thumbsSwiper
        thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2 lg:h-[450px] md:h-[350px] h-[250px]"
      >
        {/* Your SwiperSlides */}
        {images?.map((image, index) => {
          return (
            <SwiperSlide key={index} className="rounded-xl overflow-hidden">
              <img src={image.url} alt="" className="w-[850px] h-[500px]" />
            </SwiperSlide>
          );
        })}
        {/* Add the rest of the slides here */}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper mt-2 lg:h-24"
      >
        {/* Your thumbnail SwiperSlides */}
        {images?.map((image, index) => {
          return (
            <SwiperSlide
              key={index}
              className="lg:rounded-xl md:rounded-lg rounded-md overflow-hidden"
            >
              <img
                src={image.url}
                alt=""
                className="lg:w-[300px] lg:h-[200px] md:w-[250px] md:h-[150px] object-cover"
              />
            </SwiperSlide>
          );
        })}
        {/* Add the rest of the thumbnail slides here */}
      </Swiper>
    </>
  );
};

export default GallerySlider;
