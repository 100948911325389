import axios from "axios";
import { useLocation } from "react-router-dom";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { fetchListingTaxSettings } from "../../Components/fetchListingTaxSettings";
import { calculateTaxes } from "../../Components/calculateTaxes";
import { DataContext } from "../../Context/Context";

const Checkout = () => {
  const [msgtype, setMsgtype] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [tax, setTax] = useState([]);
  const { discount } = useContext(DataContext);

  const location = useLocation();
  const {
    formattedEndDate,
    formattedStartDate,
    guestCount,
    childCount,
    infantCount,
    bedLineFee,
    nights,
    updatedPrice,
    cleaningFee,
    additionalValue,
    selectedServices,
    selectedServices2,
    listing,
    weeklyDiscount,
  } = location.state || {};

  // Fetch tax settings
  useEffect(() => {
    const fetchTaxSettings = async () => {
      try {
        const settings = await fetchListingTaxSettings(listing?.id);
        setTax(settings || []); // Ensure tax is an array
      } catch (error) {
        // console.error("Failed to fetch listing tax settings:", error.message);
      }
    };

    if (listing?.id) {
      fetchTaxSettings();
    }
  }, [listing?.id]);

  const { cityTourismTax, occupancyTax, vatGstTax } = calculateTaxes(
    tax,
    nights,
    updatedPrice
  );

  let totalPrices = 0;

  // Calculate the base price and all additional costs separately
  const basePrice = updatedPrice;
  const additionalCosts =
    additionalValue +
    bedLineFee?.amount +
    cleaningFee +
    cityTourismTax +
    occupancyTax +
    vatGstTax;

  if (discount && discount > 0) {
    const discountedBasePrice = basePrice - discount;
    totalPrices = discountedBasePrice + additionalCosts;
  } else {
    totalPrices = basePrice + additionalCosts;
  }

  const addsOnServicesString = selectedServices
    ?.map((service) => `${service.label}: AED ${service.value}`)
    .join(", ");
  const additionalServicesString = selectedServices2
    ?.map((service) => `${service.label}`)
    .join(", ");

  const handleCheckout = async (e) => {
    e.preventDefault();

    const form = e.target;

    const fname = form.fname.value;
    const lname = form.lname.value;
    const phone = form.phone.value;
    const email = form.email.value;
    const message = form.message.value;

    const userData = {
      fname,
      lname,
      email,
      phone,
      message,
    };

    const reservationData = {
      channelId: 2000,
      listingMapId: listing?.listingSettings?.listingMapId || 0,
      isManuallyChecked: 0,
      isInitial: 0,
      guestName: userData.fname + " " + userData.lname,
      guestFirstName: userData.fname,
      guestLastName: userData.lname,
      guestEmail: userData.email,
      guestPicture: userData?.picture || "",
      numberOfGuests: guestCount || 1,
      adults: guestCount || 1,
      children: childCount || 0,
      infants: infantCount || 0,
      pets: null,
      arrivalDate: formattedStartDate,
      departureDate: formattedEndDate,
      checkInTime: null,
      checkOutTime: null,
      phone: userData.phone,
      totalPrice: totalPrices,
      cleaningFee: cleaningFee || null,
      isPaid: true,
      currency: "AED",
      comment: userData?.comment || "",
      customFieldValues: [
        {
          customFieldId: 71097,
          value: addsOnServicesString,
        },
        {
          customFieldId: 70771,
          value: additionalServicesString,
        },
      ],
    };

    if (
      reservationData.guestName === undefined &&
      userData.guestEmail === undefined
    ) {
      toast.warn("Please provide your information");
      return;
    }

    if (!stripe || !elements) {
      setMessage("Stripe is not initialized yet!");
      return;
    }

    setLoading(true);

    // try {
    //   const { error, paymentMethod } = await stripe.createPaymentMethod({
    //     type: "card",
    //     card: elements.getElement(CardElement),
    //   });

    //   if (error) {
    //     setMessage(error.message);
    //     setMsgtype("failed");
    //     setLoading(false);
    //     return;
    //   }

    //   const paymentResponse = await axios.post(
    //     "https://365luxuryhomes.com/api/stripe/payment",
    //     {
    //       paymentMethodId: paymentMethod?.id,
    //       amount: totalPrices,
    //       userEmail: userData?.email,
    //       reservationData,
    //     }
    //   );

    //   if (paymentResponse.data.success) {
    //     setMsgtype("Working");
    //     setMessage("Payment successful! Creating reservation...");

    //     localStorage.removeItem("discount");
    //     // Call Hostaway reservation API
    //     const tokenResponse = await axios.post(
    //       "https://365luxuryhomes.com/api/accessToken"
    //     );
    //     const accessToken = tokenResponse.data.access_token;
    //     const reservationResponse = await axios.post(
    //       "https://api.hostaway.com/v1/reservations?forceOverbooking=1",
    //       reservationData,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${accessToken}`,
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     );
    //     if (reservationResponse.data) {
    //       setMsgtype("success");
    //       setMessage("Reservation created successfully!");
    //       toast.success("Reservation created successfully!");
    //     }
    //   } else {
    //     setMessage("Payment was successful, but reservation failed.");
    //     setMsgtype("failed");
    //   }
    // } catch (error) {
    //   console.error(
    //     "Error creating reservation:",
    //     error.response?.data || error.message
    //   );
    //   setMessage(error.message);
    //   setMsgtype("failed");
    // } finally {
    //   setLoading(false);
    // }

    try {
      setLoading(true);
      setMessage("");
      setMsgtype("");

      // Step 1: Create Payment Method with Stripe
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (error) {
        setMessage(error.message);
        setMsgtype("failed");
        return;
      }

      // Step 2: Process Payment with Backend API
      const paymentResponse = await axios.post(
        "https://365luxuryhomes.com/api/stripe/payment",
        {
          paymentMethodId: paymentMethod?.id,
          amount: totalPrices,
          userEmail: userData?.email,
          reservationData,
        }
      );

      if (!paymentResponse.data.success) {
        throw new Error(paymentResponse.data.message || "Payment failed.");
      }

      setMsgtype("working");
      setMessage("Payment successful! Creating reservation...");

      // Step 3: Remove discount from local storage
      localStorage.removeItem("discount");

      // Step 4: Get Access Token for Hostaway API
      let accessToken;
      try {
        const tokenResponse = await axios.post(
          "https://365luxuryhomes.com/api/accessToken"
        );
        accessToken = tokenResponse.data.access_token;
      } catch (tokenError) {
        throw new Error("Failed to get API access token. Please try again.");
      }

      // Step 5: Create Reservation via Hostaway API
      try {
        const reservationResponse = await axios.post(
          "https://api.hostaway.com/v1/reservations?forceOverbooking=1",
          reservationData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (reservationResponse.data) {
          setMsgtype("success");
          setMessage("Reservation created successfully!");
          toast.success("Reservation created successfully!");
        } else {
          throw new Error("Payment was successful, but reservation failed.");
        }
      } catch (reservationError) {
        throw new Error(
          reservationError.response?.data?.message ||
            "Error creating reservation. Please try again."
        );
      }
    } catch (error) {
      console.error("Error during payment or reservation:", error);

      // Handling various error types
      if (error.response) {
        setMessage(error.response.data.message || "Something went wrong.");
      } else if (error.request) {
        setMessage("Network error. Please check your connection.");
      } else {
        setMessage(error.message || "An unexpected error occurred.");
      }
      setMsgtype("failed");
    } finally {
      setLoading(false);
    }
  };

  const generateTitle = () => {
    if (nights >= 7 && nights < 30) {
      return "Weekly Discount";
    } else if (nights >= 30) {
      return "Monthly Discount";
    }
  };

  return (
    <section className="py-20">
      <div className="container">
        <form
          onSubmit={handleCheckout}
          className="lg:p-8 md:p-6 p-4 bg-gray-50"
        >
          <h1 className="text-2xl font-bold mb-6 text-[#aca9a5]">
            365 Luxury Homes Checkout
          </h1>

          <div className="mb-6">
            <h2 className="text-sm font-semibold text-[#aca9a5] mb-2">
              Booking Summary
            </h2>
            <table className="w-full border-collapse bg-white shadow rounded">
              <thead>
                <tr className="">
                  <th className="text-left border-b p-2 text-[#aca9a5] text-[14px]">
                    Details
                  </th>
                  <th className="text-right border-b p-2 text-[#aca9a5] text-[14px]">
                    Value
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">Guests</td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    {guestCount || 0} Adults, {childCount || 0} Children,{" "}
                    {infantCount || 0} Infants
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">Start Date</td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    {formattedStartDate}
                  </td>
                </tr>

                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">End Date</td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    {formattedEndDate}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">
                    Total Nights
                  </td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    {nights} Nights
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">Base Price</td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    AED {updatedPrice.toFixed(2) || 0}
                  </td>
                </tr>
                {weeklyDiscount > 0 && (
                  <tr>
                    <td className="p-2 text-red-500 text-[14px]">
                      {generateTitle()}
                    </td>
                    <td className="p-2 text-right text-red-500 text-[14px]">
                      - AED {weeklyDiscount.toFixed(2)}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">
                    Cleaning fee
                  </td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    AED {cleaningFee.toFixed(2) || 0}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">
                    Bed linen fee
                  </td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    AED {bedLineFee?.amount}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">
                    Add-ons services
                  </td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    AED {additionalValue}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">
                    City / Tourism tax
                  </td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    AED {cityTourismTax.toFixed(2) || 0}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">
                    Occupancy tax 5 % (per reservation)
                  </td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    AED {occupancyTax.toFixed(2) || 0}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">
                    VAT / GST 5 % (per reservation)
                  </td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    AED {vatGstTax.toFixed(2) || 0}
                  </td>
                </tr>
                {discount && discount > 0 && (
                  <tr className="border-t-[1px]">
                    <td className="p-2 text-[#aca9a5] text-[16px] =">
                      Coupon Applied
                    </td>
                    <td className="p-2 text-right text-[#aca9a5] text-[16px] ">
                      - AED {discount.toFixed(2) || 0}
                    </td>
                  </tr>
                )}
                <tr className="border-t-[1px]">
                  <td className="p-2 text-[#aca9a5] text-[16px] font-semibold =">
                    Subtotal
                  </td>
                  <td className="p-2 text-right text-[#aca9a5] text-[16px] font-semibold">
                    AED {totalPrices.toFixed(2) || 0}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="bg-white p-6 shadow rounded mb-6">
            <h2 className="text-sm font-semibold mb-4 text-[#aca9a5]">
              Billing details
            </h2>
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <input
                  type="text"
                  placeholder="First name"
                  name="fname"
                  className="border p-2 rounded w-full text-[#aca9a5] focus:ring-[#aca9a5] focus:border-[#aca9a5] text-[14px]"
                  required
                />
                <input
                  type="text"
                  placeholder="Last name"
                  name="lname"
                  className="border p-2 rounded w-full text-[#aca9a5] focus:ring-[#aca9a5] focus:border-[#aca9a5] text-[14px]"
                  required
                />
              </div>
              <input
                type="text"
                placeholder="Phone"
                name="phone"
                className="border p-2 rounded w-full text-[#aca9a5] focus:ring-[#aca9a5] focus:border-[#aca9a5] text-[14px]"
                required
              />
              <input
                type="email"
                placeholder="Email address"
                name="email"
                className="border p-2 rounded w-full text-[#aca9a5] focus:ring-[#aca9a5] focus:border-[#aca9a5] text-[14px]"
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="bg-white p-6 shadow rounded">
              <h3 className="text-sm font-semibold text-[#aca9a5] mb-4">
                Additional information
              </h3>
              <textarea
                placeholder="Notes about your order, e.g. special notes for delivery."
                name="message"
                className="border p-2 rounded w-full text-[#aca9a5] text-[14px] focus:ring-[#aca9a5] focus:border-[#aca9a5] h-[120px]"
              ></textarea>
            </div>

            <div className="bg-white p-6 shadow rounded">
              <h3 className="text-sm font-semibold mb-4 text-[#aca9a5]">
                Payment Information
              </h3>
              <div className="space-y-4">
                <CardElement className="border p-2 rounded w-full text-[#aca9a5] text-[14px] focus:ring-[#6c675b] focus:border-yellow-500" />
                <button
                  type="submit"
                  className="bg-[#aca9a5] text-white font-normal py-2 px-4 rounded hover:bg-[#6c675b] flex items-center gap-2"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      Processing
                      <span className="loading loading-spinner loading-sm"></span>
                    </>
                  ) : (
                    "Place order"
                  )}
                </button>
                {message && (
                  <p
                    className={`${
                      msgtype === "failed" ? "text-red-500" : "text-green-500"
                    } text-[14px] mt-4`}
                  >
                    {message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Checkout;
