import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import palmJumeirah from "../../Assets/icons/Palm Jumeirah.png";
import bluewaters from "../../Assets/icons/Blue.png";
import downtown from "../../Assets/icons/Downtown.png";
import marina from "../../Assets/icons/marina.png";
import palm from "../../Assets/icons/Vector Palm.png";
import { DataContext } from "../../Context/Context";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import DynamicSeoPage from "../DynamicSeoPage";

const Properties = () => {
  const location = useLocation();
  const { listings, setListings, startDate, endDate } = useContext(DataContext);
  const [loading, setLoading] = useState(false);

  const [section, setSection] = useState({});

  const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (location.hash) {
      const scrollToElement = () => {
        const element = document.querySelector(location.hash);
        if (element) {
          const topPosition =
            element.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: topPosition,
            behavior: "smooth",
          });
        } else {
        }
      };
      setTimeout(scrollToElement, 100);
    }
  }, [location]);

  // Fetch section data for the properties banner
  const fetchSection = async () => {
    try {
      const response = await axios.get(
        `https://365luxuryhomes.com/api/sections/properties`
      );

      if (response.data) {
        setSection(response.data);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchSection();
  }, []);

  // Fetch listings based on selected dates or fetch all listings
  useEffect(() => {
    const fetchListings = async () => {
      setLoading(true);

      try {
        const tokenResponse = await axios.post(
          "https://365luxuryhomes.com/api/accessToken"
        );
        const accessToken = tokenResponse.data.access_token;
        const apiUrl = "https://api.hostaway.com/v1/listings";

        const listingsResponse = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setListings(listingsResponse?.data?.result || []);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchListings();
  }, [setListings]);

  const truncateDescription = (description, wordLimit) => {
    const words = description.split(" ");
    if (words.length > wordLimit) {
      return words?.slice(0, wordLimit).join(" ") + "...";
    }
    return description;
  };

  // console.log(listings);

  return (
    <div>
      <DynamicSeoPage page="properties" />
      <section
        className="properties_banner pt-20 pb-10"
        id="banner"
        loading="lazy"
        style={{
          backgroundImage: `url(${section?.imageUrl || ""})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container h-full flex items-end"></div>
      </section>

      <div className="lg:mt-10 md:mt-8 mt-5">
        <div className="container flex items-center justify-between ">
          <div className="flex items-center gap-1">
            <h1 className="lg:text-xl md:text-[18px] text-[16px] font-medium leading-none text-[#aca9a5]">
              <span>{listings?.length}</span>
              <span> Properties found</span>
            </h1>
          </div>
          <Link
            to={"/listings"}
            className="px-6 py-2 text-[15px] bg-[#aca9a5] hover:bg-[#6c675b] text-white rounded-[4px] hover:text-white focus:text-white active:text-white hover:no-underline"
          >
            View on map
          </Link>
        </div>
      </div>
      {/* Section for Palm Jumeirah */}
      <section className="py-10 pt-10 bg-white" id="palm_jumeirah">
        <div className="container">
          <div className="flex items-center gap-4 mb-16">
            <img src={palmJumeirah} alt="" className="w-10" />
            <h2 className="text-[#aca9a5] font-thin lg:text-2xl md:text-2xl text-xl uppercase">
              Palm Jumeirah
            </h2>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-10">
            {loading ? (
              <>
                <div className="flex w-full flex-col gap-4">
                  <div className="skeleton h-32 w-full"></div>
                  <div className="skeleton h-4 w-28"></div>
                  <div className="skeleton h-4 w-full"></div>
                  <div className="skeleton h-4 w-full"></div>
                </div>
                <div className="flex w-full flex-col gap-4">
                  <div className="skeleton h-32 w-full"></div>
                  <div className="skeleton h-4 w-28"></div>
                  <div className="skeleton h-4 w-full"></div>
                  <div className="skeleton h-4 w-full"></div>
                </div>
                <div className="flex w-full flex-col gap-4">
                  <div className="skeleton h-32 w-full"></div>
                  <div className="skeleton h-4 w-28"></div>
                  <div className="skeleton h-4 w-full"></div>
                  <div className="skeleton h-4 w-full"></div>
                </div>
              </>
            ) : (
              <>
                {listings
                  .filter((listing) => listing.city === "Palm Jumeirah")
                  .map((listing) => (
                    <Link
                      to={`/listing/${listing.id}`}
                      key={listing?.id}
                      className="hover:no-underline"
                    >
                      <img
                        src={listing?.listingImages[0]?.url}
                        alt=""
                        loading="lazy"
                        className="mb-4 shadow-lg w-full rounded-lg"
                      />
                      <div>
                        <h3 className="leading-[26px] font-normal text-[#aca9a5] text-[18px]">
                          {listing?.name}
                        </h3>
                        <p className="mt-2 font-thin text-[15px] text-[#aca9a5] leading-snug">
                          {truncateDescription(listing?.description, 12)}
                        </p>
                      </div>
                    </Link>
                  ))}
              </>
            )}
          </div>
        </div>
      </section>

      {/* Section for Bluewaters Island */}
      <section className="py-10 pt-20 bg-[#fdfcf5]" id="bluewaters_island">
        <div className="container">
          <div className="flex items-center gap-4 mb-16">
            <img src={bluewaters} alt="" className="w-10" />
            <h2 className="text-[#aca9a5] font-thin lg:text-2xl md:text-2xl text-xl uppercase">
              Bluewaters Island
            </h2>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-10">
            {loading ? (
              <>
                <div className="flex w-full flex-col gap-4">
                  <div className="skeleton h-32 w-full"></div>
                  <div className="skeleton h-4 w-28"></div>
                  <div className="skeleton h-4 w-full"></div>
                  <div className="skeleton h-4 w-full"></div>
                </div>
                <div className="flex w-full flex-col gap-4">
                  <div className="skeleton h-32 w-full"></div>
                  <div className="skeleton h-4 w-28"></div>
                  <div className="skeleton h-4 w-full"></div>
                  <div className="skeleton h-4 w-full"></div>
                </div>
                <div className="flex w-full flex-col gap-4">
                  <div className="skeleton h-32 w-full"></div>
                  <div className="skeleton h-4 w-28"></div>
                  <div className="skeleton h-4 w-full"></div>
                  <div className="skeleton h-4 w-full"></div>
                </div>
              </>
            ) : (
              listings
                .filter((listing) => listing.city === "Bluewaters Island")
                .map((listing) => (
                  <Link
                    to={`/listing/${listing.id}`}
                    key={listing?.id}
                    className="hover:no-underline"
                  >
                    <img
                      src={listing?.listingImages[0]?.url}
                      alt=""
                      loading="lazy"
                      className="mb-4 shadow-lg w-full rounded-lg"
                    />
                    <div>
                      <h3 className="leading-[26px] font-normal text-[#aca9a5] text-[18px]">
                        {listing?.name}
                      </h3>
                      <p className="mt-2 font-thin text-[15px] text-[#aca9a5] leading-snug">
                        {truncateDescription(listing?.description, 12)}
                      </p>
                    </div>
                  </Link>
                ))
            )}
          </div>
        </div>
      </section>

      {/* Section for JBR */}
      <section className="py-10 pt-20 bg-[#fff]" id="j_b_r">
        <div className="container">
          <div className="flex items-center gap-4 mb-16">
            <img src={palm} alt="" className="w-10" />
            <h2 className="text-[#aca9a5] font-thin lg:text-2xl md:text-2xl text-xl uppercase">
              JUMEIRAH BEACH RESIDENCE (JBR)
            </h2>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-10">
            {loading ? (
              <>
                <div className="flex w-full flex-col gap-4">
                  <div className="skeleton h-32 w-full"></div>
                  <div className="skeleton h-4 w-28"></div>
                  <div className="skeleton h-4 w-full"></div>
                  <div className="skeleton h-4 w-full"></div>
                </div>
                <div className="flex w-full flex-col gap-4">
                  <div className="skeleton h-32 w-full"></div>
                  <div className="skeleton h-4 w-28"></div>
                  <div className="skeleton h-4 w-full"></div>
                  <div className="skeleton h-4 w-full"></div>
                </div>
                <div className="flex w-full flex-col gap-4">
                  <div className="skeleton h-32 w-full"></div>
                  <div className="skeleton h-4 w-28"></div>
                  <div className="skeleton h-4 w-full"></div>
                  <div className="skeleton h-4 w-full"></div>
                </div>
              </>
            ) : (
              listings
                .filter((listing) => listing.city === "JBR")
                .map((listing) => (
                  <Link
                    to={`/listing/${listing.id}`}
                    key={listing?.id}
                    className="hover:no-underline"
                  >
                    <img
                      src={listing?.listingImages[0]?.url}
                      alt=""
                      loading="lazy"
                      className="mb-4 shadow-lg w-full rounded-lg"
                    />
                    <div>
                      <h3 className="leading-[26px] font-normal text-[#aca9a5] text-[18px]">
                        {listing?.name}
                      </h3>
                      <p className="mt-2 font-thin text-[15px] text-[#aca9a5] leading-snug">
                        {truncateDescription(listing?.description, 12)}
                      </p>
                    </div>
                  </Link>
                ))
            )}
          </div>
        </div>
      </section>

      {/* Section for Downtown */}
      <section className="py-10 pt-20 bg-[#fdfcf5]" id="downtown">
        <div className="container">
          <div className="flex items-center gap-4 mb-16">
            <img src={downtown} alt="" className="w-10" />
            <h2 className="text-[#aca9a5] font-thin lg:text-2xl md:text-2xl text-xl uppercase">
              Downtown
            </h2>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-10">
            {loading ? (
              <>
                <div className="flex w-full flex-col gap-4">
                  <div className="skeleton h-32 w-full"></div>
                  <div className="skeleton h-4 w-28"></div>
                  <div className="skeleton h-4 w-full"></div>
                  <div className="skeleton h-4 w-full"></div>
                </div>
                <div className="flex w-full flex-col gap-4">
                  <div className="skeleton h-32 w-full"></div>
                  <div className="skeleton h-4 w-28"></div>
                  <div className="skeleton h-4 w-full"></div>
                  <div className="skeleton h-4 w-full"></div>
                </div>
                <div className="flex w-full flex-col gap-4">
                  <div className="skeleton h-32 w-full"></div>
                  <div className="skeleton h-4 w-28"></div>
                  <div className="skeleton h-4 w-full"></div>
                  <div className="skeleton h-4 w-full"></div>
                </div>
              </>
            ) : (
              listings
                .filter((listing) => listing.city === "Downtown Dubai")
                .map((listing) => (
                  <Link
                    to={`/listing/${listing.id}`}
                    key={listing?.id}
                    className="hover:no-underline"
                  >
                    <img
                      src={listing?.listingImages[0]?.url}
                      alt=""
                      loading="lazy"
                      className="mb-4 shadow-lg w-full rounded-lg"
                    />
                    <div>
                      <h3 className="leading-[26px] font-normal text-[#aca9a5] text-[18px]">
                        {listing?.name}
                      </h3>
                      <p className="mt-2 font-thin text-[15px] text-[#aca9a5] leading-snug">
                        {truncateDescription(listing?.description, 12)}
                      </p>
                    </div>
                  </Link>
                ))
            )}
          </div>
        </div>
      </section>

      {/* Section for Dubai Marina*/}
      <section className="py-10 pt-20 bg-[#fff]" id="dubai_marina">
        <div className="container">
          <div className="flex items-center gap-4 mb-16">
            <img src={marina} alt="" className="w-10" />
            <h2 className="text-[#aca9a5] font-thin lg:text-2xl md:text-2xl text-xl uppercase">
              Dubai Marina
            </h2>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-10">
            {loading ? (
              <>
                <div className="flex w-full flex-col gap-4">
                  <div className="skeleton h-32 w-full"></div>
                  <div className="skeleton h-4 w-28"></div>
                  <div className="skeleton h-4 w-full"></div>
                  <div className="skeleton h-4 w-full"></div>
                </div>
                <div className="flex w-full flex-col gap-4">
                  <div className="skeleton h-32 w-full"></div>
                  <div className="skeleton h-4 w-28"></div>
                  <div className="skeleton h-4 w-full"></div>
                  <div className="skeleton h-4 w-full"></div>
                </div>
                <div className="flex w-full flex-col gap-4">
                  <div className="skeleton h-32 w-full"></div>
                  <div className="skeleton h-4 w-28"></div>
                  <div className="skeleton h-4 w-full"></div>
                  <div className="skeleton h-4 w-full"></div>
                </div>
              </>
            ) : (
              listings
                .filter((listing) => listing.city === "Dubai Marina")
                .map((listing) => (
                  <Link
                    to={`/listing/${listing.id}`}
                    key={listing?.id}
                    className="hover:no-underline"
                  >
                    <img
                      src={listing?.listingImages[0]?.url}
                      alt=""
                      loading="lazy"
                      className="mb-4 shadow-lg w-full rounded-lg"
                    />
                    <div>
                      <h3 className="leading-[26px] font-normal text-[#aca9a5] text-[18px]">
                        {listing?.name}
                      </h3>
                      <p className="mt-2 font-thin text-[15px] text-[#aca9a5] leading-snug">
                        {truncateDescription(listing?.description, 12)}
                      </p>
                    </div>
                  </Link>
                ))
            )}
          </div>
        </div>
      </section>
      <div className="flex flex-col items-center justify-center lg:py-20 md:py-14 lg:mb-0 md:mb-0 mb-10">
        <a
          href="#banner"
          className="text-[#aca9a5] hover:no-underline hover:text-[#aca9a5]"
        >
          Go To Top
        </a>
        <div className="w-[2px] h-14 bg-[#aca9a5]"></div>
      </div>
    </div>
  );
};

export default Properties;
