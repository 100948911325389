import React, { useContext, useState } from "react";
import logo from "../Assets/Vector Smart Object.png";
import { Link } from "react-router-dom";
import { DataContext } from "../Context/Context";
import Register from "../Components/Register";
import userImg from "../Assets/user.png";

const Navbar = () => {
  const { user, logout } = useContext(DataContext);
  const [isOpen, setIsOpen] = useState(false);

  const handleLinkClick = () => {
    setIsOpen(false); // Close the dropdown
  };
  return (
    <div className="bg-base-100 fixed w-[100%] z-[9999] shadow-md">
      <div className="container">
        <div className="navbar">
          <div className="flex-1">
            <Link className="text-xl cursor-pointer">
              <img src={logo} alt="" className="w-32" />
            </Link>
          </div>
          <div className="flex-none">
            <ul className="menu menu-horizontal mb-0 items-center">
              <div className="dropdown lg:flex items-center justify-center md:block hidden">
                <div
                  className="avatar"
                  tabIndex={0}
                  role="button"
                  onClick={() => setIsOpen(true)}
                >
                  <div className="ring[#aca9a5] w-8 rounded-full ">
                    <img src={userImg} alt="" />
                  </div>
                </div>
                {isOpen && (
                  <ul
                    tabIndex={0}
                    className="dropdown-content menu bg-base-100 rounded-box z-[1] w-40 p-2 shadow top-16 right-0"
                  >
                    {user ? (
                      <>
                        <li>
                          <Link
                            onClick={handleLinkClick}
                            to={"/dashboard"}
                            className="text-[16px] text-[#aca9a5] hover:text-[#6c675b] flex items-center gap-2 hover:no-underline"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width={20}
                              height={20}
                              className="text-inherit"
                              fill={"none"}
                            >
                              <path
                                d="M18 9.35714V10.5M18 9.35714C16.9878 9.35714 16.0961 8.85207 15.573 8.08517M18 9.35714C19.0122 9.35714 19.9039 8.85207 20.427 8.08517M18 3.64286C19.0123 3.64286 19.9041 4.148 20.4271 4.915M18 3.64286C16.9877 3.64286 16.0959 4.148 15.5729 4.915M18 3.64286V2.5M21.5 4.21429L20.4271 4.915M14.5004 8.78571L15.573 8.08517M14.5 4.21429L15.5729 4.915M21.4996 8.78571L20.427 8.08517M20.4271 4.915C20.7364 5.36854 20.9167 5.91364 20.9167 6.5C20.9167 7.08643 20.7363 7.63159 20.427 8.08517M15.5729 4.915C15.2636 5.36854 15.0833 5.91364 15.0833 6.5C15.0833 7.08643 15.2637 7.63159 15.573 8.08517"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              />
                              <path
                                d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2C7.40013 2 8.1002 2 8.63498 2.27248C9.10538 2.51217 9.48783 2.89462 9.72752 3.36502C10 3.8998 10 4.59987 10 6C10 7.40013 10 8.1002 9.72752 8.63498C9.48783 9.10538 9.10538 9.48783 8.63498 9.72752C8.1002 10 7.40013 10 6 10C4.59987 10 3.8998 10 3.36502 9.72752C2.89462 9.48783 2.51217 9.10538 2.27248 8.63498C2 8.1002 2 7.40013 2 6Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                              />
                              <path
                                d="M2 18C2 16.5999 2 15.8998 2.27248 15.365C2.51217 14.8946 2.89462 14.5122 3.36502 14.2725C3.8998 14 4.59987 14 6 14C7.40013 14 8.1002 14 8.63498 14.2725C9.10538 14.5122 9.48783 14.8946 9.72752 15.365C10 15.8998 10 16.5999 10 18C10 19.4001 10 20.1002 9.72752 20.635C9.48783 21.1054 9.10538 21.4878 8.63498 21.7275C8.1002 22 7.40013 22 6 22C4.59987 22 3.8998 22 3.36502 21.7275C2.89462 21.4878 2.51217 21.1054 2.27248 20.635C2 20.1002 2 19.4001 2 18Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                              />
                              <path
                                d="M14 18C14 16.5999 14 15.8998 14.2725 15.365C14.5122 14.8946 14.8946 14.5122 15.365 14.2725C15.8998 14 16.5999 14 18 14C19.4001 14 20.1002 14 20.635 14.2725C21.1054 14.5122 21.4878 14.8946 21.7275 15.365C22 15.8998 22 16.5999 22 18C22 19.4001 22 20.1002 21.7275 20.635C21.4878 21.1054 21.1054 21.4878 20.635 21.7275C20.1002 22 19.4001 22 18 22C16.5999 22 15.8998 22 15.365 21.7275C14.8946 21.4878 14.5122 21.1054 14.2725 20.635C14 20.1002 14 19.4001 14 18Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                              />
                            </svg>
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <span
                            onClick={() => logout()}
                            className="text-[16px] text-[#aca9a5] flex items-center gap-2 hover:no-underline hover:text-red-500"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width={20}
                              height={20}
                              className="text-inherit"
                              fill={"none"}
                            >
                              <path
                                d="M14 3.09502C13.543 3.03241 13.0755 3 12.6 3C7.29807 3 3 7.02944 3 12C3 16.9706 7.29807 21 12.6 21C13.0755 21 13.543 20.9676 14 20.905"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              />
                              <path
                                d="M21 12L11 12M21 12C21 11.2998 19.0057 9.99153 18.5 9.5M21 12C21 12.7002 19.0057 14.0085 18.5 14.5"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Logout
                          </span>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Link
                            onClick={() =>
                              document
                                .getElementById("register_modal")
                                .showModal()
                            }
                            className="text-[16px] flex items-center gap-2 hover:no-underline text-[#aca9a5]  hover:text-[#6c675b]"
                          >
                            Sign up
                          </Link>
                        </li>
                        <li>
                          <span
                            onClick={() =>
                              document.getElementById("login_modal").showModal()
                            }
                            className="text-[16px] flex items-center gap-2 hover:no-underline text-[#aca9a5] hover:text-[#6c675b]"
                          >
                            Login
                          </span>
                        </li>
                      </>
                    )}
                  </ul>
                )}
              </div>
              <li className="custom_li dropdown">
                <div
                  tabIndex={0}
                  role="button"
                  className="hamburger hover:bg-transparent focus:bg-transparent"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={32}
                    height={32}
                    className="text-[#aca9a5]"
                    fill={"none"}
                  >
                    <path
                      d="M4 5L20 5"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4 12L20 12"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4 19L20 19"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {isOpen && (
                  <ul
                    tabIndex={0}
                    className="dropdown-content menu bg-base-100 rounded-box z-[1] w-40 p-2 shadow top-16 right-0"
                  >
                    <li>
                      <Link
                        onClick={handleLinkClick}
                        className="navigation_link font-thin py-[3px] text-[16px] hover:no-underline text-[#aca9a5] hover:text-[#6c675b]"
                        to="/"
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={handleLinkClick}
                        to="/about"
                        className="navigation_link font-thin py-[3px] text-[16px] hover:no-underline text-[#aca9a5] hover:text-[#6c675b]"
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={handleLinkClick}
                        to="/properties"
                        className="navigation_link font-thin py-[3px] text-[16px] hover:no-underline text-[#aca9a5] hover:text-[#6c675b]"
                      >
                        Properties
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={handleLinkClick}
                        to="/experiences"
                        className="navigation_link font-thin py-[3px] text-[16px] hover:no-underline text-[#aca9a5] hover:text-[#6c675b]"
                      >
                        Experiences
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={handleLinkClick}
                        to="/contact"
                        className="navigation_link font-thin py-[3px] text-[16px] hover:no-underline text-[#aca9a5] hover:text-[#6c675b]"
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={handleLinkClick}
                        to="/list-with-us"
                        className="navigation_link font-thin py-[3px] text-[16px] hover:no-underline text-[#aca9a5] hover:text-[#6c675b]"
                      >
                        Host With Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={handleLinkClick}
                        to="/blog"
                        className="navigation_link font-thin text-[16px] py-[3px] hover:no-underline text-[#aca9a5] hover:text-[#6c675b]"
                      >
                        Blog
                      </Link>
                    </li>
                    {user ? (
                      <>
                        <li>
                          <Link
                            onClick={handleLinkClick}
                            to="/dashboard"
                            className="navigation_link lg:hidden md:hidden py-[3px] font-thin text-[16px] hover:no-underline text-[#aca9a5] hover:text-[#6c675b]"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <span
                            onClick={() => {
                              handleLinkClick();
                              logout();
                            }}
                            className="text-[16px] py-[3px] font-thin text-[#aca9a5] flex items-center gap-2 hover:no-underline hover:text-red-500"
                          >
                            Logout
                          </span>
                        </li>
                      </>
                    ) : (
                      <>
                        <div className="w-full h-[1px] bg-[#aca9a5] my-2 opacity-15 lg:hidden md:hidden block"></div>
                        <li>
                          <Link
                            onClick={() => {
                              handleLinkClick();
                              document
                                .getElementById("login_modal")
                                .showModal();
                            }}
                            className="navigation_link lg:hidden py-[3px] text-[16px] gap-1 md:hidden font-thin text-[#aca9a5] hover:no-underline hover:text-[#6c675b]"
                          >
                            Login
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => {
                              handleLinkClick();
                              document
                                .getElementById("register_modal")
                                .showModal();
                            }}
                            to="/contact"
                            className="navigation_link lg:hidden py-[3px] md:hidden font-thin text-[16px] hover:no-underline text-[#aca9a5] hover:text-[#6c675b]"
                          >
                            Register
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
