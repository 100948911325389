import { createBrowserRouter } from "react-router-dom";
import Main from "../Layout/Main";
import Home from "../Pages/Home/Home";
import Contact from "../Pages/Contact/Contact";
import SingleListing from "../Pages/Single Listing/SingleListing";
import Checkout from "../Pages/Checkout/Checkout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Properties from "../Pages/Properties/Properties";
import ScrollToTop from "../Components/ScrollToTop";
import About from "../Pages/About/About";
import Dashboard from "../Dashboard/Dashboard";
import Reservations from "../Dashboard/Pages/Reservations";
import Services from "../Pages/Services/Services";
import ListWithUs from "../Pages/ListWithUs/ListWithUs";
import BlogPage from "../Pages/Blog/BlogPage";
import PrivacyPolicy from "../Pages/Privacy Policy/PrivacyPolicy";
import BlogDetails from "../Pages/Blog/BlogDetails";
import PropertiesOnMap from "../Pages/PropertiesOnMap/PropertiesOnMap";
import TermsAndCondition from "../Pages/TermsAndCondition/TermsAndCondition";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <ScrollToTop /> <Main />
      </>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/experiences",
        element: <Services />,
      },
      {
        path: "/list-with-us",
        element: <ListWithUs />,
      },
      {
        path: "/blog",
        element: <BlogPage />,
      },
      {
        path: "/blog/:slug",
        element: <BlogDetails />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndCondition />,
      },
      {
        path: "/listings",
        element: <PropertiesOnMap />,
      },
      {
        path: "/properties",
        element: (
          <>
            <ScrollToTop /> <Properties />
          </>
        ),
      },
      {
        path: "/checkout",
        element: (
          <Elements stripe={stripePromise}>
            <Checkout />
          </Elements>
        ),
      },
      {
        path: "/listing/:id",
        element: <SingleListing />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      {
        path: "/dashboard",
        element: <Reservations />,
      },
    ],
  },
]);
