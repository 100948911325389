import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import img1 from "../../Assets/image5.jpg";
import img2 from "../../Assets/image4.jpg";
import img3 from "../../Assets/image3.jpg";
import img4 from "../../Assets/image6.jpg";
import img5 from "../../Assets/image7.jpg";

import icon1 from "../../Assets/icons/svgexport-4.svg";
import icon2 from "../../Assets/icons/svgexport-5.svg";
import icon3 from "../../Assets/icons/downtown w.png";
import icon4 from "../../Assets/icons/Dubai Marina.png";
import icon5 from "../../Assets/icons/Vector Palm.png";
import { Link } from "react-router-dom";

const Gallery = () => {
  return (
    <section className="">
      <>
        <Swiper
          slidesPerView={3}
          spaceBetween={0}
          loop={true}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="listingSwiper max-h-[800px]"
          breakpoints={{
            768: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
          }}
        >
          <SwiperSlide>
            <Link to={"/properties#palm_jumeirah"}>
              <div className="absolute bottom-[5%] left-8">
                <img src={icon1} loading="lazy" alt="" className="mb-2" />
                <span className="font-medium text-2xl text-white block">
                  PALM
                </span>
                <span className="font-medium text-2xl text-white block">
                  JUMEIRAH
                </span>
              </div>
              <img
                src={img1}
                alt=""
                loading="lazy"
                className="h-full max-h-[800px] w-full object-cover"
              />
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to={"/properties#bluewaters_island"}>
              <div className="absolute top-[5%] left-8">
                <img src={icon2} loading="lazy" alt="" className="mb-2" />
                <span className="font-medium text-2xl text-white block">
                  BLUEWATERS
                </span>
                <span className="font-medium text-2xl text-white block">
                  ISLAND
                </span>
              </div>
              <img
                src={img2}
                alt=""
                loading="lazy"
                className="h-full max-h-[800px] w-full object-cover"
              />
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to={"/properties#j_b_r"}>
              <div className="absolute bottom-[5%] left-8">
                <img
                  src={icon5}
                  loading="lazy"
                  alt=""
                  className="mb-2 w-16 brightness-200"
                />
                <span className="font-medium text-2xl text-white block">
                  JUMEIRAH BEACH
                </span>
                <span className="font-medium text-2xl text-white block">
                  RESIDENCE (JBR)
                </span>
              </div>
              <img
                src={img3}
                alt=""
                loading="lazy"
                className="h-full max-h-[800px] w-full object-cover"
              />
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to={"/properties#downtown"}>
              <div className="absolute top-[5%] left-8">
                <img src={icon3} loading="lazy" alt="" className="mb-2" />
                <span className="font-medium text-2xl text-white block">
                  DOWNTOWN
                </span>
              </div>
              <img
                src={img4}
                alt=""
                loading="lazy"
                className="h-full max-h-[800px] w-full object-cover"
              />
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to={"/properties#dubai_marina"}>
              <div className="absolute bottom-[5%] left-8">
                <img
                  src={icon4}
                  loading="lazy"
                  alt=""
                  className="mb-2 w-20 brightness-200"
                />
                <span className="font-medium text-2xl text-white block">
                  DUBAI MARINA
                </span>
              </div>
              <img
                src={img5}
                alt=""
                loading="lazy"
                className="h-full max-h-[800px] w-full object-cover"
              />
            </Link>
          </SwiperSlide>
        </Swiper>
      </>
    </section>
  );
};

export default Gallery;
