import React, { useContext, useEffect } from "react";
import Navbar from "../Shared/Navbar";
import { Link, Outlet } from "react-router-dom";
import Footer from "../Shared/Footer";
import { DataContext } from "../Context/Context";
import Register from "../Components/Register";
import image from "../Assets/1.jpg";
import Login from "../Components/Login";
import "./style.css";

const Main = () => {
  const { open, setOpen, user, logout } = useContext(DataContext);

  useEffect(() => {
    if (open) {
      // Disable scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Enable scrolling
      document.body.style.overflow = "";
    }

    // Cleanup to ensure no residual styles
    return () => {
      document.body.style.overflow = "";
    };
  }, [open]);

  return (
    <div className="relative">
      <dialog id="register_modal" className="modal">
        <div className="modal-box lg:h-[60%] md:h-[50%] h-[50%]  w-[100%] lg:min-w-[800px] flex p-0">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 z-50">
              ✕
            </button>
          </form>
          <div className="lg:w-[50%] md:w-[50%] w-full flex items-center">
            <Register />
          </div>
          <div className="g:w-[50%] md:w-[50%] w-full lg:block md:block hidden">
            <img src={image} alt="" className="h-full object-cover" />
          </div>
        </div>
      </dialog>
      <dialog id="login_modal" className="modal w-full">
        <div className="modal-box lg:h-[60%] md:h-[50%] h-[40%] w-[100%] lg:min-w-[800px] flex p-0">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 z-50">
              ✕
            </button>
          </form>
          <div className="lg:w-[50%] md:w-[50%] w-full flex items-center">
            <Login />
          </div>
          <div className="lg:w-[50%] md:w-[50%] w-full lg:block md:block hidden">
            <img src={image} alt="" className="h-full object-cover" />
          </div>
        </div>
      </dialog>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Main;
