import {
  FaWifi,
  FaSwimmingPool,
  FaCar,
  FaFire,
  FaBed,
  FaBicycle,
  FaTv,
  FaGamepad,
  FaChair,
  FaBlender,
  FaFan,
  FaCogs,
  FaToilet,
  FaTree,
  FaBaby,
  FaPlug,
  FaCoffee,
} from "react-icons/fa";
import {
  MdOutlineSmokeFree,
  MdOutlineFireExtinguisher,
  MdOutlineShower,
  MdOutlineBeachAccess,
  MdOutlinePark,
} from "react-icons/md";
import {
  GiWashingMachine,
  GiVacuumCleaner,
  GiHanger,
  GiChickenOven,
} from "react-icons/gi";
import {
  FaBellConcierge,
  FaToiletPortable,
  FaDumbbell,
  FaKitMedical,
  FaLaptop,
} from "react-icons/fa6";
import { LuMicrowave, LuFireExtinguisher } from "react-icons/lu";

const getAmenityIcon = (amenityName) => {
  const icons = {
    Internet: <FaWifi />,
    Wireless: <FaWifi />,
    "Air conditioning": <FaFan />,
    "Swimming pool": <FaSwimmingPool />,
    Kitchen: <FaBellConcierge />,
    Fireplace: <FaFire />,
    "Washing Machine": <GiWashingMachine />,
    Dryer: <GiVacuumCleaner />,
    "Hair Dryer": <FaFan />,
    Heating: <FaFire />,
    "Smoke detector": <MdOutlineSmokeFree />,
    "Carbon Monoxide Detector": <MdOutlineSmokeFree />,
    "First aid kit": <MdOutlineFireExtinguisher />,
    "Fire Extinguisher": <MdOutlineFireExtinguisher />,
    Essentials: <FaBed />,
    Shampoo: <MdOutlineShower />,
    Hangers: <GiHanger />,
    Iron: <FaCogs />,
    "Laptop Friendly workspace": <FaPlug />,
    TV: <FaTv />,
    "Sound system": <FaGamepad />,
    "Street parking": <FaCar />,
    "Free parking": <FaCar />,
    Balcony: <FaTree />,
    "Suitable for children": <FaBaby />,
    "Suitable for infants": <FaBaby />,
    Linens: <FaBed />,
    "Outdoor grill": <FaFire />,
    Toaster: <FaBlender />,
    Dishwasher: <FaCogs />,
    Microwave: <LuMicrowave />,
    Oven: <GiChickenOven />,
    "Electric kettle": <FaCogs />,
    "Coffee/tea maker": <FaCogs />,
    Bidet: <FaToilet />,
    "Baby crib": <FaBaby />,
    Stove: <FaCogs />,
    Refrigerator: <FaCogs />,
    "Garden or backyard": <FaTree />,
    "High chair": <FaChair />,
    "Kitchen utensils": <FaCogs />,
    "Hot water": <FaCogs />,
    "Private entrance": <FaPlug />,
    "Extra pillows and blankets": <FaBed />,
    "Cooking basics": <FaCogs />,
    "Beach essentials": <MdOutlineBeachAccess />,
    "Room darkening shades": <FaChair />,
    Freezer: <FaToiletPortable />,
    Laundromat: <GiWashingMachine />,
    "Baby bath": <FaBaby />,
    "Babysitter recommendations": <FaBaby />,
    "Changing table": <FaBaby />,
    "Children's dinnerware": <FaBaby />,
    "Video games": <FaGamepad />,
    Beach: <MdOutlineBeachAccess />,
    "Beach front": <MdOutlineBeachAccess />,
    "Beach view": <MdOutlineBeachAccess />,
    "Water view": <MdOutlineBeachAccess />,
    Waterfront: <MdOutlineBeachAccess />,
    "Kayak canoe": <FaBicycle />,
    "Luggage dropoff allowed": <FaPlug />,
    "Long term stays allowed": <FaBed />,
    "Cleaning before checkout": <GiVacuumCleaner />,
    "Outlet covers": <FaPlug />,
    "Books for kids": <FaBaby />,
    "Dining table": <FaChair />,
    "Outdoor furniture": <FaChair />,
    Safe: <FaCogs />,
    "Fire Extinguisher": <LuFireExtinguisher />,
    "Fire pit": <FaFire />,
    "Cleaning products": <GiVacuumCleaner />,
    "Body soap": <MdOutlineShower />,
    "Shower gel": <MdOutlineShower />,
    "Clothing storage": <FaBed />,
    "Drying rack for clothing": <GiWashingMachine />,
    "Board games": <FaGamepad />,
    "Baking sheet": <FaBlender />,
    "Barbeque utensils": <FaBlender />,
    Blender: <FaBlender />,
    Coffee: <FaCogs />,
    Coffee: <FaCoffee />,
    Gym: <FaDumbbell />,
    "Laptop Friendly workspace": <FaLaptop />,
    Laptop: <FaLaptop />,
    "First aid kit": <FaKitMedical />,
    "Mini fridge": <FaCogs />,
    "Trash Compactor": <GiWashingMachine />,
    "Wine glasses": <FaBlender />,
    "Outdoor kitchen": <FaBellConcierge />,
  };

  return icons[amenityName] || <FaBed />; // Default icon
};

const AmenitiesList = ({ amenities }) => (
  <div>
    <div className="grid lg:grid-cols-2 gap-3">
      {amenities?.slice(0, 16)?.map((amenity) => (
        <div
          key={amenity.id}
          className="flex items-center gap-2 text-[#aca9a5]"
        >
          {getAmenityIcon(amenity.amenityName)}{" "}
          <span className="font-thin text-[16px] text-[#aca9a5]">
            {amenity.amenityName}
          </span>
        </div>
      ))}
    </div>
    <div
      className=" flex items-end gap-2 mt-4 cursor-pointer"
      onClick={() => document.getElementById("my_modal_3").showModal()}
    >
      <div className="w-8 h-[1px] bg-[#aca9a5]"></div>
      <span className="font-thin text-[16px] text-[#aca9a5] leading-none">
        Show All
      </span>
    </div>
    {/* You can open the modal using document.getElementById('ID').showModal() method */}
    <dialog id="my_modal_3" className="modal">
      <div className="modal-box">
        <form method="dialog">
          {/* if there is a button in form, it will close the modal */}
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <div className="grid lg:grid-cols-2 gap-5">
          {amenities?.map((amenity) => (
            <div
              key={amenity.id}
              className="flex items-center gap-2 text-[#aca9a5]"
            >
              <span className="text-2xl">
                {getAmenityIcon(amenity.amenityName)}{" "}
              </span>
              <span className="font-thin text-[16px] text-[#aca9a5]">
                {amenity.amenityName}
              </span>
            </div>
          ))}
        </div>
      </div>
    </dialog>
  </div>
);

export default AmenitiesList;
