import axios from "axios";
import React, {
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import GallerySlider from "../../Components/GallerySlider";
import ListingCalendar from "../../Components/ListingCalendar";
import toast from "react-hot-toast";
import { differenceInDays } from "date-fns";
import PricingTable from "../../Components/PricingTable";
import { DataContext } from "../../Context/Context";
import MapView from "../../Components/MapView";
import AmenitiesList from "../../Components/AmenitiesList";
import CustomDateRangePicker from "../../Components/CustomDateRangePicker";
import { fetchListingFeeSettings } from "../../Components/fetchListingFeeSettings";
import { fetchListingTaxSettings } from "../../Components/fetchListingTaxSettings";
import { calculateTaxes } from "../../Components/calculateTaxes";

const SingleListing = () => {
  const { user, endDate, startDate } = useContext(DataContext);
  const { id } = useParams();
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedServices2, setSelectedServices2] = useState([]);
  const [listingSetting, setListingSetting] = useState([]);
  const [updatedPrice, setUpdatedPrice] = useState(null);
  const [priceLocked, setPriceLocked] = useState(false);
  const [nights, setNights] = useState(null);
  const [nightsLocked, setNightsLocked] = useState(false);
  const [tax, setTax] = useState([]);
  const [services, setServices] = useState([]);
  const [servicesLoading, setServicesLoading] = useState([]);
  const [weeklyDiscount, setWeeklyDiscount] = useState(0);
  const [subtotalData, setSubtotalData] = useState(null);
  let [countGuest, setCountGuest] = useState(0);
  let [countChild, setCountChild] = useState(0);
  let [countInfants, setCountInfants] = useState(0);
  const [visible, setVisible] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const availabilityRef = useRef(null);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [calendarData, setCalendarData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await fetchListingFeeSettings(listing?.id);
        setListingSetting(result);
      } catch (err) {
        setListingSetting(null);
      } finally {
        setLoading(false);
      }
    };

    if (listing?.id) {
      fetchData();
    }
  }, [listing?.id]);

  const bedLineFee = listingSetting?.find(
    (item) => item.feeType === "bedLinenFee"
  );

  // Fetch tax settings
  useEffect(() => {
    const fetchTaxSettings = async () => {
      try {
        const settings = await fetchListingTaxSettings(id);
        setTax(settings || []);
      } catch (error) {
        // console.error("Failed to fetch listing tax settings:", error.message);
      }
    };

    if (id) {
      fetchTaxSettings();
    }
  }, [id]);

  const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  let lat = listing?.lat || "";
  let long = listing?.lng || "";

  useEffect(() => {
    setServicesLoading(true);
    const fetchServices = async () => {
      try {
        const response = await axios.get(
          "https://365luxuryhomes.com/api/services"
        );
        setServices(response.data);
      } catch (error) {
        // console.error("Error fetching calendar for the listing:", error);
      } finally {
        setServicesLoading(false);
      }
    };
    fetchServices();
  }, []);

  const handleServiceChange = (e, service) => {
    const { checked } = e.target;
    setSelectedServices((prev) => {
      if (checked) {
        return [...prev, service];
      } else {
        return prev.filter((item) => item._id !== service._id);
      }
    });
  };

  const handleServiceChange2 = (e, service) => {
    const { checked } = e.target;
    setSelectedServices2((prev) => {
      if (checked) {
        return [...prev, service];
      } else {
        return prev.filter((item) => item._id !== service._id);
      }
    });
  };

  // handle counting Guest, Childs, and Infants
  const handleCounter = (value, type) => {
    const updateCount = (count, setCount, limit) => {
      setCount(
        value === "minus" ? Math.max(0, count - 1) : Math.min(limit, count + 1)
      );
    };
    if (type === "guest") {
      updateCount(
        countGuest,
        setCountGuest,
        listing.personCapacity - 1 - countChild
      );
    } else if (type === "child") {
      updateCount(
        countChild,
        setCountChild,
        listing.personCapacity - 1 - countGuest
      );
    } else if ((type = "infants")) {
      updateCount(countInfants, setCountInfants, Infinity);
    }
  };

  const handleScroll = () => {
    availabilityRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  let dateObject = new Date(endDate);
  dateObject.setDate(dateObject.getDate() - 1);
  let newFormattedDate = formatDate(dateObject);

  useEffect(() => {
    const fetchCalendarForListing = async () => {
      setCalendarLoading(true);
      try {
        // Fetch access token
        const tokenResponse = await axios.post(
          "https://365luxuryhomes.com/api/accessToken"
        );
        const accessToken = tokenResponse.data.access_token;
        // Fetch calendar data for the single listing
        const response = await axios.get(
          `https://api.hostaway.com/v1/listings/${id}/calendar`,
          {
            params: {
              startDate: formatDate(startDate),
              endDate: newFormattedDate,
              includeResources: 1,
            },
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Cache-Control": "no-cache",
            },
          }
        );
        setCalendarData(response.data.result);
      } catch (error) {
        // console.error("Error fetching calendar for the listing:", error);
      } finally {
        setCalendarLoading(false);
      }
    };
    if (id && formatDate(startDate) && newFormattedDate) {
      fetchCalendarForListing();
    }
  }, [id, formatDate(startDate), newFormattedDate]);

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const tokenResponse = await axios.post(
          "https://365luxuryhomes.com/api/accessToken"
        );
        const accessToken = tokenResponse.data.access_token;
        const response = await axios.get(
          `https://api.hostaway.com/v1/listings/${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Cache-Control": "no-cache",
            },
          }
        );
        setListing(response.data.result);
      } catch (error) {
        // console.error("Error fetching listing:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchListing();
  }, [id]);

  const truncateDescription = (description, wordLimit) => {
    const words = description?.split(" ");
    if (words?.length > wordLimit) {
      return words?.slice(0, wordLimit).join(" ") + `...`;
    }
    return description;
  };

  const formatTime = (time24) => {
    const hours = Math.floor(time24);
    const minutes = (time24 % 1) * 60;
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes === 0 ? "00" : minutes;
    return `${formattedHours}:${formattedMinutes} ${period}`;
  };

  useEffect(() => {
    if (priceLocked) {
      const totalPrice = calendarData?.reduce(
        (sum, item) => sum + (item.price || 0),
        0
      );

      let discount = 0;

      if (nights >= 30) {
        discount = 0.2;
      } else if (nights >= 7) {
        discount = 0.1;
      }

      const discountValue = totalPrice * discount;
      setWeeklyDiscount(discountValue);

      const discountedPrice = totalPrice * (1 - discount);
      const updatedTotalPrice = discountedPrice * 1.05;

      setUpdatedPrice(updatedTotalPrice);
      setSubtotalData((prevData) => ({
        ...prevData,
        updatedPrice: updatedTotalPrice,
        weeklyDiscount: discountValue,
      }));
    }
  }, [calendarData, priceLocked]);

  useEffect(() => {
    if (nightsLocked) {
      const totalNights = differenceInDays(endDate, startDate);
      setNights(totalNights);
      setSubtotalData((prevData) => ({
        ...prevData,
        nights: totalNights,
      }));
      setNightsLocked(true);
    }
  }, [nights, nightsLocked, startDate, endDate]);

  const handleBookNow = () => {
    if (!priceLocked && calendarData) {
      const totalPrice = calendarData?.reduce(
        (sum, item) => sum + (item.price || 0),
        0
      );

      let discount = 0;

      if (nights >= 30) {
        discount = 0.2;
      } else if (nights >= 7) {
        discount = 0.1;
      }

      const discountValue = totalPrice * discount;
      setWeeklyDiscount(discountValue);

      const discountedPrice = totalPrice * (1 - discount);
      const updatedTotalPrice = discountedPrice * 1.05;

      setUpdatedPrice(updatedTotalPrice);
      setSubtotalData((prevData) => ({
        ...prevData,
        updatedPrice: updatedTotalPrice,
        weeklyDiscount: discountValue,
      }));
      setPriceLocked(true);
    }
  };

  const calculateNights = () => {
    if (!nightsLocked) {
      const totalNights = differenceInDays(endDate, startDate);
      setNights(totalNights);
      setSubtotalData((prevData) => ({
        ...prevData,
        nights: totalNights,
      }));
      setNightsLocked(true);
    }
  };

  const handleBooking = (e) => {
    e.preventDefault();
    const guestData = e.target[1].value;

    if (!startDate && !endDate) {
      toast.error("Please provide start & end date");
    } else {
      if (guestData === "") {
        toast.error("Please select guest quantity");
      } else {
        const [guestCount, childCount = null, infantCount = null] = guestData
          .match(/\d+/g)
          .map(Number);

        const cleaningFee = listing?.cleaningFee;
        const additionalValue = selectedServices.reduce(
          (total, service) => total + service.value,
          0
        );

        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        setSubtotalData({
          formattedStartDate,
          formattedEndDate,
          guestCount,
          childCount,
          infantCount,
          nights,
          updatedPrice,
          cleaningFee,
          bedLineFee,
          startDate,
          endDate,
          selectedServices,
          additionalValue,
          selectedServices2,
          user,
          listing,
          weeklyDiscount,
        });
        handleBookNow();
        calculateNights();
      }
    }
  };

  const handleCheckout = () => {
    navigate("/checkout", { state: subtotalData });
  };

  const handleDateChange = ({ startDate, endDate }) => {};

  const { cityTourismTax, occupancyTax, vatGstTax } = calculateTaxes(
    tax,
    subtotalData?.nights,
    subtotalData?.updatedPrice
  );

  return (
    <section className="lg:pt-28 md:pt-24 pt-24 pb-10">
      <div className="container">
        <h1 className="leading-normal font-medium text-[#aca9a5] lg:text-[1.6rem] md:text-[1.4rem] text-[1rem] mb-2">
          {listing?.name}
        </h1>
        <div className="flex items-start gap-8 relative">
          {loading ? (
            <div className="lg:w-[70%] md:w-[70%] w-full py-10">
              <div className="flex w-full flex-col gap-4">
                <div className="skeleton h-4 w-28"></div>
                <div className="skeleton h-4 w-full"></div>
                <div className="skeleton h-72 w-full"></div>
                <div className="flex items-center gap-4">
                  <div className="skeleton h-32 w-44"></div>
                  <div className="skeleton h-32 w-44"></div>
                  <div className="skeleton h-32 w-44"></div>
                  <div className="skeleton h-32 w-44"></div>
                </div>
                <div className="skeleton h-4 w-28"></div>
                <div className="skeleton h-4 w-full"></div>
                <div className="skeleton h-4 w-full"></div>
                <div className="skeleton h-4 w-full"></div>
                <div className="skeleton h-4 w-full"></div>
                <div className="skeleton h-4 w-full"></div>
              </div>
            </div>
          ) : (
            <div className="lg:w-[70%] md:w-[70%] w-full">
              <div className="">
                <Suspense
                  fallback={<div className="skeleton h-72 w-full"></div>}
                >
                  <GallerySlider images={listing?.listingImages} />
                </Suspense>
              </div>

              <div className="flex w-full lg:flex-row md:flex-row flex-col items-center justify-between mt-4">
                <div className="lg:w-auto md:w-auto w-full lg:flex md:flex grid grid-cols-2 gap-4 bg-[#f9f9f9] p-3 text-[#aca9a5] text-[16px] rounded-lg">
                  <div className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={20}
                      height={20}
                      className="text-inherit"
                      fill={"none"}
                    >
                      <path
                        d="M6.57757 15.4816C5.1628 16.324 1.45336 18.0441 3.71266 20.1966C4.81631 21.248 6.04549 22 7.59087 22H16.4091C17.9545 22 19.1837 21.248 20.2873 20.1966C22.5466 18.0441 18.8372 16.324 17.4224 15.4816C14.1048 13.5061 9.89519 13.5061 6.57757 15.4816Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                      />
                    </svg>
                    <span className="text-sm mt-[2px]">
                      {listing?.personCapacity + " " + "Guest"}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={20}
                      height={20}
                      className="text-inherit"
                      fill={"none"}
                    >
                      <path
                        d="M22 17.5H2"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22 21V16C22 14.1144 22 13.1716 21.4142 12.5858C20.8284 12 19.8856 12 18 12H6C4.11438 12 3.17157 12 2.58579 12.5858C2 13.1716 2 14.1144 2 16V21"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 12V10.2134C11 9.83272 10.9428 9.70541 10.6497 9.55538C10.0395 9.24292 9.29865 9 8.5 9C7.70135 9 6.96055 9.24292 6.35025 9.55538C6.05721 9.70541 6 9.83272 6 10.2134L6 12"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M18 12V10.2134C18 9.83272 17.9428 9.70541 17.6497 9.55538C17.0395 9.24292 16.2987 9 15.5 9C14.7013 9 13.9605 9.24292 13.3503 9.55538C13.0572 9.70541 13 9.83272 13 10.2134L13 12"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M21 12V7.36057C21 6.66893 21 6.32311 20.8079 5.99653C20.6157 5.66995 20.342 5.50091 19.7944 5.16283C17.5869 3.79978 14.8993 3 12 3C9.10067 3 6.41314 3.79978 4.20558 5.16283C3.65804 5.50091 3.38427 5.66995 3.19213 5.99653C3 6.32311 3 6.66893 3 7.36057V12"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                    <span className="text-sm mt-[2px]">
                      {listing?.bedroomsNumber + " " + "Bedrooms"}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={20}
                      height={20}
                      className="text-inherit"
                      fill={"none"}
                    >
                      <path
                        d="M22 17.5H2"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22 21V16C22 14.1144 22 13.1716 21.4142 12.5858C20.8284 12 19.8856 12 18 12H6C4.11438 12 3.17157 12 2.58579 12.5858C2 13.1716 2 14.1144 2 16V21"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 12V10.2134C11 9.83272 10.9428 9.70541 10.6497 9.55538C10.0395 9.24292 9.29865 9 8.5 9C7.70135 9 6.96055 9.24292 6.35025 9.55538C6.05721 9.70541 6 9.83272 6 10.2134L6 12"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M18 12V10.2134C18 9.83272 17.9428 9.70541 17.6497 9.55538C17.0395 9.24292 16.2987 9 15.5 9C14.7013 9 13.9605 9.24292 13.3503 9.55538C13.0572 9.70541 13 9.83272 13 10.2134L13 12"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M21 12V7.36057C21 6.66893 21 6.32311 20.8079 5.99653C20.6157 5.66995 20.342 5.50091 19.7944 5.16283C17.5869 3.79978 14.8993 3 12 3C9.10067 3 6.41314 3.79978 4.20558 5.16283C3.65804 5.50091 3.38427 5.66995 3.19213 5.99653C3 6.32311 3 6.66893 3 7.36057V12"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                    <span className="text-sm mt-[2px]">
                      {listing?.bedroomsNumber + " " + "Beds"}
                    </span>
                  </div>
                </div>
                <a
                  onClick={handleScroll}
                  className="py-2 cursor-pointer hover:no-underline px-4 rounded-lg border-2 border-[#aca9a5] text-[#aca9a5] bg-transparent text-[16px] hover:bg-[#aca9a5] hover:text-white lg:w-auto md:w-auto w-full text-center lg:mt-0 md:mt-0 mt-4"
                >
                  Check Availability
                </a>
              </div>

              {/* Booking from for mobile  */}

              <div className="w-full lg:hidden md:hidden block lg:mt-20 md:mt-16 mt-5">
                <div className="p-3 bg-[#f9f9f9] rounded-lg">
                  <h2 className="text-[20px] text-[#aca9a5] font-thin leading-none mb-6">
                    Book Now
                  </h2>
                  <form
                    onSubmit={handleBooking}
                    className=" lg:flex w-full flex-col md:flex items-center gap-4"
                  >
                    <div className="grid w-full grid-cols-1 justify-center gap-4">
                      {/* <DateInput /> */}
                      <CustomDateRangePicker onChange={handleDateChange} />
                      <div className="relative w-full rounded-md h-[52px]">
                        <input
                          type="text"
                          name=""
                          placeholder="Select Guests"
                          id="guest-field"
                          readOnly
                          value={
                            countGuest > 0 || countChild > 0 || countInfants > 0
                              ? `${
                                  countGuest > 0 ? `${countGuest} Guest` : ""
                                }${countChild > 0 ? ", " : ""}${
                                  countChild > 0 ? `${countChild} Child` : ""
                                }${countInfants > 0 ? ", " : ""}${
                                  countInfants > 0
                                    ? `${countInfants} Infants`
                                    : ""
                                }`
                              : ""
                          }
                          onClick={() => setVisible(!visible)}
                          className="block text-[14px] placeholder:text-[#aca9a5] text-[#aca9a5] w-full h-[52px] max-w-sm px-3 py-2 border border-gray-300 focus:outline-none focus:ring-[#aca9a5] focus:border-[#aca9a5] rounded-md"
                        />
                        {visible && (
                          <div
                            id="guest-counter"
                            className="bg-white p-4 shadow-md absolute top-14 w-full z-20"
                          >
                            <div className="flex flex-col gap-4">
                              <div className="flex items-center justify-between gap-2">
                                <div>
                                  <h2 className="text-[14px] leading-none font-medium text-[#aca9a5]">
                                    Adult
                                  </h2>
                                  <span className="text-xs text-[#aca9a5]">
                                    Ages 13 or above
                                  </span>
                                </div>
                                <div className="flex items-center justify-center gap-2">
                                  <div
                                    onClick={() =>
                                      handleCounter("minus", "guest")
                                    }
                                    className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width={20}
                                      height={20}
                                      className="text-[#aca9a5]"
                                      fill={"none"}
                                    >
                                      <path
                                        d="M20 12L4 12"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                  <span className="text-[#aca9a5]">
                                    {countGuest}
                                  </span>
                                  <div
                                    onClick={() =>
                                      handleCounter("plus", "guest")
                                    }
                                    className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width={20}
                                      height={20}
                                      className="text-[#aca9a5]"
                                      fill={"none"}
                                    >
                                      <path
                                        d="M12 4V20"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M4 12H20"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className="flex items-center justify-between gap-2">
                                <div>
                                  <h2 className="text-[14px] leading-none font-medium text-[#aca9a5]">
                                    Children
                                  </h2>
                                  <span className="text-xs text-[#aca9a5]">
                                    Ages 2 to 12
                                  </span>
                                </div>
                                <div className="flex items-center justify-center gap-2">
                                  <div
                                    onClick={() =>
                                      handleCounter("minus", "child")
                                    }
                                    className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width={20}
                                      height={20}
                                      className="text-[#aca9a5]"
                                      fill={"none"}
                                    >
                                      <path
                                        d="M20 12L4 12"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                  <span className="text-[#aca9a5]">
                                    {countChild}
                                  </span>
                                  <div
                                    onClick={() =>
                                      handleCounter("plus", "child")
                                    }
                                    className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width={20}
                                      height={20}
                                      className="text-[#aca9a5]"
                                      fill={"none"}
                                    >
                                      <path
                                        d="M12 4V20"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M4 12H20"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>

                              <div className="flex items-center justify-between gap-2">
                                <div>
                                  <h2 className="text-[14px] leading-none font-medium text-[#aca9a5]">
                                    Infants
                                  </h2>
                                  <span className="text-xs text-[#aca9a5]">
                                    Under 2 years
                                  </span>
                                </div>
                                <div className="flex items-center justify-center gap-2">
                                  <div
                                    onClick={() =>
                                      handleCounter("minus", "infants")
                                    }
                                    className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width={20}
                                      height={20}
                                      className="text-[#aca9a5]"
                                      fill={"none"}
                                    >
                                      <path
                                        d="M20 12L4 12"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                  <span className="text-[#aca9a5]">
                                    {countInfants}
                                  </span>
                                  <div
                                    onClick={() =>
                                      handleCounter("plus", "infants")
                                    }
                                    className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width={20}
                                      height={20}
                                      className="text-[#aca9a5]"
                                      fill={"none"}
                                    >
                                      <path
                                        d="M12 4V20"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M4 12H20"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>

                              <span
                                onClick={() => setVisible(!visible)}
                                className="text-[#aca9a5] text-[14px] cursor-pointer mt-4 flex items-center gap-1 hover:text-red-500"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                                  className="text-inherit"
                                  fill={"none"}
                                >
                                  <path
                                    d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                Close
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="w-full">
                        <div className="collapse border rounded-lg">
                          <input type="checkbox" className="peer" />
                          <div className="text-[14px] flex items-center justify-between font-medium leading-none text-[#aca9a5] collapse-title pr-4">
                            <span className="text-[16px]">
                              Add-ons services
                            </span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width={20}
                              height={20}
                              className="text-inherit"
                              fill={"none"}
                            >
                              <path
                                d="M12 4V20M20 12H4"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="collapse-content grid grid-cols-1 gap-2">
                            {services
                              ?.filter(
                                (service) => service?.category === "Add-On"
                              )
                              ?.map((service) => (
                                <label
                                  key={service?._id}
                                  className="flex items-center gap-2 cursor-pointer"
                                >
                                  <input
                                    type="checkbox"
                                    value={service?.value}
                                    checked={selectedServices.some(
                                      (item) => item?._id === service?._id
                                    )}
                                    onChange={(e) =>
                                      handleServiceChange(e, service)
                                    }
                                    className="w-4 h-4 text-[#aca9a5] focus:ring-[#aca9a5] border-gray-300 rounded"
                                  />
                                  <span className="text-[#aca9a5] text-[14px]">
                                    {service?.label}
                                  </span>
                                </label>
                              ))}
                          </div>
                        </div>

                        {/* Display Selected Services */}
                        {selectedServices.length > 0 && (
                          <div className="mt-2">
                            <h3 className="text-[14px] font-medium text-[#aca9a5] mb-2">
                              Selected Adds-on Services:
                            </h3>
                            <ul className="list-disc list-inside text-[#aca9a5]">
                              {selectedServices.map((service) => (
                                <li
                                  key={service._id}
                                  className="text-[14px] font-thin"
                                >
                                  {service.label}: AED {service.value} (Per day)
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>

                      <div className="w-full">
                        <div className="collapse border rounded-lg">
                          <input type="checkbox" className="peer" />
                          <div className="text-[14px] flex items-center justify-between font-medium leading-none text-[#aca9a5] collapse-title pr-4">
                            <span className="text-[16px]">
                              Additional Services{" "}
                            </span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width={20}
                              height={20}
                              className="text-inherit"
                              fill={"none"}
                            >
                              <path
                                d="M12 4V20M20 12H4"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="collapse-content grid grid-cols-1 gap-2">
                            {services
                              ?.filter(
                                (service) => service?.category === "Additional"
                              )
                              ?.map((service) => (
                                <label
                                  key={service?._id}
                                  className="flex items-center gap-2 cursor-pointer"
                                >
                                  <input
                                    type="checkbox"
                                    value={service?.value}
                                    checked={selectedServices2.some(
                                      (item) => item?._id === service?._id
                                    )}
                                    onChange={(e) =>
                                      handleServiceChange2(e, service)
                                    }
                                    className="w-4 h-4 text-[#aca9a5] focus:ring-[#aca9a5] border-gray-300 rounded"
                                  />
                                  <span className="text-[#aca9a5] text-[14px]">
                                    {service?.label}
                                  </span>
                                </label>
                              ))}
                          </div>
                        </div>

                        {/* Display Selected Services */}
                        {selectedServices2.length > 0 && (
                          <div className="mt-2">
                            <h3 className="text-[14px] font-medium text-[#aca9a5] mb-2">
                              Selected Additional Services:
                            </h3>
                            <ul className="list-disc list-inside text-[#aca9a5]">
                              {selectedServices2.map((service) => (
                                <li
                                  key={service._id}
                                  className="text-[14px] font-thin"
                                >
                                  {service.label}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>

                      {/* Submit Button */}
                      {!subtotalData && (
                        <button
                          className="mb-[2px] text-[16px] px-6 rounded-md w-full h-[50px] bg-[#797a76]
        text-white font-normal border-none hover:bg-gray-400 uppercase"
                        >
                          Check Availability
                        </button>
                      )}
                    </div>
                  </form>

                  <div>
                    {subtotalData && (
                      <Suspense
                        fallback={<div className="skeleton h-72 w-full"></div>}
                      >
                        <PricingTable
                          price={subtotalData.updatedPrice}
                          cleaningFee={subtotalData.cleaningFee}
                          nights={subtotalData.nights}
                          additional={selectedServices.reduce(
                            (total, service) => total + service.value,
                            0
                          )}
                          bedLineFee={bedLineFee?.amount}
                          cityTourismTax={cityTourismTax}
                          occupancyTax={occupancyTax}
                          vatGstTax={vatGstTax}
                          weeklyDiscount={weeklyDiscount}
                        />
                      </Suspense>
                    )}
                    {subtotalData && (
                      <button
                        onClick={handleCheckout}
                        className="mb-[2px] text-[16px] px-6 rounded-md w-full h-[50px] bg-[#797a76]
        text-white font-normal border-none hover:bg-gray-400 uppercase"
                      >
                        Pay Now
                      </button>
                    )}
                  </div>
                </div>

                <div className="p-3 bg-[#f9f9f9] rounded-lg mt-4">
                  <h2 className="text-[20px] text-[#aca9a5] font-thin leading-none mb-6">
                    Price Details
                  </h2>

                  <p className="font-semibold text-[14px] text-[#aca9a5] mt-0 mb-2">
                    Extra Price per guest:{" "}
                    <span className="font-thin text-[14px]">
                      AED {listing?.priceForExtraPerson}
                    </span>
                  </p>
                  <p className="font-semibold text-[14px] text-[#aca9a5] mt-0 mb-2">
                    Cleaning Fee:{" "}
                    <span className="font-thin text-[14px]">
                      AED {listing?.cleaningFee}
                    </span>
                  </p>
                  <p className="font-semibold text-[14px] text-[#aca9a5] mt-0">
                    Minimum no of nights:{" "}
                    <span className="font-thin text-[14px]}">
                      {listing?.minNights}
                    </span>
                  </p>
                </div>
              </div>

              <div className="p-3 bg-[#f9f9f9] rounded-lg mt-4">
                <h2 className="text-[20px] text-[#aca9a5] font-thin">
                  Description
                </h2>
                <p className="text-[#aca9a5] text-[12px] leading-[20px] font-thin">
                  {showMore
                    ? listing?.bookingcomPropertyDescription
                    : truncateDescription(
                        listing?.bookingcomPropertyDescription,
                        60
                      )}
                  <span
                    onClick={() => setShowMore(!showMore)}
                    className="block text-[14px] cursor-pointer mt-4 text-[#cf9b41]"
                  >
                    {showMore ? "Show less" : "Show more"}
                  </span>
                </p>
              </div>

              <div className="p-3 bg-[#f9f9f9] rounded-lg mt-4">
                <h2 className="text-[20px] text-[#aca9a5] font-thin">
                  Location
                </h2>

                <div className="">
                  <div className="grid lg:grid-cols-2 gap-4 items-start">
                    <p className="font-semibold text-[14px] text-[#aca9a5] mt-0">
                      City:{" "}
                      <span className="font-thin text-[14px]">
                        {listing?.city}
                      </span>
                    </p>
                    <p className="font-semibold text-[14px] text-[#aca9a5] mt-0">
                      Country:{" "}
                      <span className="font-thin text-[14px]">
                        {listing?.country}
                      </span>
                    </p>
                  </div>
                  <div className="mt-5 rounded-xl overflow-hidden">
                    <Suspense
                      fallback={<div className="skeleton h-72 w-full"></div>}
                    >
                      <MapView lat={lat} lng={long} />
                    </Suspense>
                  </div>
                </div>
              </div>

              <div className="p-3 bg-[#f9f9f9] rounded-lg mt-6">
                <h2 className="text-[20px] text-[#aca9a5] font-thin">
                  Listing Details
                </h2>
                <div className="grid lg:grid-cols-2 gap-2 items-start">
                  <p className="font-semibold text-[14px] text-[#aca9a5] mt-0">
                    Property ID:{" "}
                    <span className="font-thin text-[14px]">{listing?.id}</span>
                  </p>

                  <p className="font-semibold text-[14px] text-[#aca9a5] mt-0">
                    Bathrooms:{" "}
                    <span className="font-thin text-[14px]">
                      {listing?.bathroomsNumber}
                    </span>
                  </p>

                  <p className="font-semibold text-[14px] text-[#aca9a5] mt-0">
                    Bedrooms:{" "}
                    <span className="font-thin text-[14px]">
                      {listing?.bedroomsNumber}
                    </span>
                  </p>

                  <p className="font-semibold text-[14px] text-[#aca9a5] mt-0">
                    Check In:{" "}
                    <span className="font-thin text-[14px]">
                      {formatTime(listing?.checkInTimeStart)}
                    </span>
                  </p>

                  <p className="font-semibold text-[14px] text-[#aca9a5] mt-0">
                    Check Out:{" "}
                    <span className="font-thin text-[14px]">
                      {formatTime(listing?.checkOutTime)}
                    </span>
                  </p>
                </div>
              </div>

              <div className="p-3 bg-[#f9f9f9] rounded-lg mt-4">
                <h2 className="text-[20px] text-[#aca9a5] font-thin">
                  Amenities
                </h2>
                <div>
                  <AmenitiesList amenities={listing?.listingAmenities} />
                </div>
              </div>

              <div className="my-6" id="availability" ref={availabilityRef}>
                <h2 className="text-[20px] text-[#aca9a5] font-thin leading-none">
                  Availability
                </h2>
                <span className="text-[12px] text-[#aca9a5]">
                  For booking, click here.
                </span>
              </div>
              <div className="p-3 bg-[#fff] rounded-lg border mt-4">
                <ListingCalendar listingId={listing?.id} />
              </div>
            </div>
          )}

          <div className="w-[30%] lg:block md:block hidden h-full sticky-section">
            <div className="p-3 bg-[#f9f9f9] rounded-lg">
              <h3 className="text-[20px] text-[#aca9a5] font-thin leading-none mb-6">
                Book Now
              </h3>
              <form
                onSubmit={handleBooking}
                className=" lg:flex flex-col md:flex items-center gap-4"
              >
                <div className="grid grid-cols-1 justify-center gap-4">
                  {/* <DateInput /> */}
                  <CustomDateRangePicker onChange={handleDateChange} />
                  <div className="relative w-full rounded-md h-[52px]">
                    <input
                      type="text"
                      name=""
                      placeholder="Select Guests"
                      id="guest-field"
                      readOnly
                      value={
                        countGuest > 0 || countChild > 0 || countInfants > 0
                          ? `${countGuest > 0 ? `${countGuest} Guest` : ""}${
                              countChild > 0 ? ", " : ""
                            }${countChild > 0 ? `${countChild} Child` : ""}${
                              countInfants > 0 ? ", " : ""
                            }${
                              countInfants > 0 ? `${countInfants} Infants` : ""
                            }`
                          : ""
                      }
                      onClick={() => setVisible(!visible)}
                      className="block text-[14px] placeholder:text-[#aca9a5] text-[#aca9a5] w-full h-[52px] max-w-sm px-3 py-2 border border-gray-300 focus:outline-none focus:ring-[#aca9a5] focus:border-[#aca9a5] rounded-md"
                    />
                    {visible && (
                      <div
                        id="guest-counter"
                        className="bg-white p-4 shadow-md absolute top-14 w-full z-20"
                      >
                        <div className="flex flex-col gap-4">
                          <div className="flex items-center justify-between gap-2">
                            <div>
                              <h2 className="text-[14px] leading-none font-medium text-[#aca9a5]">
                                Adult
                              </h2>
                              <span className="text-xs text-[#aca9a5]">
                                Ages 13 or above
                              </span>
                            </div>
                            <div className="flex items-center justify-center gap-2">
                              <div
                                onClick={() => handleCounter("minus", "guest")}
                                className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                                  className="text-[#aca9a5]"
                                  fill={"none"}
                                >
                                  <path
                                    d="M20 12L4 12"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                              <span className="text-[#aca9a5]">
                                {countGuest}
                              </span>
                              <div
                                onClick={() => handleCounter("plus", "guest")}
                                className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                                  className="text-[#aca9a5]"
                                  fill={"none"}
                                >
                                  <path
                                    d="M12 4V20"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4 12H20"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center justify-between gap-2">
                            <div>
                              <h2 className="text-[14px] leading-none font-medium text-[#aca9a5]">
                                Children
                              </h2>
                              <span className="text-xs text-[#aca9a5]">
                                Ages 2 to 12
                              </span>
                            </div>
                            <div className="flex items-center justify-center gap-2">
                              <div
                                onClick={() => handleCounter("minus", "child")}
                                className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                                  className="text-[#aca9a5]"
                                  fill={"none"}
                                >
                                  <path
                                    d="M20 12L4 12"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                              <span className="text-[#aca9a5]">
                                {countChild}
                              </span>
                              <div
                                onClick={() => handleCounter("plus", "child")}
                                className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                                  className="text-[#aca9a5]"
                                  fill={"none"}
                                >
                                  <path
                                    d="M12 4V20"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4 12H20"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center justify-between gap-2">
                            <div>
                              <h2 className="text-[14px] leading-none font-medium text-[#aca9a5]">
                                Infants
                              </h2>
                              <span className="text-xs text-[#aca9a5]">
                                Under 2 years
                              </span>
                            </div>
                            <div className="flex items-center justify-center gap-2">
                              <div
                                onClick={() =>
                                  handleCounter("minus", "infants")
                                }
                                className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                                  className="text-[#aca9a5]"
                                  fill={"none"}
                                >
                                  <path
                                    d="M20 12L4 12"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                              <span className="text-[#aca9a5]">
                                {countInfants}
                              </span>
                              <div
                                onClick={() => handleCounter("plus", "infants")}
                                className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                                  className="text-[#aca9a5]"
                                  fill={"none"}
                                >
                                  <path
                                    d="M12 4V20"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4 12H20"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>

                          <span
                            onClick={() => setVisible(!visible)}
                            className="text-[#aca9a5] text-[14px] cursor-pointer mt-4 flex items-center gap-1 hover:text-red-500"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width={20}
                              height={20}
                              className="text-inherit"
                              fill={"none"}
                            >
                              <path
                                d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Close
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="w-full">
                    <div className="collapse border rounded-lg">
                      <input type="checkbox" className="peer" />
                      <div className="text-[14px] flex items-center justify-between font-medium leading-none text-[#aca9a5] collapse-title pr-4">
                        <span className="text-[16px]">Add-ons services</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width={20}
                          height={20}
                          className="text-inherit"
                          fill={"none"}
                        >
                          <path
                            d="M12 4V20M20 12H4"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="collapse-content grid grid-cols-1 gap-2">
                        {services
                          ?.filter((service) => service?.category === "Add-On")
                          ?.map((service) => (
                            <label
                              key={service?._id}
                              className="flex items-center gap-2 cursor-pointer"
                            >
                              <input
                                type="checkbox"
                                value={service?.value}
                                checked={selectedServices.some(
                                  (item) => item?._id === service?._id
                                )}
                                onChange={(e) =>
                                  handleServiceChange(e, service)
                                }
                                className="w-4 h-4 text-[#aca9a5] focus:ring-[#aca9a5] border-gray-300 rounded"
                              />
                              <span className="text-[#aca9a5] text-[14px]">
                                {service?.label}
                              </span>
                            </label>
                          ))}
                      </div>
                    </div>

                    {/* Display Selected Services */}
                    {selectedServices.length > 0 && (
                      <div className="mt-2">
                        <h3 className="text-[14px] font-medium text-[#aca9a5] mb-2">
                          Selected Adds-on Services:
                        </h3>
                        <ul className="list-disc list-inside text-[#aca9a5]">
                          {selectedServices.map((service) => (
                            <li
                              key={service._id}
                              className="text-[14px] font-thin"
                            >
                              {service.label}: AED {service.value} (Per day)
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  <div className="w-full">
                    <div className="collapse border rounded-lg">
                      <input type="checkbox" className="peer" />
                      <div className="text-[14px] flex items-center justify-between font-medium leading-none text-[#aca9a5] collapse-title pr-4">
                        <span className="text-[16px]">
                          Additional Services{" "}
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width={20}
                          height={20}
                          className="text-inherit"
                          fill={"none"}
                        >
                          <path
                            d="M12 4V20M20 12H4"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="collapse-content grid grid-cols-1 gap-2">
                        {services
                          ?.filter(
                            (service) => service?.category === "Additional"
                          )
                          ?.map((service) => (
                            <label
                              key={service?._id}
                              className="flex items-center gap-2 cursor-pointer"
                            >
                              <input
                                type="checkbox"
                                value={service?.value}
                                checked={selectedServices2.some(
                                  (item) => item?._id === service?._id
                                )}
                                onChange={(e) =>
                                  handleServiceChange2(e, service)
                                }
                                className="w-4 h-4 text-[#aca9a5] focus:ring-[#aca9a5] border-gray-300 rounded"
                              />
                              <span className="text-[#aca9a5] text-[14px]">
                                {service?.label}
                              </span>
                            </label>
                          ))}
                      </div>
                    </div>

                    {/* Display Selected Services */}
                    {selectedServices2.length > 0 && (
                      <div className="mt-2">
                        <h3 className="text-[14px] font-medium text-[#aca9a5] mb-2">
                          Selected Additional Services:
                        </h3>
                        <ul className="list-disc list-inside text-[#aca9a5]">
                          {selectedServices2.map((service) => (
                            <li
                              key={service._id}
                              className="text-[14px] font-thin"
                            >
                              {service.label}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  {/* Submit Button */}
                  {!subtotalData && (
                    <button
                      className="mb-[2px] text-[16px] px-6 rounded-md w-full h-[50px] bg-[#797a76]
        text-white font-normal border-none hover:bg-gray-400 uppercase"
                    >
                      Check Availability
                    </button>
                  )}
                </div>
              </form>

              <div>
                {subtotalData && (
                  <Suspense
                    fallback={<div className="skeleton h-72 w-full"></div>}
                  >
                    <PricingTable
                      price={subtotalData.updatedPrice}
                      cleaningFee={subtotalData.cleaningFee}
                      nights={subtotalData.nights}
                      additional={selectedServices.reduce(
                        (total, service) => total + service.value,
                        0
                      )}
                      bedLineFee={bedLineFee?.amount}
                      cityTourismTax={cityTourismTax}
                      occupancyTax={occupancyTax}
                      vatGstTax={vatGstTax}
                      weeklyDiscount={weeklyDiscount}
                    />
                  </Suspense>
                )}
                {subtotalData && (
                  <button
                    onClick={handleCheckout}
                    className="mb-[2px] text-[16px] px-6 rounded-md w-full h-[50px] bg-[#797a76]
        text-white font-normal border-none hover:bg-gray-400 uppercase"
                  >
                    Pay Now
                  </button>
                )}
              </div>
            </div>
            <div className="p-3 bg-[#f9f9f9] rounded-lg mt-4">
              <h3 className="text-[20px] text-[#aca9a5] font-thin leading-none mb-6">
                Price Details
              </h3>

              <p className="font-semibold text-[14px] text-[#aca9a5] mt-0 mb-2">
                Extra Price per guest:{" "}
                <span className="font-thin text-[14px]">
                  AED {listing?.priceForExtraPerson}
                </span>
              </p>
              <p className="font-semibold text-[14px] text-[#aca9a5] mt-0 mb-2">
                Cleaning Fee:{" "}
                <span className="font-thin text-[14px]">
                  AED {listing?.cleaningFee}
                </span>
              </p>
              <p className="font-semibold text-[14px] text-[#aca9a5] mt-0">
                Minimum no of nights:{" "}
                <span className="font-thin text-[14px]">
                  {listing?.minNights}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SingleListing;
