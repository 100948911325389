import React from "react";

import logo1 from "../../Assets/logos/arabian news@2x.png";
import logo2 from "../../Assets/logos/forbes@2x.png";
import logo3 from "../../Assets/logos/gulf news@2x.png";
import logo4 from "../../Assets/logos/serviced@2x.png";
import logo5 from "../../Assets/logos/khaleej times@2x.png";

const Featured = () => {
  return (
    <section className="lg:py-20 md:py-14 py-10">
      <div className="container">
        <h2 className="font-normal leading-none text-2xl uppercase text-[#aca9a5] text-center">
          AS FEATURED IN
        </h2>

        <div className="flex flex-wrap justify-center lg:justify-center gap-10 mt-10">
          <img
            src={logo1}
            alt=""
            loading="lazy"
            className="w-40 h-auto object-contain"
          />
          <img
            src={logo2}
            alt=""
            loading="lazy"
            className="w-32 h-auto object-contain"
          />
          <img
            src={logo3}
            alt=""
            loading="lazy"
            className="w-40 h-auto object-contain"
          />
          <img
            src={logo4}
            alt=""
            loading="lazy"
            className="w-40 h-auto object-contain"
          />
          <img
            src={logo5}
            alt=""
            loading="lazy"
            className="w-40 h-auto object-contain"
          />
        </div>
      </div>
    </section>
  );
};

export default Featured;
