import React from "react";
import "./stylec.css";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div>
      <section className="w-full h-[40vh] bg-[#aca9a5] pt-10 pb-4">
        <div className="container flex items-end justify-start h-full">
          <h1 className="text-white uppercase">Privacy Policy</h1>
        </div>
      </section>
      <section className="lg:py-20 md:py-14 py-10">
        <div className="container">
          <h3 className="text-[#aca9a5] text-[20px] leading-none">
            {" "}
            Effective Date: January 8, 2025
          </h3>
          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            {" "}
            Welcome to 365 Luxury Homes.
            <br /> Your privacy is of utmost importance to us, and we are
            committed to safeguarding your personal information. This Privacy
            Policy explains how we collect, use, and protect your data when you
            visit our website (
            <Link
              target="_blank"
              className="text-[16px]"
              to={"https://www.365luxuryhomes.com"}
            >
              https://www.365luxuryhomes.com
            </Link>
            ) or interact with our services. By accessing our website or using
            our services, you agree to the practices described in this policy.
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>1. Information We Collect</b> <br />
            We collect personal and non-personal information through various
            means. Personal information includes details you voluntarily provide
            when you fill out contact forms, request information, book a
            property, schedule a service, subscribe to newsletters or
            promotional offers, or communicate with us via email, chat, or
            phone. This may include your name, email address, phone number,
            address or location, payment details (if applicable), and other
            information you choose to provide. Non-personal information, such as
            your IP address, browser type and version, device information, pages
            visited on our website, time spent on the website, and referring
            URLs, is collected automatically.
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>2. How We Use Your Information</b>
            <br />
            We use the information collected to provide, operate, and maintain
            our services. This includes improving your user experience,
            responding to inquiries, processing bookings, delivering customer
            support, and sending promotional emails, special offers, and updates
            (which you can opt-out of at any time). Additionally, we analyze
            website usage to refine our marketing strategies and fulfill legal
            and regulatory obligations.
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>3. How We Share Your Information</b> <br />
            We do not sell or rent your personal information. However, we may
            share your data with trusted third-party service providers who
            assist in website operations, payment processing, and marketing. We
            may also share information with legal authorities when required to
            comply with legal obligations or protect our legal rights. In the
            event of a merger, acquisition, or sale of assets, your data may be
            transferred to the relevant parties.
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>4. Cookies and Tracking Technologies</b> <br />
            We use cookies and similar tracking technologies to enhance your
            browsing experience. These technologies collect non-personal
            information such as browsing preferences, session data, and
            analytics data. You can manage cookies through your browser
            settings, but disabling cookies may impact certain website features.
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>5. Data Security</b> <br />
            We implement appropriate technical and organizational measures to
            safeguard your information. However, no method of data transmission
            over the internet or electronic storage is completely secure. While
            we strive to protect your personal information, we cannot guarantee
            absolute security.
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>6. Your Rights</b>
            <br />
            Depending on your location, you may have rights concerning your
            personal data. These rights include the ability to request access to
            the data we hold about you, request corrections to your personal
            data, request that we delete your personal data, request limits on
            how we process your data, request data portability to another
            organization, and opt-out of promotional emails or marketing
            communications. To exercise these rights, please contact us at{" "}
            <Link className="text-[16px]" to="mailto:info@365luxuryhomes.com">
              info@365luxuryhomes.com
            </Link>
            .
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>7. Third-Party Links</b> <br />
            Our website may include links to third-party websites. We are not
            responsible for the privacy practices or content of these external
            sites. We encourage you to review their privacy policies before
            sharing any personal information.
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>8. Children's Privacy</b>
            <br />
            Our services are not directed at individuals under the age of 18. We
            do not knowingly collect personal information from children. If you
            believe a child has provided us with their information, contact us,
            and we will promptly delete it.
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>9. Payment and Refund Policy</b>
            <br />
            Payments for bookings or services must be made through the secure
            methods provided on our website. Refunds are subject to our terms
            and conditions. If you are eligible for a refund, it will be
            processed within 7-10 business days. For any payment or
            refund-related inquiries, please contact us at{" "}
            <Link className="text-[16px]" href="mailto:info@365luxuryhomes.com">
              info@365luxuryhomes.com
            </Link>
            .
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>10. Changes to This Privacy Policy</b>
            <br />
            We may update this Privacy Policy from time to time. The revised
            policy will be posted on our website with the updated effective
            date. We encourage you to review this page periodically to stay
            informed about how we protect your information.
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>11. Contact Us</b>
            <br />
            If you have any questions, concerns, or requests related to this
            Privacy Policy or the handling of your personal information, please
            reach out to us:
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            Email:{" "}
            <Link className="text-[16px]" to={"mailto:info@365luxuryhomes.com"}>
              info@365luxuryhomes.com
            </Link>
          </p>
          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-1">
            Phone:{" "}
            <Link className="text-[16px]" to={"tel:+971 52 143 4186"}>
              +971 52 143 4186
            </Link>
          </p>
          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-1">
            Address: 4th Street, Al Quoz 3, Next to Gold & Diamond Park, Dubai,
            UAE
          </p>

          <p className="text-[#aca9a5] font-medium text-[18px] leading-[24px] mt-8">
            {" "}
            Thank you for trusting 365 Luxury Homes. Your privacy is our
            priority.
          </p>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
