import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "./style.css";

import img1 from "../../Assets/Vector Smart Object copy 11.png";
import quoteIcon from "../../Assets/qoute-open.png";

const Testimonials = () => {
  return (
    <section className="lg:py-20 md:py-14 py-10 bg-[#fdfcf5]">
      <div className="container relative">
        <h2 className="font-normal leading-[32px] text-2xl uppercase text-[#aca9a5] text-center">
          The Voice of Our Customers
        </h2>
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Navigation, Pagination, Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="relative flex items-center justify-center">
              <div className="lg:w-[70%] lg:p-10 md:p-8 p-5 rounded-xl bg-[#fdfcf5]">
                <div className="">
                  <img
                    src={img1}
                    alt=""
                    loading="lazy"
                    className="w-10 rotate-180 opacity-50"
                  />
                  <p className="w-[80%] mx-auto font-thin text-[#aca9a5] text-[14px] leading-5">
                    great place, and this is the first time we have encountered
                    such excellent service! When renting for a long period of
                    time, we had cleaning included every day, where very nice
                    and positive girls came and cleaned everything thoroughly
                    and wrinkled the towels. always in touch, when the child
                    needed the carpet cleaned, it was replaced with a new one.
                    Well done! I really liked it!
                  </p>
                  <img
                    src={img1}
                    alt=""
                    loading="lazy"
                    className="w-10 ml-auto opacity-50"
                  />
                </div>
                <div className="flex items-end justify-center gap-2 mt-4 w-full mx-auto">
                  <div className="w-8 h-[2px] bg-[#aca9a5]"></div>
                  <h3 className="font-normal leading-none text-[15px] text-[#aca9a5] uppercase">
                    Анастасия И
                  </h3>
                  <div className="w-8 h-[2px] bg-[#aca9a5]"></div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="relative flex items-center justify-center">
              <div className="lg:w-[70%] lg:p-10 md:p-8 p-5 rounded-xl bg-[#fdfcf5]">
                <div className="">
                  <img
                    src={img1}
                    alt=""
                    loading="lazy"
                    className="w-10 rotate-180 opacity-50"
                  />
                  <p className="w-[80%] mx-auto font-thin text-[#aca9a5] text-[14px] leading-5">
                    Paradise on earth! When I saw the breathtaking view I knew I
                    was in the right place! Everything super clean beautiful new
                    with housekeeping included that you don’t see but magically
                    everything is perfect again! Don’t know if the view is
                    better at night or daylight, I will have to come back to
                    decide! Location amazing few steps from pharmacy supermarket
                    and the garage is amazing! If you are a fan of sports car
                    you will love do a garage tour!
                  </p>
                  <img
                    src={img1}
                    loading="lazy"
                    alt=""
                    className="w-10 ml-auto opacity-50"
                  />
                </div>
                <div className="flex items-end justify-center gap-2 mt-4 w-full mx-auto">
                  <div className="w-8 h-[2px] bg-[#aca9a5]"></div>
                  <h3 className="font-normal leading-none text-[15px] text-[#aca9a5] uppercase">
                    Alessandra Stockler
                  </h3>
                  <div className="w-8 h-[2px] bg-[#aca9a5]"></div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="relative flex items-center justify-center">
              <div className="lg:w-[70%] lg:p-10 md:p-8 p-5 rounded-xl bg-[#fdfcf5]">
                <div className="">
                  <img
                    src={img1}
                    alt=""
                    loading="lazy"
                    className="lg:w-16 md:w-16 w-10 rotate-180 opacity-50"
                  />
                  <p className="w-[75%] mx-auto font-thin text-[#aca9a5] text-[14px] leading-5">
                    I stayed for 1 month in 365 Luxury homes apartment in Sadaf
                    JBR. The apartment was fully renovated, fully equipped and
                    all was just perfect. The person in charged took care of all
                    our requests and they even clean the apartment every 3 days.
                    I highly recommend this company
                  </p>
                  <img
                    src={img1}
                    alt=""
                    className="lg:w-16 md:w-16 w-10 ml-auto opacity-50"
                  />
                </div>
                <div className="flex items-end justify-center gap-2 mt-4 w-full mx-auto">
                  <div className="w-8 h-[2px] bg-[#aca9a5]"></div>
                  <h3 className="font-normal leading-none text-[15px] text-[#aca9a5] uppercase">
                    Jac Ba
                  </h3>
                  <div className="w-8 h-[2px] bg-[#aca9a5]"></div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="relative flex items-center justify-center">
              <div className="lg:w-[70%] lg:p-10 md:p-8 p-5 rounded-xl bg-[#fdfcf5]">
                <div className="">
                  <img
                    src={img1}
                    alt=""
                    loading="lazy"
                    className="w-10 rotate-180 opacity-50"
                  />
                  <p className="w-[75%] mx-auto font-thin text-[#aca9a5] text-[14px] leading-5">
                    We had an amazing stay! The apartment and the view is
                    beautiful but the location is even better. There is a bakery
                    right in the corner of the building and a supermarket for
                    buying snacks. The open kitchen area was my favorite part of
                    the apartment. A wonderful view day an night! I wish I had
                    more time to stay there longer.
                  </p>
                  <img src={img1} alt="" className="w-10 ml-auto opacity-50" />
                </div>
                <div className="flex items-end justify-center gap-2 mt-4 w-full mx-auto">
                  <div className="w-8 h-[2px] bg-[#aca9a5]"></div>
                  <h3 className="font-normal leading-none text-[15px] text-[#aca9a5] uppercase">
                    Anton Seidl
                  </h3>
                  <div className="w-8 h-[2px] bg-[#aca9a5]"></div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="relative flex items-center justify-center">
              <div className="lg:w-[70%] lg:p-10 md:p-8 p-5 rounded-xl bg-[#fdfcf5]">
                <div className="">
                  <img
                    src={img1}
                    alt=""
                    loading="lazy"
                    className="w-10 rotate-180 opacity-50"
                  />
                  <p className="w-[75%] mx-auto font-thin text-[#aca9a5] text-[14px] leading-5">
                    We stayed with 365 luxury homes and we booked the apartment
                    in blue waters that I highly recommend, the staff were very
                    helpful and professional. The apartment was luxurious, clean
                    and had everything we needed as a family. The location is
                    amazing that you don’t need to leave the area. We had such a
                    fantastic experience that I've already given them a glowing
                    review. Highly recommended for anyone looking for a
                    luxurious and hassle-free stay!
                  </p>
                  <img src={img1} alt="" className="w-10 ml-auto opacity-50" />
                </div>
                <div className="flex items-end justify-center gap-2 mt-4 w-full mx-auto">
                  <div className="w-8 h-[2px] bg-[#aca9a5]"></div>
                  <h3 className="font-normal leading-none text-[15px] text-[#aca9a5] uppercase">
                    Dana Ahmed
                  </h3>
                  <div className="w-8 h-[2px] bg-[#aca9a5]"></div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="relative flex items-center justify-center">
              <div className="lg:w-[70%] lg:p-10 md:p-8 p-5 rounded-xl bg-[#fdfcf5]">
                <div className="">
                  <img
                    src={img1}
                    alt=""
                    loading="lazy"
                    className="w-10 rotate-180 opacity-50"
                  />
                  <p className="w-[75%] mx-auto font-thin text-[#aca9a5] text-[14px] leading-5">
                    What an amazing apartment. Having stayed in a few apartments
                    over the years this has been the best by far. Every detail
                    has been thought about, from new washing sponges, to
                    dishwasher tablets, to amazing tea and coffee choice to
                    daily cleaning. Not to mention amazing toiletries, beach
                    bags, beach towels. The apartment is furnished beautifully
                    and simple things like lots of hangers, drying rack made it
                    such an enjoyable stay. The views are out of this world and
                    our stay here was simply the best. Looking forward to going
                    back again.
                  </p>
                  <img src={img1} alt="" className="w-10 ml-auto opacity-50" />
                </div>
                <div className="flex items-end justify-center gap-2 mt-4 w-full mx-auto">
                  <div className="w-8 h-[2px] bg-[#aca9a5]"></div>
                  <h3 className="font-normal leading-none text-[15px] text-[#aca9a5] uppercase">
                    Dhrupti
                  </h3>
                  <div className="w-8 h-[2px] bg-[#aca9a5]"></div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="relative flex items-center justify-center">
              <div className="lg:w-[70%] lg:p-10 md:p-8 p-5 rounded-xl">
                <div className="">
                  <img
                    src={img1}
                    alt=""
                    loading="lazy"
                    className="w-10 rotate-180 opacity-50"
                  />
                  <p className="w-[75%] mx-auto font-thin text-[#aca9a5] text-[14px] leading-5">
                    I don't even know where to start , my journey with 365
                    Luxury Homes was out o this world , upon arrival I was
                    welcomed by a lovely security who showed me the way and who
                    to meet, Then was Welcomed by Moh the concierge services the
                    receptionist was amazing , Moh then took me to park my car
                    and took me for a tour around the building as well as in the
                    apartment ,am not exaggerating but when I say luxury I mean
                    Luxury , the apartment was neat , spacious and more than
                    anything it felt like home, I had a beautiful view outside
                    of the London eye and sea view . The staff members are very
                    kind and professional , I would definitely book at 365
                    Luxury homes over and over again .Thank you for your
                    hospitality .
                  </p>
                  <img src={img1} alt="" className="w-10 ml-auto opacity-50" />
                </div>
                <div className="flex items-end justify-center gap-2 mt-4 w-full mx-auto">
                  <div className="w-8 h-[2px] bg-[#aca9a5]"></div>
                  <h3 className="font-normal leading-none text-[15px] text-[#aca9a5] uppercase">
                    masala ganyane
                  </h3>
                  <div className="w-8 h-[2px] bg-[#aca9a5]"></div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonials;
