import React, { useContext, useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import axios from "axios";
import { DataContext } from "../Context/Context";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";

import "../Styles/mapViewStyle.css";
import { Link } from "react-router-dom";

// Create a custom icon for the marker
const customIcon = new L.Icon({
  iconUrl: "https://i.ibb.co/fp1NLC0/Group-4.png", // Replace with your custom icon URL
  iconSize: [50, 50], // Adjust size as needed
  iconAnchor: [25, 50], // Adjust so it points correctly
});

const PropertiesMap = ({ listings }) => {
  const defaultPosition = [25.079539387772083, 55.12148983415934]; // Default position in Dubai

  return (
    <MapContainer
      center={defaultPosition}
      zoom={15}
      //   style={{ height: "800px", width: "100%", fontSize: "16px" }}
    >
      <TileLayer url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic2hhbWltczM5OSIsImEiOiJjbTJkMTZldWkwY3ZmMnJzYjY3ajB2aTJmIn0.bouAZxiGv_HbAPtsH0nm8A" />
      {listings.map((listing, index) => (
        <Marker
          position={[listing?.lat, listing?.lng]}
          icon={customIcon}
          key={listing?.id}
        >
          <Popup>
            <Link
              className="text-[#aca9a5] hover:no-underline focus:no-underline focus:text-[#aca9a5] active:text-[#aca9a5]"
              to={`/listing/${listing.id}`}
              style={{ textAlign: "center" }}
            >
              <Swiper
                navigation={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[Navigation, Autoplay]}
                className="mySwiper w-full h-36 rounded-md overflow-hidden"
              >
                {listing?.listingImages?.map((image) => (
                  <SwiperSlide>
                    <img src={image?.url} alt="" />
                  </SwiperSlide>
                ))}
              </Swiper>
              <h3 className="text-[18px] leading-[24px] mt-2 font-medium text-[#aca9a5]">
                {listing?.name}
              </h3>
              <p className="text-[#aca9a5]">
                {listing?.personCapacity} guests · {listing?.bedroomsNumber}{" "}
                bedrooms · {listing?.bathroomsNumber} baths
              </p>
            </Link>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default PropertiesMap;
