import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./style.css";
import { DataContext } from "../../Context/Context";
import axios from "axios";

const BlogDetails = () => {
  const [blog, setBlog] = useState({});
  const [loading, setLoading] = useState(false);
  const { blogs } = useContext(DataContext);
  const { slug } = useParams();
  const recommendedBlogs = blogs?.filter((item) => item?.slug !== blog?.slug);

  const fetchBlog = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://365luxuryhomes.com/api/blogs/${slug}`
      );

      // Ensure the response has data
      if (response.data) {
        setBlog(response.data); // Assuming `setSections` is the correct state setter
        setLoading(false);
      } else {
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlog();
  }, [slug]);

  if (!blog) {
    return <p>Blog not found.</p>;
  }

  return (
    <section className="pt-24 pb-10">
      <div className="container flex lg:flex-row md:flex-row flex-col items-start gap-5">
        <div className="lg:w-[70%] md:w-[60%] w-full">
          {loading ? (
            <div className="flex w-full flex-col gap-4">
              <div className="skeleton h-56 w-full"></div>
              <div className="skeleton h-4 w-28"></div>
              <div className="skeleton h-4 w-full"></div>
              <div className="skeleton h-4 w-full"></div>
            </div>
          ) : (
            <>
              {/* Blog Title */}
              <h1 className="text-2xl font-medium text-[#aca9a5] mb-4">
                {blog.title}
              </h1>

              {/* Blog Date */}
              <p className="text-sm text-[#aca9a5] mb-6">
                {new Date(blog.date).toDateString()}
              </p>

              {/* Main Image */}
              <img
                src={blog.mainImage}
                alt={blog.title}
                loading="lazy"
                className="w-full rounded-lg mb-6"
              />

              {/* Blog Content */}
              <div
                className="text-sm text-[#aca9a5] leading-relaxed"
                id="blog_description"
                dangerouslySetInnerHTML={{ __html: blog.content }}
              ></div>

              {/* Additional Images */}
              {blog.additionalImages?.length > 0 && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
                  {blog.additionalImages.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      loading="lazy"
                      alt={`Additional Image ${index + 1}`}
                      className="w-full rounded-lg"
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </div>

        {/* Recommended Blogs */}
        <aside className=" lg:w-[30%] md:w-[40%] w-full">
          <h2 className="text-xl font-semibold text-[#aca9a5] mb-4">
            Recommended Blogs
          </h2>
          <div className="space-y-4">
            {recommendedBlogs?.map((recommendedBlog, index) => (
              <div key={index} className="flex space-x-4">
                <img
                  src={recommendedBlog?.mainImage}
                  alt={recommendedBlog?.title}
                  loading="lazy"
                  className="w-16 h-16 object-cover rounded-lg"
                />
                <div>
                  <h3 className="text-[15px] font-medium leading-[20px] text-[#aca9a5]">
                    {recommendedBlog?.title}
                  </h3>
                  <a
                    href={`/blogs/${recommendedBlog.slug}`}
                    className="text-[#aca9a5] text-[14px] hover:text-[#aca9a5] hover:underline"
                  >
                    Read Article
                  </a>
                </div>
              </div>
            ))}
          </div>
        </aside>
      </div>
    </section>
  );
};

export default BlogDetails;
