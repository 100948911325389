import React from "react";
import { Link } from "react-router-dom";

const TermsAndCondition = () => {
  return (
    <>
      <section className="w-full h-[40vh] bg-[#aca9a5] pt-10 pb-4">
        <div className="container flex items-end justify-start h-full">
          <h1 className="text-white uppercase">Terms and Conditions</h1>
        </div>
      </section>
      <section className="lg:py-20 md:py-14 py-10">
        <div className="container">
          <h3 className="text-[#aca9a5] text-[20px] leading-none">
            Terms and Conditions
          </h3>
          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            Welcome to 365 Luxury Homes! We strive to provide you with an
            exceptional experience during your stay at one of our luxurious
            properties. Please read the following terms and conditions carefully
            before making a booking. By accessing our website and making a
            reservation, you agree to comply with and be bound by the following
            terms and conditions:
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>1. Reservation and Payment:</b>
            <br />
            1.1. Booking Confirmation: Your reservation is confirmed once you
            receive a booking confirmation email from us. Please review the
            details of your reservation and notify us immediately of any
            discrepancies.
          </p>
          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            1.2. Payment: Payment for your stay is required in full at the time
            of booking. We accept major credit cards and online payment methods.
            Your payment details will be securely processed, and your card will
            be charged accordingly.
          </p>
          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            1.2. Payment: Payment for your stay is required in full at the time
            of booking. We accept major credit cards and online payment methods.
            Your payment details will be securely processed, and your card will
            be charged accordingly.
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>2. Cancellation and Refunds:</b>
            <br />
            2.1. Cancellation Policy: We understand that plans may change, and
            you may need to cancel your reservation. Our cancellation policy is
            as follows: <br /> - Cancellation more than 30 days prior to the
            scheduled check-in date: 100% refund, minus any fees occurred to
            receive the payment.
            <br /> - Cancellation between 30 and 15 days prior to the scheduled
            check-in date: 50% refund, minus any fees occurred to receive the
            payment.
            <br /> - No refund for cancellations within 15 days of the scheduled
            check-in date. <br />
          </p>
          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            2.2. Refunds: Refunds, if applicable, will be processed in
            accordance with the cancellation policy. Please review the terms
            carefully to understand the refund eligibility and any associated
            fees. Any fees occurred to receive the payment (e.g., transaction
            fees) will be deducted from the refund amount.
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>3. Property Usage and Responsibilities:</b> <br />
            3.1. Property Use: The reserved property is for personal
            accommodation use only. It is strictly prohibited to use the
            property for any commercial, illegal, or disruptive purposes.
          </p>
          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            3.2. Care and Maintenance: We kindly request that you treat the
            property and its amenities with care. Any damages or losses incurred
            during your stay will be your responsibility and may result in
            additional charges.
          </p>
          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            3.3. Compliance with Laws and Regulations: Guests are expected to
            comply with all local laws and regulations during their stay. Any
            illegal activities or actions that disturb the peace and privacy of
            other residents may result in immediate eviction without refund.
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>4. Check-in, Online Check-In, and House Rules Agreement:</b>{" "}
            <br />
            4.1. Check-in: Check-in time is typically from 4:00 PM onwards,
            unless otherwise specified. You will receive check-in instructions
            prior to your arrival. Please inform us in advance if you anticipate
            a late arrival.
          </p>
          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            4.2. House Rules Agreement and Guest Information:
          </p>
          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            4.2. House Rules Agreement and Guest Information:
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            Upon completion of your booking, the primary guest who made the
            reservation will receive a link to sign the house rules agreement
            and upload government-issued identification documents for all guests
            who will be staying at the property. It is mandatory for the primary
            guest to sign the house rules agreement and provide the necessary
            guest information. This ensures compliance with Dubai Tourism and
            Commerce Marketing (DTCM) regulations. Failure to complete these
            requirements within 48 hours prior to check-in may result in the
            cancellation of your reservation without refund.
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>5. Check-out:</b>
            <br />
            5.1. Check-out: Check-out time is usually before 12:00 PM (noon),
            unless otherwise specified. Late check-out requests are subject to
            availability and may incur additional charges.
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>6. Guest Responsibilities:</b> <br />
            6.1. Conduct: We expect guests to behave in a respectful and
            considerate manner towards the property, its staff, and other
            guests. Any disruptive, illegal, or disrespectful behavior may
            result in immediate eviction without refund.
          </p>
          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            6.2. Property Security: Guests are responsible for ensuring the
            security of the property during their stay. Please ensure all doors,
            windows, and access points are securely locked when leaving the
            property.
          </p>
          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            6.3. Personal Belongings: We are not responsible for any loss,
            theft, or damage to your personal belongings during your stay.
            Please take necessary precautions and use the provided safety
            deposit boxes, where available.
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>7. Limitation of Liability:</b>
            <br />
            7.1. Indemnification: You agree to indemnify and hold 365 Luxury
            Homes, its owners, and employees harmless from any claims, losses,
            damages, liabilities, or expenses arising out of your stay or any
            violations of these terms and conditions.
          </p>
          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            7.2. Property Representations: While we strive to provide accurate
            and up-to-date information about our properties, we cannot guarantee
            the absence of any errors, inaccuracies, or omissions. Property
            descriptions, amenities, and photographs are provided for
            informational purposes and may be subject to change.
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>8. Privacy Policy:</b>
            <br />
            8.1. Personal Information: We collect and process your personal
            information in accordance with our Privacy Policy. By making a
            reservation, you consent to the collection, use, and disclosure of
            your personal information as described in our Privacy Policy.
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            <b>9. Governing Law and Jurisdiction:</b>
            <br />
            9.1. These terms and conditions shall be governed by and construed
            in accordance with the laws of Dubai, United Arab Emirates. Any
            disputes arising out of or in connection with these terms and
            conditions shall be subject to the exclusive jurisdiction of the
            courts of Dubai.
          </p>
          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            By making a reservation and staying at one of our properties, you
            acknowledge and agree to abide by these terms and conditions. If you
            have any questions or concerns, please contact our customer support
            team for assistance. Thank you for choosing 365 Luxury Homes, and we
            wish you a memorable and enjoyable stay.
          </p>

          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-6">
            Email:{" "}
            <Link className="text-[16px]" to={"mailto:info@365luxuryhomes.com"}>
              info@365luxuryhomes.com
            </Link>
          </p>
          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-1">
            Phone:{" "}
            <Link className="text-[16px]" to={"tel:+971 52 143 4186"}>
              +971 52 143 4186
            </Link>
          </p>
          <p className="text-[#aca9a5] font-thin text-[16px] leading-[24px] mt-1">
            Address: 4th Street, Al Quoz 3, Next to Gold & Diamond Park, Dubai,
            UAE
          </p>

          <p className="text-[#aca9a5] font-medium text-[18px] leading-[24px] mt-8">
            {" "}
            Thank you for trusting 365 Luxury Homes.
          </p>
        </div>
      </section>
    </>
  );
};

export default TermsAndCondition;
