import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import "../Styles/CustomCalendar.css";
import { DataContext } from "../Context/Context";
import { eachDayOfInterval, subDays } from "date-fns";

const ListingCalendar = ({ listingId }) => {
  const { setCalender, setCurrentDatePrice, startDate, endDate } =
    useContext(DataContext);
  const [calendarData, setCalendarData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [nextMonth, setNextMonth] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)
  );

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];

  useEffect(() => {
    const fetchCalendar = async () => {
      try {
        const tokenResponse = await axios.post(
          "https://365luxuryhomes.com/api/accessToken"
        ); // Replace with your backend
        const accessToken = tokenResponse.data.access_token;

        const startDate = `${currentMonth.getFullYear()}-${String(
          currentMonth.getMonth() + 1
        ).padStart(2, "0")}-01`;
        const endDate = `${nextMonth.getFullYear()}-${String(
          nextMonth.getMonth() + 2
        ).padStart(2, "0")}-01`;

        const response = await axios.get(
          `https://api.hostaway.com/v1/listings/${listingId}/calendar`,
          {
            params: { startDate, endDate, includeResources: 1 },
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Cache-Control": "no-cache",
            },
          }
        );

        setCalendarData(response.data || []);
        setCalender(response.data || []);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchCalendar();
  }, [currentMonth, nextMonth]);

  const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getCalendarMatrix = (date) => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const daysInMonth = lastDayOfMonth.getDate();
    const firstWeekday = firstDayOfMonth.getDay();

    const calendarMatrix = [];
    let week = Array(firstWeekday === 0 ? 6 : firstWeekday - 1).fill(null);

    for (let day = 1; day <= daysInMonth; day++) {
      week.push(day);

      if (week.length === 7 || day === daysInMonth) {
        calendarMatrix.push(week);
        week = [];
      }
    }

    return calendarMatrix;
  };

  const getDayStatus = (formattedDate) => {
    const dayData = calendarData?.result?.find((d) => d.date === formattedDate);

    if (!dayData) return "available";
    if (dayData.status === "reserved") return "reserved";
    if (!dayData.isAvailable) return "booked";
    if (dayData.isPast) return "past";
    return "available";
  };

  const getMatchingItems = (calendarData, startDate, endDate) => {
    if (!startDate || !endDate) return [];

    const dateRange = eachDayOfInterval({
      start: new Date(startDate),
      end: subDays(new Date(endDate), 1),
    }).map((date) => formatDate(date));

    const matchingItems = calendarData?.result?.filter((item) =>
      dateRange.includes(item.date)
    );

    return matchingItems;
  };

  useEffect(() => {
    if (startDate && endDate && calendarData) {
      const matchingItems = getMatchingItems(calendarData, startDate, endDate);
      setCurrentDatePrice(matchingItems);
    }
  }, [startDate, endDate, calendarData]);

  const renderCalendar = (date) => {
    const matrix = getCalendarMatrix(date);

    function generateRandomHashKey() {
      // Generate a random number between 1000 and 9999
      const randomKey = Math.floor(1000 + Math.random() * 9000);
      return randomKey.toString();
    }

    return (
      <table className="calendar-table">
        <thead>
          <tr>
            {["M", "T", "W", "T", "F", "S", "S"].map((day) => (
              <th key={day + generateRandomHashKey()}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {matrix.map((week, i) => (
            <tr key={i}>
              {week.map((day, idx) => {
                const formattedDate = day
                  ? `${date.getFullYear()}-${String(
                      date.getMonth() + 1
                    ).padStart(2, "0")}-${String(day).padStart(2, "0")}`
                  : null;

                const dayStatus = day ? getDayStatus(formattedDate) : "";

                return (
                  <td key={idx} className={`calendar-cell ${dayStatus}`}>
                    {dayStatus === "reserved" ? (
                      <del className={``}>{day}</del>
                    ) : (
                      day || ""
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const goToPreviousMonth = () => {
    setCurrentMonth(
      (prev) => new Date(prev.getFullYear(), prev.getMonth() - 1, 1)
    );
    setNextMonth(
      (prev) => new Date(prev.getFullYear(), prev.getMonth() - 1, 1)
    );
  };

  const goToNextMonth = () => {
    setCurrentMonth(
      (prev) => new Date(prev.getFullYear(), prev.getMonth() + 1, 1)
    );
    setNextMonth(
      (prev) => new Date(prev.getFullYear(), prev.getMonth() + 1, 1)
    );
  };

  if (loading) return <p>Loading calendar...</p>;

  return (
    <div className="">
      <div className="calendar-header">
        <span className="cursor-pointer" onClick={goToPreviousMonth}>
          &laquo;
        </span>
        <span className="text-[#aca9a5]">
          {currentMonth.toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
        </span>
        <span className="text-[#aca9a5]">
          {nextMonth.toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
        </span>
        <span className="cursor-pointer" onClick={goToNextMonth}>
          &raquo;
        </span>
      </div>
      <div className="calendar ">
        {renderCalendar(currentMonth)}
        {renderCalendar(nextMonth)}
      </div>
    </div>
  );
};

export default ListingCalendar;
