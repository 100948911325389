import React, { useContext, useEffect, useState } from "react";
import "../Styles/pricingCardStyle.css";
import axios from "axios";
import { DataContext } from "../Context/Context";
import { eachDayOfInterval, subDays } from "date-fns";

const PricingTable = ({
  price,
  cleaningFee,
  nights,
  additional,
  bedLineFee,
  cityTourismTax,
  occupancyTax,
  vatGstTax,
  weeklyDiscount,
}) => {
  const { setDiscountValue } = useContext(DataContext);
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [couponError, setCouponError] = useState("");
  const [couponSuccess, setCouponSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const totalAfterDiscount = price - discount;

  const adsOnServices = additional;

  const total =
    (cleaningFee ?? 0) +
    (adsOnServices ?? 0) +
    (bedLineFee ?? 0) +
    (cityTourismTax ?? 0) +
    (occupancyTax ?? 0) +
    (vatGstTax ?? 0) +
    (totalAfterDiscount ?? 0);

  // Apply Coupon Function
  const applyCoupon = async () => {
    setLoading(true); // Start loading
    try {
      // Fetch access token (replace with your endpoint)
      const tokenResponse = await axios.post(
        "https://365luxuryhomes.com/api/accessToken"
      );
      const accessToken = tokenResponse.data.access_token;

      // Fetch coupons
      const response = await axios.get("https://api.hostaway.com/v1/coupons", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Cache-Control": "no-cache",
        },
      });

      const coupons = response.data.result; // Access the result array

      // Validate the coupon
      const matchedCoupon = coupons.find(
        (c) => c.name.toLowerCase() === couponCode.toLowerCase()
      );

      if (!matchedCoupon) {
        setCouponError("Invalid coupon code");
        setCouponSuccess("");
        setDiscount(0);
        return;
      }

      if (!matchedCoupon.isActive || matchedCoupon.isExpired) {
        setCouponError("Coupon is inactive or expired");
        setCouponSuccess("");
        setDiscount(0);
        return;
      }

      // Check minimum nights
      if (nights < matchedCoupon.minimumNights) {
        setCouponError(
          `This coupon requires a minimum stay of ${matchedCoupon.minimumNights} nights.`
        );
        setCouponSuccess("");
        setDiscount(0);
        return;
      }

      // Calculate the discount
      let calculatedDiscount = 0;
      if (matchedCoupon.type === "percentage") {
        calculatedDiscount = (price * matchedCoupon.amount) / 100;
      } else if (matchedCoupon.type === "flatFee") {
        calculatedDiscount = matchedCoupon.amount;
      }

      setDiscountValue(calculatedDiscount);
      // console.log(calculatedDiscount);

      setDiscount(calculatedDiscount);
      setCouponError("");
      setCouponSuccess(`Coupon applied successfully!`);
    } catch (error) {
      console.error("Error applying coupon:", error.message);
      setCouponError("Failed to apply coupon. Please try again.");
      setCouponSuccess("");
      setDiscount(0);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const generateTitle = () => {
    if (nights >= 7 && nights < 30) {
      return "Weekly Discount";
    } else if (nights >= 30) {
      return "Monthly Discount";
    }
  };

  return (
    <div className="pricing-table-container">
      <table className="pricing-table w-full">
        <tbody>
          <tr className="text-[#aca9a5]">
            <td className="text-[14px] font-thin">Base Price</td>
            <td className="text-[14px] font-thin">
              AED {price ? price.toFixed(2) : "0.00"}
            </td>
          </tr>
          {weeklyDiscount > 0 && (
            <tr className="text-red-500">
              <td className="text-[14px] font-thin">{generateTitle()}</td>
              <td className="text-[14px] font-thin">
                - AED {weeklyDiscount.toFixed(2)}
              </td>
            </tr>
          )}
          <tr className="text-[#aca9a5]">
            <td className="text-[14px] font-thin">Cleaning Fee</td>
            <td className="text-[14px] font-thin">AED {cleaningFee}</td>
          </tr>
          {bedLineFee > 0 && bedLineFee && (
            <tr className="text-[#aca9a5]">
              <td className="text-[14px] font-thin">Bed Line Fee</td>
              <td className="text-[14px] font-thin">AED {bedLineFee}</td>
            </tr>
          )}
          <tr className="text-[#aca9a5]">
            <td className="text-[14px] font-thin">Total Nights</td>
            <td className="text-[14px] font-thin">{nights}</td>
          </tr>
          <tr className="text-[#aca9a5]">
            <td className="text-[14px] font-thin">City/Tourism Tax</td>
            <td className="text-[14px] font-thin">AED {cityTourismTax}</td>
          </tr>
          <tr className="text-[#aca9a5]">
            <td className="text-[14px] font-thin">Occupancy Tax</td>
            <td className="text-[14px] font-thin">
              AED {occupancyTax ? occupancyTax.toFixed(2) : "0.00"}
            </td>
          </tr>
          <tr className="text-[#aca9a5]">
            <td className="text-[14px] font-thin">VAT/GST</td>
            <td className="text-[14px] font-thin">
              AED {vatGstTax ? vatGstTax.toFixed(2) : "0.00"}
            </td>
          </tr>
          {adsOnServices > 0 && adsOnServices && (
            <tr className="text-[#aca9a5]">
              <td className="text-[14px] font-thin">Adds-on Services</td>
              <td className="text-[14px] font-thin">AED {adsOnServices}</td>
            </tr>
          )}
          {discount > 0 && (
            <tr className="text-[#aca9a5]">
              <td className="text-[14px] font-thin text-[#aca9a5]">
                Coupon Applied
              </td>
              <td className="text-[14px] font-thin text-[#aca9a5]">
                - AED {discount.toFixed(2)}
              </td>
            </tr>
          )}
          <tr className="text-[#aca9a5]">
            <td className="text-[14px] font-bold">Total</td>
            <td className="text-[14px] font-bold">AED {total.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>

      {/* Coupon Input Section */}
      <div className="coupon-container mt-4">
        <div className="flex items-center">
          <input
            type="text"
            placeholder="Enter Coupon Code"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            className="input input-sm w-full input-bordered focus:outline-none text-[15px] text-[#aca9a5] rounded-sm"
          />
          <button
            onClick={applyCoupon}
            className="btn btn-sm rounded-sm bg-[#aca9a5] text-white"
          >
            {loading ? (
              <span className="loading loading-dots loading-md"></span>
            ) : (
              "Apply"
            )}
          </button>
        </div>
        {couponError && (
          <p className="error-message text-[#aca9a5] text-[14px] font-thin mt-2">
            {couponError}
          </p>
        )}
        {couponSuccess && (
          <p className="success-message text-[#aca9a5] text-[14px] font-thin mt-2">
            {couponSuccess}
          </p>
        )}
      </div>
    </div>
  );
};

export default PricingTable;
