import React, { useEffect, useRef, useState } from "react";
import image from "../../Assets/image8.jpg";

const Counter = ({ end, duration, suffix = "" }) => {
  const [count, setCount] = useState(0);
  const [hasStarted, setHasStarted] = useState(false); // To track animation start
  const ref = useRef(null); // To reference the section

  useEffect(() => {
    // Intersection Observer to trigger animation when section is visible
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setHasStarted(true); // Start the animation
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the section is visible
    );

    if (ref.current) observer.observe(ref.current);

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, []);

  useEffect(() => {
    if (!hasStarted) return;

    let start = 0;
    const increment = end / (duration / 10);

    const timer = setInterval(() => {
      start += increment;
      if (start >= end) {
        clearInterval(timer);
        setCount(end);
      } else {
        setCount(Math.ceil(start));
      }
    }, 10);

    return () => clearInterval(timer);
  }, [hasStarted, end, duration]);

  return (
    <span
      ref={ref}
      className="lg:text-4xl md:text-3xl text-xl font-bold leading-none"
    >
      {count}
      {suffix}
    </span>
  );
};

const About = () => {
  return (
    <section className="lg:py-20 md:py-10 py-10 bg-[#fdfcf5]">
      <div className="container flex lg:flex-row md:flex-row flex-col gap-12 items-center">
        <div className="lg:w-[60%] md:w-[60%] w-full">
          <div className="flex items-end lg:gap-8 md:gap-8 gap-2 mb-10">
            <h1 className="font-normal leading-none text-2xl uppercase text-[#aca9a5]">
              About Us
            </h1>
            <div className="lg:w-28 md:w-24 w-20 h-[2px] bg-[#aca9a5]"></div>
          </div>
          <p className="mt-8 leading-6 font-thin text-[14px] text-[#aca9a5]">
            365 Luxury Homes offers exceptional rental properties for vacations
            and extended stays in Dubai's premier locations, including JBR, Palm
            Jumeirah, Bluewaters Island and many more. Each residence is
            meticulously curated and includes premium amenities, bespoke
            services, and an ambiance of prestige and exclusivity. Designed for
            discerning guests, our most emblematic luxury homes guarantee a
            comfortable stay, breathtaking views, and personalised attention,
            providing you with the highest level of tailor-made experiences.
            With a commitment to offering the best service, our team is on a
            quest to redefine luxury stays in Dubai’s most sought-after
            destinations.
          </p>
          <div className="flex lg:flex-row md:flex-row flex-row lg:items-center md:items-center items-start lg:gap-8 md:gap-6 gap-4 mt-8">
            <div className="border-l-[3px] pl-3 border-[#aca9a5]">
              <span className="font-bold lg:text-4xl md:text-3xl text-xl block text-[#aca9a5] leading-none">
                <Counter end={30} duration={2000} suffix="+" />
              </span>
              <span className="lg:text-lg md:text-lg text-[14px] leading-none text-[#aca9a5]">
                Active Listings
              </span>
            </div>
            <div className="border-l-[3px] pl-3 border-[#aca9a5]">
              <span className="font-bold lg:text-4xl md:text-3xl text-xl block text-[#aca9a5] leading-none ">
                <Counter end={100} duration={2000} suffix="+" />
              </span>
              <span className="lg:text-lg md:text-lg text-[14px] leading-none text-[#aca9a5]">
                Reviews
              </span>
            </div>
            <div className="border-l-[3px] pl-3 border-[#aca9a5]">
              <span className="font-bold lg:text-4xl md:text-3xl text-xl block text-[#aca9a5] leading-none ">
                <Counter end={2019} duration={2000} suffix="" />
              </span>
              <span className="lg:text-lg md:text-lg text-[14px] leading-none text-[#aca9a5]">
                Established In
              </span>
            </div>
          </div>
        </div>
        <div className="lg:w-[35%] md:w-[35%] w-full">
          <img src={image} alt="" loading="lazy" className="w-full" />
        </div>
      </div>
    </section>
  );
};

export default About;
