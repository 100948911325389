import React, { useContext, useState } from "react";
import { DataContext } from "../Context/Context";

const Register = () => {
  const { register } = useContext(DataContext);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRegister = (e) => {
    e.preventDefault();

    const readyData = {
      name: formData.firstName + " " + formData.lastName,
      email: formData.email,
      password: formData.password,
    };
    register(readyData);
  };

  return (
    <form onSubmit={handleRegister} className="bg-white p-6 rounded w-full">
      <h2 className="text-lg font-bold mb-4 text-[#aca9a5]">Register</h2>
      <div className="mb-4">
        <input
          type="text"
          name="firstName"
          placeholder="First Name"
          value={formData.firstName}
          onChange={handleChange}
          required
          className="border p-2 rounded w-full text-[14px] text-[#aca9a5]"
        />
      </div>
      <div className="mb-4">
        <input
          type="text"
          name="lastName"
          placeholder="Last Name"
          value={formData.lastName}
          onChange={handleChange}
          required
          className="border p-2 rounded w-full text-[14px] text-[#aca9a5]"
        />
      </div>
      <div className="mb-4">
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
          className="border p-2 rounded w-full text-[14px] text-[#aca9a5]"
        />
      </div>
      <div className="mb-4">
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleChange}
          required
          className="border p-2 rounded w-full text-[14px] text-[#aca9a5]"
        />
      </div>
      <button
        type="submit"
        className="bg-[#aca9a5] text-white px-4 py-2 rounded"
      >
        Register
      </button>
    </form>
  );
};

export default Register;
