import React, { useEffect, useState } from "react";
import "./style.css";
import img1 from "../../Assets/Services/new/Airport.jpg";
import img2 from "../../Assets/Services/new/At-the-top.jpg";
import img3 from "../../Assets/Services/new/Babysitting.jpg";
import img4 from "../../Assets/Services/new/Balloon-Adventures-Dubai---Signature-Experience---11-Flying.jpg";
import img5 from "../../Assets/Services/new/Big-Bus.jpg";
import img6 from "../../Assets/Services/new/Buggy.jpg";
import img7 from "../../Assets/Services/new/Deep-Dive.jpg";
import img8 from "../../Assets/Services/new/Dubai-Stay.jpg";
import img9 from "../../Assets/Services/fashion show.jpg";
import img10 from "../../Assets/Services/new/F1.jpg";
import img11 from "../../Assets/Services/new/Golf.jpg";
import img12 from "../../Assets/Services/new/Helicopter.jpg";
import img13 from "../../Assets/Services/new/Jet-Ski.jpg";
import img14 from "../../Assets/Services/new/Luxury-Cars.jpg";
import img15 from "../../Assets/Services/new/Private-Chef.jpg";
import img16 from "../../Assets/Services/new/Private-jet.jpg";
import img17 from "../../Assets/Services/new/SPA.jpg";
import img18 from "../../Assets/Services/new/Safari.jpg";
import img19 from "../../Assets/Services/new/Skydive.jpg";
import img20 from "../../Assets/Services/new/Top-Attractions.jpg";
import img21 from "../../Assets/Services/new/Water-Park.jpg";
import img22 from "../../Assets/Services/new/Yacht.jpg";
import img23 from "../../Assets/Services/new/Yellow-Boat.jpg";
import img24 from "../../Assets/Services/new/aquarium-access.jpg";

import icon1 from "../../Assets/Services/Icons/Airport.svg";
import icon2 from "../../Assets/Services/Icons/At the top.svg";
import icon3 from "../../Assets/Services/Icons/Babysitting.svg";
import icon4 from "../../Assets/Services/Icons/Balloon.svg";
import icon5 from "../../Assets/Services/Icons/Big Bus.svg";
import icon6 from "../../Assets/Services/Icons/Buggy Adventure.svg";
import icon7 from "../../Assets/Services/Icons/Deep dive.svg";
import icon8 from "../../Assets/Services/Icons/Dubai Stay.svg";
import icon9 from "../../Assets/Services/Icons/Special Events.svg";
import icon10 from "../../Assets/Services/Icons/F1.svg";
import icon11 from "../../Assets/Services/Icons/Golf.svg";
import icon12 from "../../Assets/Services/Icons/Helicopter.svg";
import icon13 from "../../Assets/Services/Icons/Jetski.svg";
import icon14 from "../../Assets/Services/Icons/Luxury Cars.svg";
import icon15 from "../../Assets/Services/Icons/Private chef.svg";
import icon16 from "../../Assets/Services/Icons/Private cars.svg";
import icon17 from "../../Assets/Services/Icons/SPA.svg";
import icon18 from "../../Assets/Services/Icons/Safari.svg";
import icon19 from "../../Assets/Services/Icons/Sky Dive.svg";
import icon20 from "../../Assets/Services/Icons/Dubai Top Attractions.svg";
import icon21 from "../../Assets/Services/Icons/Waterpark.svg";
import icon22 from "../../Assets/Services/Icons/Yacht.svg";
import icon23 from "../../Assets/Services/Icons/Yellow Boat.svg";
import icon24 from "../../Assets/Services/Icons/Aquarium Access.svg";
import axios from "axios";
import { Link } from "react-router-dom";
import DynamicSeoPage from "../DynamicSeoPage";

const services = [
  {
    id: 1,
    title: "Airport Transfer",
    img: img1,
    icon: icon1,
  },
  {
    id: 2,
    title: "At The Top",
    img: img2,
    icon: icon2,
  },
  {
    id: 3,
    title: "Childcare",
    img: img3,
    icon: icon3,
  },
  {
    id: 4,
    title: "Balloon Adventure",
    img: img4,
    icon: icon4,
  },
  {
    id: 5,
    title: "Big Bus",
    img: img5,
    icon: icon5,
  },
  {
    id: 6,
    title: "Buggy Adventure",
    img: img6,
    icon: icon6,
  },
  {
    id: 7,
    title: "Deep Dive Dubai",
    img: img7,
    icon: icon7,
  },
  {
    id: 8,
    title: "Dubai Stay",
    img: img8,
    icon: icon8,
  },
  {
    id: 9,
    title: "Special Event access",
    img: img9,
    icon: icon9,
  },
  {
    id: 10,
    title: "Formula 1",
    img: img10,
    icon: icon10,
  },
  {
    id: 11,
    title: "Golfing",
    img: img11,
    icon: icon11,
  },
  {
    id: 12,
    title: "Helicopter Ride",
    img: img12,
    icon: icon12,
  },
  {
    id: 13,
    title: "Jet Ski",
    img: img13,
    icon: icon13,
  },
  {
    id: 14,
    title: "Luxury Car",
    img: img14,
    icon: icon14,
  },
  {
    id: 15,
    title: "Private Chef",
    img: img15,
    icon: icon15,
  },
  {
    id: 16,
    title: "Private Car",
    img: img16,
    icon: icon16,
  },
  {
    id: 17,
    title: "Spa",
    img: img17,
    icon: icon17,
  },
  {
    id: 18,
    title: "Safari",
    img: img18,
    icon: icon18,
  },
  {
    id: 19,
    title: "Sky Dive",
    img: img19,
    icon: icon19,
  },
  {
    id: 20,
    title: "Dubai top Attractions",
    img: img20,
    icon: icon20,
  },
  {
    id: 21,
    title: "Waterparks",
    img: img21,
    icon: icon21,
  },
  {
    id: 22,
    title: "Yacht",
    img: img22,
    icon: icon22,
  },
  {
    id: 23,
    title: "Yellow Boat",
    img: img23,
    icon: icon23,
  },
  {
    id: 24,
    title: "Aquarium Access",
    img: img24,
    icon: icon24,
  },
];

const Services = () => {
  const [section, setSection] = useState({});

  const fetchSection = async () => {
    try {
      const response = await axios.get(
        `https://365luxuryhomes.com/api/sections/services`
      );
      // Ensure the response has data
      if (response.data) {
        setSection(response.data);
      } else {
        // console.warn("No section data received");
      }
    } catch (error) {
      // console.error("Error fetching sections:", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchSection();
  }, []);
  return (
    <div>
      <DynamicSeoPage page="services" />
      <section
        className="services_banner pt-20 pb-10"
        id="banner"
        style={{
          backgroundImage: `url(${section?.imageUrl || ""})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container h-full flex items-end">
          <h1 className="font-bold text-3xl text-white uppercase">
            Our Services
          </h1>
        </div>
      </section>
      <section className="py-20">
        <div className="container">
          <div className="flex items-end gap-2 mb-4">
            <div className="w-20 h-[2px] bg-[#aca9a5]"></div>
            <h1 className="lg:text-2xl md:text-2xl text-xl font-thin text-[#aca9a5] uppercase leading-none -mt-1">
              ADDITIONAL SERVICES
            </h1>
          </div>
          <p className="font-thin text-[#aca9a5] text-[14px] leading-[18px]">
            Our premium services reflect our commitment to curating singular
            experiences that resonate with sophistication, comfort, and
            unforgettable memories. From arranging exclusive access to crafting
            individualised itineraries, our team strives to turn aspirational
            desires into reality, one event at a time.
          </p>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-8">
            {services.map((service, i) => (
              <div className="relative service_card w-full" key={i}>
                <img
                  src={service.img}
                  loading="lazy"
                  alt=""
                  className="w-full"
                />
                <div
                  className={`service_card_details absolute top-0 left-0 w-full h-full p-4 flex flex-col items-center justify-center transition-opacity text-white gap-3 bg-[#446e9f] bg-opacity-75`}
                >
                  <img
                    src={service.icon}
                    alt=""
                    loading="lazy"
                    className="w-20 brightness-200"
                  />
                  <h2 className="text-center font-normal text-[24px] uppercase">
                    {service.title}
                  </h2>
                  <Link
                    // 971585173365
                    to={`https://wa.me/971525665365?text=Dear%20Concierge%20Team%2C%0A%0AI%20hope%20this%20message%20finds%20you%20well.%20I%20am%20interested%20in%20learning%20more%20about%20the%20${encodeURIComponent(
                      service.title
                    )}%20services%20offered%20by%20your%20platform.%20Could%20you%20please%20provide%20me%20with%20more%20details%20on%20the%20services%20available%2C%20pricing%2C%20and%20how%20to%20proceed%20with%20a%20booking%3F%0A%0AThank%20you%20in%20advance%20for%20your%20assistance.%0A%0ABest%20regards%2C%0A`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" px-4 rounded-md text-[#fff] hover:text-[#fff] hover:no-underline bg-transparent border border-white py-2 text-thin uppercase text-[16px] active:text-white focus:text-white"
                  >
                    Know More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <div className="flex flex-col items-center justify-center lg:py-20 md:py-14 lg:mb-0 md:mb-0 mb-10">
        <a
          href="#banner"
          className="text-[#aca9a5] hover:no-underline hover:text-[#aca9a5]"
        >
          Go To Top
        </a>
        <div className="w-[2px] h-14 bg-[#aca9a5]"></div>
      </div>
    </div>
  );
};

export default Services;
