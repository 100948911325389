import React, { useEffect, useState } from "react";
import "./style.css";

import Group365 from "../../Assets/About/365 Group.png";
import luxuryLogo from "../../Assets/About/Luxury Homes Logo.png";
import Concierge from "../../Assets/About/Concierge.png";
import Laundry from "../../Assets/About/Laundry.png";
import CleanIt from "../../Assets/About/Clean It.png";
import maintenance from "../../Assets/About/maintenance.png";

import section1 from "../../Assets/About/luxury homes section img.png";
import section2 from "../../Assets/About/Concierge img.png";
import section3 from "../../Assets/About/Laundry img.png";
import section4 from "../../Assets/About/clean img.png";
import section5 from "../../Assets/About/maintenance img.png";

import { Link } from "react-router-dom";
import axios from "axios";
import DynamicSeoPage from "../DynamicSeoPage";

const About = () => {
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState({});

  const fetchSection = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://365luxuryhomes.com/api/sections/about_us`
      );

      // Ensure the response has data
      if (response.data) {
        setSection(response.data); // Assuming `setSections` is the correct state setter
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSection();
  }, []);
  return (
    <div>
      <DynamicSeoPage page="about-us" />
      <section
        className="about_banner pt-20 pb-10 relative"
        id="banner"
        style={{
          backgroundImage: `url(${section?.imageUrl || ""})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {loading && (
          <div className="skeleton h-full w-full absolute top-0 left-0 -z-0"></div>
        )}
        <div className="container h-full flex items-end lg:justify-normal md:justify-normal justify-center">
          <h1 className="font-bold text-3xl text-white uppercase lg:text-left md:text-left text-center">
            About Us
          </h1>
        </div>
      </section>
      <section className="py-10 pt-20">
        <div className="container flex lg:flex-row md:flex-row flex-col lg:items-start md:items-start items-center lg:gap-10 md:gap-6 gap-8">
          <div className="lg:w-[50%] md:w-[40%]">
            <div className="flex lg:items-start md:items-start items-center gap-2 lg:-ml-20 lg:mb-8 md:mb-6 mb-4">
              <div className="lg:w-20 md:w-14 w-14 h-[2px] bg-[#aca9a5]"></div>
              <h2 className="text-2xl font-semibold text-[#aca9a5] uppercase leading-none -mt-1">
                Welcome to
              </h2>
            </div>
            <img src={Group365} alt="" className="w-56" />
          </div>
          <div className="lg:w-[50%] md:w-[60%]">
            <p className="text-[#aca9a5] text-[16px] leading-[24px] font-thin lg:text-left md:text-left text-center">
              Born out of a commitment to elevating daily lifestyle, 365 Group's
              offerings span hospitality, personalised concierge services,
              dry-cleaning, maintenance, and more, as a testament to our
              relentless dedication to quality. We understand that luxury is not
              just about aesthetics but about the experience and emotions it
              evokes. Every property we curate and every service we provide is
              thoughtfully designed to make our clients and guests feel valued,
              understood, and well-served.
            </p>
          </div>
        </div>
      </section>
      <section className="luxury_homes mt-20 flex items-center">
        <div className="container flex lg:flex-row md:flex-col flex-col lg:items-start md:items-start items-center h-full lg:gap-0 md:gap-8 gap-6">
          <div className="lg:w-[50%] md:w-full w-full h-full lg:pr-20">
            <div className="flex lg:items-start md:items-start items-center lg:justify-normal md:justify-normal justify-center gap-2 lg:-ml-20 lg:mb-8 mb-6">
              <div className="w-20 h-[2px] bg-[#aca9a5]"></div>
              <h2 className="text-xl font-thin text-[#aca9a5] uppercase leading-none -mt-1">
                SUBSIDIARIES
              </h2>
            </div>
            <div className="h-full flex flex-col lg:items-start md:items-start items-center justify-center">
              <img src={luxuryLogo} alt="" className="w-56" />
              <p className="text-[#aca9a5] text-[16px] leading-[24px] font-thin lg:mt-8 md:mt-8 mt-8 lg:text-left md:text-left text-center">
                If you can imagine your perfect stay in Dubai, 365 Luxury Homes
                can make it happen. We are committed to finding spaces that suit
                your style and setting a new global quality mark for vacation
                homes. From exclusive, beachfront stays on Palm Jumeirah to
                short-term or long-term holiday home apartments in Downtown, 365
                Luxury Homes has all your requirements covered. Our concierge
                service can assist with special requests, and creating a custom
                holiday itinerary.
              </p>
              <Link
                to={"#"}
                target="_blank"
                className=" bg-[#aca9a5] uppercase rounded-sm text-white mt-8 font-thin text-[16px] px-4 py-2 hover:no-underline hover:text-white focus:text-white active:text-white"
              >
                Know More
              </Link>
            </div>
          </div>
          <div className="lg:w-[50%] md:w-full w-full h-full">
            <img src={section1} alt="" className="lg:hidden" />
          </div>
        </div>
      </section>
      <section className="luxury_concierge lg:pt-0 md:pt-16 pt-10 flex items-center">
        <div className="container flex lg:flex-row md:flex-col-reverse flex-col-reverse items-center h-full lg:gap-0 md:gap-10 gap-8">
          <div className="lg:w-[50%] md:w-full w-full h-full">
            <img src={section2} alt="" className="lg:hidden" />
          </div>
          <div className="lg:w-[50%] md:w-full w-full h-full lg:pl-20">
            <div className="h-full flex flex-col lg:items-start md:items-start items-center justify-center">
              <img src={Concierge} alt="" className="w-56" />
              <p className="text-[#aca9a5] text-[16px] leading-[24px] font-thin lg:mt-8 md:mt-8 mt-8 lg:text-left md:text-left text-center">
                Our luxury concierge means home-made hospitality, personalised
                for you, with insider tips from local experts. Our finest
                designer properties and hand-picked villas, provisioned by our
                team, ensure that every stay meets our quality standards. With
                the facilities and services of a five-star hotel but the space
                and privacy of a real home, our luxury concierge team is
                pioneering new standards of service. We assist with everything
                from transport and tours to shopping and in-house dining.
              </p>
              <button className=" bg-[#aca9a5] uppercase rounded-sm text-white mt-8 font-thin text-[16px] px-4 py-2">
                Know More
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="laundry_365 lg:pt-0 md:pt-16 pt-10 flex items-center">
        <div className="container flex lg:flex-row md:flex-col flex-col items-center h-full lg:gap-0 md:gap-10 gap-8">
          <div className="lg:w-[50%] md:w-full w-full h-full lg:pr-20">
            <div className="h-full flex flex-col lg:items-start md:items-start items-center justify-center">
              <img src={Laundry} alt="" className="w-56" />
              <p className="text-[#aca9a5] text-[16px] leading-[24px] font-thin lg:mt-8 md:mt-8 mt-8 lg:text-left md:text-left text-center">
                The markets we service include residential communities and
                short-term rental properties, with services including the supply
                and laundering of bed linen, towels, and table linen as well as
                the laundering and dry cleaning of clothing. We pride ourselves
                on using the latest innovative technology that gives us the
                capability and capacity to handle a wide range of laundry
                demands at a cost-effective and highly hygienic level. We offer
                complete solutions to match any service requirements.
              </p>
              <Link
                to={"https://365laundry.ae/"}
                target="_blank"
                className=" bg-[#aca9a5] uppercase rounded-sm text-white mt-8 font-thin text-[16px] px-4 py-2 hover:no-underline hover:text-white focus:text-white active:text-white"
              >
                Know More
              </Link>
            </div>
          </div>
          <div className="lg:w-[50%] md:w-full w-full h-full">
            <img src={section3} alt="" className="lg:hidden" />
          </div>
        </div>
      </section>
      <section className="cleanit_365 lg:pt-0 md:pt-16 pt-10 flex items-center">
        <div className="container flex lg:flex-row md:flex-col-reverse flex-col-reverse items-center h-full lg:gap-10 md:gap-8 gap-8">
          <div className="lg:w-[50%] md:w-full h-full">
            <img src={section4} alt="" className="lg:hidden" />
          </div>
          <div className="lg:w-[50%] md:w-full h-full lg:pl-20">
            <div className="h-full flex flex-col lg:items-start md:items-start items-center justify-center">
              <img src={CleanIt} alt="" className="w-56" />
              <p className="text-[#aca9a5] text-[16px] leading-[24px] font-thin lg:mt-8 md:mt-8 mt-8 lg:text-left md:text-left text-center">
                Our premier cleaning solutions are backed by industry experience
                which positioned us as a trusted name in the field of
                professional cleaning services. Our goal is to provide quality,
                proficiency, and affordability to deliver a spotless environment
                to enjoy and be productive in. Utilising the latest equipment
                and environmentally friendly cleaning products, we offer
                services spanning regular domestic cleaning, dusting, vacuuming,
                mopping, and bathroom and kitchen sanitisation. Superior
                protection means we prioritise safety and security with rigorous
                standards.
              </p>
              <button className=" bg-[#aca9a5] uppercase rounded-sm text-white mt-8 font-thin text-[16px] px-4 py-2">
                Know More
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="maintenance_365 lg:pt-0 md:pt-16 pt-10 flex items-center">
        <div className="container h-full flex lg:flex-row md:flex-col reverse flex-col items-center lg:gap-0 md:gap-10 gap-8">
          <div className="lg:w-[50%] md:w-full h-full lg:pr-20">
            <div className="h-full flex flex-col lg:items-start md:items-start items-center justify-center">
              <img src={maintenance} alt="" className="w-56" />
              <p className="text-[#aca9a5] text-[16px] leading-[24px] font-thin lg:mt-8 md:mt-8 mt-8 lg:text-left md:text-left text-center">
                Our premier maintenance solutions come with trained technicians
                who meet our brand’s high standards for service and customer
                satisfaction. Our services cover repairs and maintenance of
                property or assets, including external property maintenance,
                roof, walls, floors, windows, ceiling, and other fixtures and
                fittings. Our ability to identify and diagnose existing or
                potential problems is standard procedure, backed by quality
                workmanship and reliability. Providing comprehensive maintenance
                solutions, we service households of all sizes across Dubai.
              </p>
              <Link
                to={"https://www.365maintenance.ae/"}
                target="_blank"
                className=" bg-[#aca9a5] border border-[#aca9a5] uppercase rounded-sm text-white mt-8 font-thin text-[16px] px-4 py-2 hover:no-underline hover:text-white focus:text-white active:text-white"
              >
                Know More
              </Link>
            </div>
          </div>
          <div className="lg:w-[50%] md:w-full h-full">
            <img src={section5} alt="" className="lg:hidden" />
          </div>
        </div>
      </section>
      <div className="flex flex-col items-center justify-center py-20">
        <a
          href="#banner"
          className="text-[#aca9a5] hover:no-underline hover:text-[#aca9a5]"
        >
          Go To Top
        </a>
        <div className="w-[2px] h-14 bg-[#aca9a5]"></div>
      </div>
    </div>
  );
};

export default About;
