import React, { useEffect, useState } from "react";
import "./style.css";
import axios from "axios";
import DynamicSeoPage from "../DynamicSeoPage";

const ListWithUs = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    location: "",
    propertyType: "",
    beds: "",
    size: "",
    unitNo: "",
    price: "",
    images: [],
  });

  const [section, setSection] = useState({});

  const fetchSection = async () => {
    try {
      const response = await axios.get(
        `https://365luxuryhomes.com/api/sections/property_owners`
      );

      if (response.data) {
        setSection(response.data);
      }
    } catch (error) {
      console.error("Error fetching section:", error);
    }
  };

  useEffect(() => {
    fetchSection();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (index, file) => {
    if (!file) {
      return;
    }
    const newImages = [...formData.images];
    newImages[index] = { file, preview: URL.createObjectURL(file) };
    setFormData({ ...formData, images: newImages });
  };

  const handleImageUpload = async (image) => {
    const formData = new FormData();
    formData.append("image", image.file);

    const response = await axios.post(
      "https://365luxuryhomes.com/api/blogs/upload-image",
      formData
    );
    return response.data.url;
  };

  const addImageField = () => {
    setFormData({
      ...formData,
      images: [...formData.images, { file: null, preview: "" }],
    });
  };

  const removeImageField = (index) => {
    const newImages = [...formData.images];
    const removedImage = newImages.splice(index, 1)[0];

    if (removedImage?.preview) {
      URL.revokeObjectURL(removedImage.preview);
    }

    setFormData({ ...formData, images: newImages });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Upload images and get URLs
      const images = await Promise.all(
        formData.images
          .filter((image) => image.file) // Ensure only valid files are uploaded
          .map((image) => handleImageUpload(image))
      );

      const listData = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        location: formData.location,
        propertyType: formData.propertyType,
        beds: formData.beds,
        size: formData.size,
        unitNo: formData.unitNo,
        price: formData.price,
        images: images,
      };

      await axios.post("https://365luxuryhomes.com/api/list", listData);

      // Reset form data
      setFormData({
        name: "",
        email: "",
        phone: "",
        location: "",
        propertyType: "",
        beds: "",
        size: "",
        unitNo: "",
        price: "",
        images: [],
      });

      alert("Form submitted successfully!");
      setLoading(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("There was an error submitting the form. Please try again.");
      setLoading(false);
    }
  };

  return (
    <>
      <DynamicSeoPage page="list-with-us" />
      <section className="pb-14">
        <section
          className="listwithus_banner pt-20 pb-10"
          id="banner"
          style={{
            backgroundImage: `url(${section?.imageUrl || ""})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></section>
        <div className="container flex flex-col items-center justify-center h-full">
          <div className="mt-10">
            <h1 className="text-[#aca9a5] font-thin text-center lg:text-2xl md:text-2xl text-xl">
              List Your Property With 365 Luxury Homes
            </h1>
            <h2 className="text-[#aca9a5] font-thin text-center lg:text-2xl md:text-2xl text-xl mt-4">
              Let us manage your home!
            </h2>
            <p className="text-[#aca9a5] font-thin text-[15px] leading-[24px] mt-4 text-center">
              Listing your home with us will assure you that you are associated
              with a leading real estate partner who can benefit you throughout
              the process of selling or renting out your home and beyond.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-[#fdfcf5]">
        <div className="container">
          <form onSubmit={handleSubmit} className="p-6 rounded-lg w-full">
            <h2 className="text-[20px] font-semibold mb-4 text-[#aca9a5]">
              Submit Your Property Details
            </h2>
            <h3 className="text-[20px] font-semibold mb-4 text-[#aca9a5]">
              About Yourself
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
              {["name", "email", "phone"].map((field, idx) => (
                <div key={idx}>
                  <input
                    type={field === "email" ? "email" : "text"}
                    name={field}
                    value={formData[field]}
                    required
                    placeholder={`Your ${field}`}
                    onChange={handleChange}
                    className="w-full bg-[#fdfcf5] border-0 border-b text-[14px] p-2 focus:outline-none focus:ring-0 focus:border-[#aca9a5]"
                  />
                </div>
              ))}
            </div>

            <h3 className="text-[20px] font-semibold mb-4 text-[#aca9a5]">
              About Property
            </h3>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
              {[
                {
                  label: "Location",
                  name: "location",
                  type: "select",
                  options: [
                    "Dubai",
                    "Abu Dhabi",
                    "Sharjah",
                    "Ajman",
                    "Ras Al Khaimah",
                  ],
                },
                { label: "Property Type", name: "propertyType" },
                {
                  label: "Bedrooms",
                  name: "beds",
                  type: "select",
                  options: [
                    "Studios",
                    "1 Bedroom",
                    "2 Bedrooms",
                    "3 Bedrooms",
                    "4 Bedrooms",
                    "5 Bedrooms",
                  ],
                },
                { label: "Size Sq ft", name: "size" },
                { label: "Unit No", name: "unitNo" },
                { label: "Price", name: "price" },
              ].map(({ label, name, type, options }, idx) => (
                <div key={idx}>
                  {type === "select" ? (
                    <select
                      name={name}
                      value={formData[name]}
                      required
                      onChange={handleChange}
                      className="w-full text-[#aca9a5] bg-[#fdfcf5] border-0 border-b p-2 text-[14px] focus:outline-none focus:ring-0 focus:border-[#aca9a5]"
                    >
                      <option value="" disabled>
                        Select {label}
                      </option>
                      {options.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type={type || "text"}
                      name={name}
                      value={formData[name]}
                      required
                      placeholder={label}
                      onChange={handleChange}
                      className="w-full bg-[#fdfcf5] border-0 border-b p-2 text-[14px] focus:outline-none focus:ring-0 focus:border-[#aca9a5]"
                    />
                  )}
                </div>
              ))}
            </div>

            <div className="mb-6">
              <label className="block text-[#aca9a5] mb-2">Upload Images</label>
              <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-4">
                {formData?.images?.map((image, index) => (
                  <div
                    key={index}
                    className="mb-4 flex flex-col items-center border border-dashed border-gray-300 p-2 rounded"
                  >
                    <label
                      htmlFor={`image-upload-${index}`}
                      className="cursor-pointer w-full flex flex-col items-center justify-center border-2 border-dashed border-gray-300 p-2 rounded hover:bg-gray-100"
                    >
                      {image?.preview ? (
                        <img
                          src={image?.preview}
                          alt="Preview"
                          className="w-full h-32 object-contain mb-2"
                        />
                      ) : (
                        <span className="text-[#aca9a5]">Add file...</span>
                      )}
                      <input
                        type="file"
                        id={`image-upload-${index}`}
                        accept="image/*"
                        onChange={(e) =>
                          handleImageChange(index, e.target.files[0])
                        }
                        className="hidden"
                      />
                    </label>
                    <button
                      type="button"
                      onClick={() => removeImageField(index)}
                      className="mt-2 text-red-500 flex items-center gap-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={24}
                        height={24}
                        className="text-inherit"
                        fill={"none"}
                      >
                        <path
                          d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>Remove</span>
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-4 flex justify-between items-center">
              <button
                type="button"
                onClick={addImageField}
                className="text-white bg-[#aca9a5] px-5 py-2 rounded-sm hover:bg-[#949290] mt-4 flex items-center gap-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="text-inherit"
                  fill={"none"}
                >
                  <path
                    d="M12 4V20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 12H20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Add Images</span>
              </button>
              <button
                type="submit"
                className="bg-[#aca9a5] text-white flex items-center gap-2 px-6 py-2 rounded-sm hover:bg-[#6c675b] focus:ring-2 focus:ring-gray-400"
              >
                <span>
                  {loading ? (
                    <span className="loading loading-spinner loading-sm"></span>
                  ) : (
                    "Submit"
                  )}
                </span>
              </button>
            </div>
          </form>
        </div>
      </section>
      <section className="py-12 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl font-normal text-center mb-10 text-[#aaa9a5]">
            Why Choose Us For Property Management?
          </h2>
          <div className="w-full">
            <div className="flex lg:flex-row md:flex-row flex-col lg:items-start md:items-start items-center justify-center lg:gap-8 md:gap-5 gap-4 w-full">
              <div className=" text-center w-72">
                <h3 className="text-base font-medium mb-2 text-[#aaa9a5]">
                  Trustworthiness You Can Count On
                </h3>
                <p className="text-[#aaa9a5] text-[14px] font-light">
                  We treat your property as if it were our own. With a proven
                  track record of satisfied homeowners, we’ve built a reputation
                  as the most reliable holiday home management company in Dubai.
                </p>
              </div>
              <div className="lg:h-14 md:h-14 h-5 min-w-[2px] bg-[#aaa9a5]"></div>
              <div className=" text-center w-72">
                <h3 className="text-base font-medium mb-2 text-[#aaa9a5]">
                  Complete Transparency
                </h3>
                <p className="text-[#aaa9a5] text-[14px] font-light">
                  Say goodbye to hidden charges and unclear agreements. From
                  earnings to expenses, you’ll have full visibility into how
                  your property is performing through our real-time reporting
                  system.
                </p>
              </div>
              <div className="lg:h-14 md:h-14 h-5 min-w-[2px] bg-[#aaa9a5]"></div>
              <div className=" text-center w-72">
                <h3 className="text-base font-medium mb-2 text-[#aaa9a5]">
                  Hassle-Free Management
                </h3>
                <p className="text-[#aaa9a5] text-[14px] font-light">
                  Let us handle everything: marketing, guest management,
                  maintenance, and compliance while you enjoy a steady,
                  optimized income stream.
                </p>
              </div>
            </div>
            <div className="flex lg:flex-row md:flex-row flex-col lg:items-start md:items-start items-center justify-center lg:gap-8 md:gap-5 gap-4 w-full mt-8">
              <div className=" text-center w-72">
                <h3 className="text-base font-medium mb-2 text-[#aaa9a5]">
                  Maximized ROI
                </h3>
                <p className="text-[#aaa9a5] text-[14px] font-light">
                  With cutting-edge technology and market expertise, we ensure
                  your property achieves its highest earning potential, whether
                  it’s through short-term stays or long-term bookings.
                </p>
              </div>
              <div className="lg:h-14 md:h-14 h-5 min-w-[2px] bg-[#aaa9a5]"></div>
              <div className=" text-center w-72">
                <h3 className="text-base font-medium mb-2 text-[#aaa9a5]">
                  Exceptional Guest Experiences
                </h3>
                <p className="text-[#aaa9a5] text-[14px] font-light">
                  We prioritize five-star hospitality, attracting repeat guests
                  and glowing reviews that elevate your property’s profile and
                  revenue.
                </p>
              </div>
              <div className="lg:h-14 md:h-14 h-5 min-w-[2px] bg-[#aaa9a5]"></div>
              <div className=" text-center w-72">
                <h3 className="text-base font-medium mb-2 text-[#aaa9a5]">
                  Local Expertise, Global Standards
                </h3>
                <p className="text-[#aaa9a5] text-[14px] font-light">
                  Navigating Dubai’s dynamic market can be overwhelming. Our
                  team combines in-depth local knowledge with world-class
                  property management practices to keep you ahead of the
                  competition.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-12 bg-[#fdfcf5]">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-2xl font-normal text-center mb-10 text-[#aaa9a5]">
            Start Your Journey With 365 Luxury Homes
          </h2>
          <p className="text-[#aaa9a5] text-[14px] font-light leading-relaxed mb-4">
            Let’s work together to make your property a thriving holiday home
            destination. With 365 Luxury Homes, you can rest assured knowing
            your investment is in the safest and most capable hands. Contact us
            today to learn more about our services and start your journey toward
            effortless property success!
          </p>
        </div>
      </section>
    </>
  );
};

export default ListWithUs;
