import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";

const DynamicSeoPage = ({ page }) => {
  const [seoData, setSeoData] = useState({
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
    metaCanonical: "",
    ogImage: "",
  });

  useEffect(() => {
    const fetchSeoData = async () => {
      try {
        const response = await axios.get(
          `https://365luxuryhomes.com/api/seo-settings/${page}`
        );
        const data = response.data || {};

        setSeoData({
          metaTitle: data.metaTitle || "",
          metaDescription: data.metaDescription || "",
          metaKeywords: data.metaKeywords || "",
          metaCanonical: data.metaCanonical || "",
          ogImage: data.ogImage || "",
        });
      } catch (error) {
        console.error("Error fetching SEO data:", error);
        // Use fallback SEO data in case of an error
        setSeoData({
          metaTitle: "",
          metaDescription: "",
          metaKeywords: "",
          metaCanonical: "",
          ogImage: "",
        });
      }
    };

    fetchSeoData();
  }, [page]);

  return (
    <>
      <Helmet>
        {/* Basic SEO */}
        <title>{seoData.metaTitle}</title>
        <meta name="description" content={seoData.metaDescription} />
        <meta name="keywords" content={seoData.metaKeywords} />
        {seoData.metaCanonical && (
          <link rel="canonical" href={seoData.metaCanonical} />
        )}

        {/* Open Graph (OG) Tags */}
        <meta property="og:title" content={seoData.metaTitle} />
        <meta property="og:description" content={seoData.metaDescription} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={seoData.metaCanonical || window.location.href}
        />
        {seoData.ogImage && (
          <meta property="og:image" content={seoData.ogImage} />
        )}

        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoData.metaTitle} />
        <meta name="twitter:description" content={seoData.metaDescription} />
        {seoData.ogImage && (
          <meta name="twitter:image" content={seoData.ogImage} />
        )}

        {/* Robots */}
        <meta name="robots" content="index, follow" />

        {/* Favicon */}
        <link rel="icon" type="image/png" href="/favicon.ico" />

        {/* Structured Data (JSON-LD) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: seoData.metaTitle,
            description: seoData.metaDescription,
            url: seoData.metaCanonical || window.location.href,
          })}
        </script>
      </Helmet>
    </>
  );
};

export default DynamicSeoPage;
