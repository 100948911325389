export const calculateTaxes = (tax, nights, baseRate) => {
  let cityTourismTax = 0;
  let occupancyTax = 0;
  let vatGstTax = 0;

  tax?.forEach((tax) => {
    if (tax.taxType === "cityTax" && tax.taxAppliedPer === "night") {
      cityTourismTax += tax.amount * nights;
    }
    if (tax.taxType === "occupancyTax" && tax.taxAppliedPer === "reservation") {
      occupancyTax += (baseRate * tax.amount) / 100;
    }
    if (tax.taxType === "vat" && tax.taxAppliedPer === "reservation") {
      vatGstTax += (baseRate * tax.amount) / 100;
    }
  });

  return { cityTourismTax, occupancyTax, vatGstTax };
};
