import React, { useContext } from "react";
import { DataContext } from "../../Context/Context";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";

const Listings = () => {
  const { listings = [], loading } = useContext(DataContext);

  const truncateDescription = (description, wordLimit) => {
    const words = description?.split(" ");
    if (words?.length > wordLimit) {
      return words?.slice(0, wordLimit).join(" ") + "...";
    }
    return description;
  };

  return (
    <section className="lg:py-20 md:py-14 py-10">
      <div className="container">
        <div className="flex lg:flex-row md:flex-col flex-col items-start gap-10">
          <div className="lg:w-[35%]">
            <div className="flex items-end gap-5 mb-10">
              <h2 className="leading-none text-2xl font-bold text-[#aca9a5]">
                OUR PROPERTIES
              </h2>
              <div className="w-20 h-[2px] bg-[#aca9a5]"></div>
            </div>
            <p className="font-thin text-[15px] leading-[24px] text-[#aca9a5] mb-8">
              With a portfolio spanning Dubai's finest and most premium
              properties, our luxury real estate offerings connect local and
              international clients to their next home rental or investment. We
              represent each property in a manner that compels buyers to enquire
              for more information.
            </p>
            <Link
              to={"/properties"}
              className=" bg-[#aca9a5] uppercase rounded-sm text-white font-thin text-[16px] px-4 py-2 hover:no-underline hover:text-slate-100"
            >
              View More
            </Link>
          </div>
          <div className="lg:w-[65%] md:w-full w-full">
            {loading ? (
              <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4 grid-cols-1">
                <div className="flex w-full flex-col gap-4">
                  <div className="skeleton h-32 w-full"></div>
                  <div className="skeleton h-4 w-28"></div>
                  <div className="skeleton h-4 w-full"></div>
                  <div className="skeleton h-4 w-full"></div>
                </div>
                <div className="flex w-full flex-col gap-4">
                  <div className="skeleton h-32 w-full"></div>
                  <div className="skeleton h-4 w-28"></div>
                  <div className="skeleton h-4 w-full"></div>
                  <div className="skeleton h-4 w-full"></div>
                </div>
                <div className="flex w-full flex-col gap-4 lg:visible md:hidden hidden">
                  <div className="skeleton h-32 w-full"></div>
                  <div className="skeleton h-4 w-28"></div>
                  <div className="skeleton h-4 w-full"></div>
                  <div className="skeleton h-4 w-full"></div>
                </div>
              </div>
            ) : (
              <>
                <Swiper
                  slidesPerView={3}
                  spaceBetween={20}
                  loop={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    1280: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                  }}
                  modules={[Pagination, Autoplay]}
                  className="lg:w-auto md:w-auto w-full lg:px-0 md:px-0 px-4"
                >
                  {listings?.slice(0, 6)?.map((listing) => (
                    <SwiperSlide key={listing.id}>
                      <Link
                        to={`/listing/${listing.id}`}
                        className="hover:no-underline"
                      >
                        <img
                          src={listing?.listingImages[0]?.url}
                          alt=""
                          loading="lazy"
                          className="mb-4 shadow-lg w-full rounded-lg lg:h-44 md:h-48 h-56"
                        />
                        <div>
                          <h3 className="leading-[26px] font-normal text-[#aca9a5] text-[18px]">
                            {listing?.name}
                          </h3>
                          <p className="mt-2 font-thin text-[15px] text-[#aca9a5] leading-snug">
                            {truncateDescription(listing?.description, 14)}
                          </p>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Listings;
