import React, { Suspense, useEffect, useState } from "react";
import Banner from "./Banner";
import About from "./About";
import Gallery from "./Gallery";
import Testimonials from "./Testimonials";
import Featured from "./Featured";
import Listings from "./Listings";
import DynamicSeoPage from "../DynamicSeoPage";

const Home = () => {
  return (
    <>
      <DynamicSeoPage page="home" />
      <Suspense>
        <Banner />
      </Suspense>
      <Suspense>
        <Listings />
      </Suspense>
      <Suspense>
        <Gallery />
      </Suspense>
      <About />
      <Featured />
      <Testimonials />
    </>
  );
};

export default Home;
