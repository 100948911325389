import React, { useState } from "react";
import "./style.css";
import img from "../../Assets/contact.jpg";
import MapView from "../../Components/MapView";
import axios from "axios";
import CustomDropdown from "../../Components/CustomDropdown";
import DynamicSeoPage from "../DynamicSeoPage";

const allCountryCodes = [
  {
    code: "AF",
    dialCode: "+93",
    flag: "https://flagsapi.com/AF/flat/32.png",
    length: 9,
  },
  {
    code: "AL",
    dialCode: "+355",
    flag: "https://flagsapi.com/AL/flat/32.png",
    length: 9,
  },
  {
    code: "DZ",
    dialCode: "+213",
    flag: "https://flagsapi.com/DZ/flat/32.png",
    length: 9,
  },
  {
    code: "AS",
    dialCode: "+1-684",
    flag: "https://flagsapi.com/AS/flat/32.png",
    length: 7,
  },
  {
    code: "AD",
    dialCode: "+376",
    flag: "https://flagsapi.com/AD/flat/32.png",
    length: 6,
  },
  {
    code: "AO",
    dialCode: "+244",
    flag: "https://flagsapi.com/AO/flat/32.png",
    length: 9,
  },
  {
    code: "AI",
    dialCode: "+1-264",
    flag: "https://flagsapi.com/AI/flat/32.png",
    length: 7,
  },
  {
    code: "AG",
    dialCode: "+1-268",
    flag: "https://flagsapi.com/AG/flat/32.png",
    length: 7,
  },
  {
    code: "AR",
    dialCode: "+54",
    flag: "https://flagsapi.com/AR/flat/32.png",
    length: 10,
  },
  {
    code: "AM",
    dialCode: "+374",
    flag: "https://flagsapi.com/AM/flat/32.png",
    length: 8,
  },
  {
    code: "AW",
    dialCode: "+297",
    flag: "https://flagsapi.com/AW/flat/32.png",
    length: 7,
  },
  {
    code: "AU",
    dialCode: "+61",
    flag: "https://flagsapi.com/AU/flat/32.png",
    length: 9,
  },
  {
    code: "AT",
    dialCode: "+43",
    flag: "https://flagsapi.com/AT/flat/32.png",
    length: 10,
  },
  {
    code: "AZ",
    dialCode: "+994",
    flag: "https://flagsapi.com/AZ/flat/32.png",
    length: 9,
  },
  {
    code: "BS",
    dialCode: "+1-242",
    flag: "https://flagsapi.com/BS/flat/32.png",
    length: 7,
  },
  {
    code: "BH",
    dialCode: "+973",
    flag: "https://flagsapi.com/BH/flat/32.png",
    length: 8,
  },
  {
    code: "BD",
    dialCode: "+880",
    flag: "https://flagsapi.com/BD/flat/32.png",
    length: 11,
  },
  {
    code: "BB",
    dialCode: "+1-246",
    flag: "https://flagsapi.com/BB/flat/32.png",
    length: 7,
  },
  {
    code: "BY",
    dialCode: "+375",
    flag: "https://flagsapi.com/BY/flat/32.png",
    length: 9,
  },
  {
    code: "BE",
    dialCode: "+32",
    flag: "https://flagsapi.com/BE/flat/32.png",
    length: 9,
  },
  {
    code: "BZ",
    dialCode: "+501",
    flag: "https://flagsapi.com/BZ/flat/32.png",
    length: 7,
  },
  {
    code: "BJ",
    dialCode: "+229",
    flag: "https://flagsapi.com/BJ/flat/32.png",
    length: 8,
  },
  {
    code: "BM",
    dialCode: "+1-441",
    flag: "https://flagsapi.com/BM/flat/32.png",
    length: 7,
  },
  {
    code: "BT",
    dialCode: "+975",
    flag: "https://flagsapi.com/BT/flat/32.png",
    length: 8,
  },
  {
    code: "BO",
    dialCode: "+591",
    flag: "https://flagsapi.com/BO/flat/32.png",
    length: 8,
  },
  {
    code: "BA",
    dialCode: "+387",
    flag: "https://flagsapi.com/BA/flat/32.png",
    length: 8,
  },
  {
    code: "BW",
    dialCode: "+267",
    flag: "https://flagsapi.com/BW/flat/32.png",
    length: 7,
  },
  {
    code: "BR",
    dialCode: "+55",
    flag: "https://flagsapi.com/BR/flat/32.png",
    length: 11,
  },
  {
    code: "BN",
    dialCode: "+673",
    flag: "https://flagsapi.com/BN/flat/32.png",
    length: 7,
  },
  {
    code: "BG",
    dialCode: "+359",
    flag: "https://flagsapi.com/BG/flat/32.png",
    length: 9,
  },
  {
    code: "BF",
    dialCode: "+226",
    flag: "https://flagsapi.com/BF/flat/32.png",
    length: 8,
  },
  {
    code: "BI",
    dialCode: "+257",
    flag: "https://flagsapi.com/BI/flat/32.png",
    length: 8,
  },
  {
    code: "KH",
    dialCode: "+855",
    flag: "https://flagsapi.com/KH/flat/32.png",
    length: 8,
  },
  {
    code: "CM",
    dialCode: "+237",
    flag: "https://flagsapi.com/CM/flat/32.png",
    length: 9,
  },
  {
    code: "CA",
    dialCode: "+1",
    flag: "https://flagsapi.com/CA/flat/32.png",
    length: 10,
  },
  {
    code: "CV",
    dialCode: "+238",
    flag: "https://flagsapi.com/CV/flat/32.png",
    length: 7,
  },
  {
    code: "KY",
    dialCode: "+1-345",
    flag: "https://flagsapi.com/KY/flat/32.png",
    length: 7,
  },
  {
    code: "CF",
    dialCode: "+236",
    flag: "https://flagsapi.com/CF/flat/32.png",
    length: 8,
  },
  {
    code: "TD",
    dialCode: "+235",
    flag: "https://flagsapi.com/TD/flat/32.png",
    length: 8,
  },
  {
    code: "CL",
    dialCode: "+56",
    flag: "https://flagsapi.com/CL/flat/32.png",
    length: 9,
  },
  {
    code: "CN",
    dialCode: "+86",
    flag: "https://flagsapi.com/CN/flat/32.png",
    length: 11,
  },
  {
    code: "DE",
    dialCode: "+49",
    flag: "https://flagsapi.com/DE/flat/32.png",
    length: 11,
  },
  {
    code: "EG",
    dialCode: "+20",
    flag: "https://flagsapi.com/EG/flat/32.png",
    length: 10,
  },
  {
    code: "ES",
    dialCode: "+34",
    flag: "https://flagsapi.com/ES/flat/32.png",
    length: 9,
  },
  {
    code: "FI",
    dialCode: "+358",
    flag: "https://flagsapi.com/FI/flat/32.png",
    length: 9,
  },
  {
    code: "FR",
    dialCode: "+33",
    flag: "https://flagsapi.com/FR/flat/32.png",
    length: 9,
  },
  {
    code: "GB",
    dialCode: "+44",
    flag: "https://flagsapi.com/GB/flat/32.png",
    length: 10,
  },
  {
    code: "GR",
    dialCode: "+30",
    flag: "https://flagsapi.com/GR/flat/32.png",
    length: 10,
  },
  {
    code: "IN",
    dialCode: "+91",
    flag: "https://flagsapi.com/IN/flat/32.png",
    length: 10,
  },
  {
    code: "IT",
    dialCode: "+39",
    flag: "https://flagsapi.com/IT/flat/32.png",
    length: 10,
  },
  {
    code: "JP",
    dialCode: "+81",
    flag: "https://flagsapi.com/JP/flat/32.png",
    length: 10,
  },
  {
    code: "KE",
    dialCode: "+254",
    flag: "https://flagsapi.com/KE/flat/32.png",
    length: 10,
  },
  {
    code: "KR",
    dialCode: "+82",
    flag: "https://flagsapi.com/KR/flat/32.png",
    length: 10,
  },
  {
    code: "MX",
    dialCode: "+52",
    flag: "https://flagsapi.com/MX/flat/32.png",
    length: 10,
  },
  {
    code: "NG",
    dialCode: "+234",
    flag: "https://flagsapi.com/NG/flat/32.png",
    length: 10,
  },
  {
    code: "NL",
    dialCode: "+31",
    flag: "https://flagsapi.com/NL/flat/32.png",
    length: 9,
  },
  {
    code: "NZ",
    dialCode: "+64",
    flag: "https://flagsapi.com/NZ/flat/32.png",
    length: 9,
  },
  {
    code: "PH",
    dialCode: "+63",
    flag: "https://flagsapi.com/PH/flat/32.png",
    length: 10,
  },
  {
    code: "PK",
    dialCode: "+92",
    flag: "https://flagsapi.com/PK/flat/32.png",
    length: 10,
  },
  {
    code: "RU",
    dialCode: "+7",
    flag: "https://flagsapi.com/RU/flat/32.png",
    length: 10,
  },
  {
    code: "SA",
    dialCode: "+966",
    flag: "https://flagsapi.com/SA/flat/32.png",
    length: 9,
  },
  {
    code: "SE",
    dialCode: "+46",
    flag: "https://flagsapi.com/SE/flat/32.png",
    length: 9,
  },
  {
    code: "SG",
    dialCode: "+65",
    flag: "https://flagsapi.com/SG/flat/32.png",
    length: 8,
  },
  {
    code: "US",
    dialCode: "+1",
    flag: "https://flagsapi.com/US/flat/32.png",
    length: 10,
  },
  {
    code: "ZA",
    dialCode: "+27",
    flag: "https://flagsapi.com/ZA/flat/32.png",
    length: 9,
  },
  {
    code: "ZW",
    dialCode: "+263",
    flag: "https://flagsapi.com/ZW/flat/32.png",
    length: 9,
  },
  {
    code: "MO",
    dialCode: "+853",
    flag: "https://flagsapi.com/MO/flat/32.png",
    length: 8,
  },
  {
    code: "MK",
    dialCode: "+389",
    flag: "https://flagsapi.com/MK/flat/32.png",
    length: 8,
  },
  {
    code: "MG",
    dialCode: "+261",
    flag: "https://flagsapi.com/MG/flat/32.png",
    length: 9,
  },
  {
    code: "MW",
    dialCode: "+265",
    flag: "https://flagsapi.com/MW/flat/32.png",
    length: 7,
  },
  {
    code: "MY",
    dialCode: "+60",
    flag: "https://flagsapi.com/MY/flat/32.png",
    length: 10,
  },
  {
    code: "MV",
    dialCode: "+960",
    flag: "https://flagsapi.com/MV/flat/32.png",
    length: 7,
  },
  {
    code: "ML",
    dialCode: "+223",
    flag: "https://flagsapi.com/ML/flat/32.png",
    length: 8,
  },
  {
    code: "MT",
    dialCode: "+356",
    flag: "https://flagsapi.com/MT/flat/32.png",
    length: 8,
  },
  {
    code: "MH",
    dialCode: "+692",
    flag: "https://flagsapi.com/MH/flat/32.png",
    length: 7,
  },
  {
    code: "MR",
    dialCode: "+222",
    flag: "https://flagsapi.com/MR/flat/32.png",
    length: 9,
  },
  {
    code: "MU",
    dialCode: "+230",
    flag: "https://flagsapi.com/MU/flat/32.png",
    length: 7,
  },
  {
    code: "FM",
    dialCode: "+691",
    flag: "https://flagsapi.com/FM/flat/32.png",
    length: 7,
  },
  {
    code: "MD",
    dialCode: "+373",
    flag: "https://flagsapi.com/MD/flat/32.png",
    length: 8,
  },
  {
    code: "MC",
    dialCode: "+377",
    flag: "https://flagsapi.com/MC/flat/32.png",
    length: 8,
  },
  {
    code: "MN",
    dialCode: "+976",
    flag: "https://flagsapi.com/MN/flat/32.png",
    length: 8,
  },
  {
    code: "ME",
    dialCode: "+382",
    flag: "https://flagsapi.com/ME/flat/32.png",
    length: 8,
  },
  {
    code: "MA",
    dialCode: "+212",
    flag: "https://flagsapi.com/MA/flat/32.png",
    length: 9,
  },
  {
    code: "MZ",
    dialCode: "+258",
    flag: "https://flagsapi.com/MZ/flat/32.png",
    length: 9,
  },
  {
    code: "MM",
    dialCode: "+95",
    flag: "https://flagsapi.com/MM/flat/32.png",
    length: 9,
  },
  {
    code: "NA",
    dialCode: "+264",
    flag: "https://flagsapi.com/NA/flat/32.png",
    length: 9,
  },
  {
    code: "NR",
    dialCode: "+674",
    flag: "https://flagsapi.com/NR/flat/32.png",
    length: 7,
  },
  {
    code: "NP",
    dialCode: "+977",
    flag: "https://flagsapi.com/NP/flat/32.png",
    length: 10,
  },
  {
    code: "NI",
    dialCode: "+505",
    flag: "https://flagsapi.com/NI/flat/32.png",
    length: 8,
  },
  {
    code: "NE",
    dialCode: "+227",
    flag: "https://flagsapi.com/NE/flat/32.png",
    length: 8,
  },
  {
    code: "NO",
    dialCode: "+47",
    flag: "https://flagsapi.com/NO/flat/32.png",
    length: 8,
  },
  {
    code: "OM",
    dialCode: "+968",
    flag: "https://flagsapi.com/OM/flat/32.png",
    length: 8,
  },
  {
    code: "PA",
    dialCode: "+507",
    flag: "https://flagsapi.com/PA/flat/32.png",
    length: 7,
  },
  {
    code: "PG",
    dialCode: "+675",
    flag: "https://flagsapi.com/PG/flat/32.png",
    length: 7,
  },
  {
    code: "PY",
    dialCode: "+595",
    flag: "https://flagsapi.com/PY/flat/32.png",
    length: 9,
  },
  {
    code: "PE",
    dialCode: "+51",
    flag: "https://flagsapi.com/PE/flat/32.png",
    length: 9,
  },
  {
    code: "PL",
    dialCode: "+48",
    flag: "https://flagsapi.com/PL/flat/32.png",
    length: 9,
  },
  {
    code: "PT",
    dialCode: "+351",
    flag: "https://flagsapi.com/PT/flat/32.png",
    length: 9,
  },
  {
    code: "QA",
    dialCode: "+974",
    flag: "https://flagsapi.com/QA/flat/32.png",
    length: 8,
  },
  {
    code: "RO",
    dialCode: "+40",
    flag: "https://flagsapi.com/RO/flat/32.png",
    length: 10,
  },
  {
    code: "RW",
    dialCode: "+250",
    flag: "https://flagsapi.com/RW/flat/32.png",
    length: 9,
  },
  {
    code: "WS",
    dialCode: "+685",
    flag: "https://flagsapi.com/WS/flat/32.png",
    length: 7,
  },
  {
    code: "SM",
    dialCode: "+378",
    flag: "https://flagsapi.com/SM/flat/32.png",
    length: 9,
  },
  {
    code: "ST",
    dialCode: "+239",
    flag: "https://flagsapi.com/ST/flat/32.png",
    length: 7,
  },
  {
    code: "SN",
    dialCode: "+221",
    flag: "https://flagsapi.com/SN/flat/32.png",
    length: 9,
  },
  {
    code: "RS",
    dialCode: "+381",
    flag: "https://flagsapi.com/RS/flat/32.png",
    length: 9,
  },
  {
    code: "SC",
    dialCode: "+248",
    flag: "https://flagsapi.com/SC/flat/32.png",
    length: 7,
  },
  {
    code: "SL",
    dialCode: "+232",
    flag: "https://flagsapi.com/SL/flat/32.png",
    length: 8,
  },
  {
    code: "SK",
    dialCode: "+421",
    flag: "https://flagsapi.com/SK/flat/32.png",
    length: 9,
  },
  {
    code: "SI",
    dialCode: "+386",
    flag: "https://flagsapi.com/SI/flat/32.png",
    length: 9,
  },
  {
    code: "SB",
    dialCode: "+677",
    flag: "https://flagsapi.com/SB/flat/32.png",
    length: 7,
  },
  {
    code: "SO",
    dialCode: "+252",
    flag: "https://flagsapi.com/SO/flat/32.png",
    length: 8,
  },
  {
    code: "SS",
    dialCode: "+211",
    flag: "https://flagsapi.com/SS/flat/32.png",
    length: 9,
  },
  {
    code: "LK",
    dialCode: "+94",
    flag: "https://flagsapi.com/LK/flat/32.png",
    length: 9,
  },
  {
    code: "SD",
    dialCode: "+249",
    flag: "https://flagsapi.com/SD/flat/32.png",
    length: 9,
  },
  {
    code: "SR",
    dialCode: "+597",
    flag: "https://flagsapi.com/SR/flat/32.png",
    length: 7,
  },
  {
    code: "SZ",
    dialCode: "+268",
    flag: "https://flagsapi.com/SZ/flat/32.png",
    length: 8,
  },
  {
    code: "CH",
    dialCode: "+41",
    flag: "https://flagsapi.com/CH/flat/32.png",
    length: 9,
  },
  {
    code: "SY",
    dialCode: "+963",
    flag: "https://flagsapi.com/SY/flat/32.png",
    length: 9,
  },
  {
    code: "TW",
    dialCode: "+886",
    flag: "https://flagsapi.com/TW/flat/32.png",
    length: 9,
  },
  {
    code: "TJ",
    dialCode: "+992",
    flag: "https://flagsapi.com/TJ/flat/32.png",
    length: 9,
  },
  {
    code: "TZ",
    dialCode: "+255",
    flag: "https://flagsapi.com/TZ/flat/32.png",
    length: 9,
  },
  {
    code: "TH",
    dialCode: "+66",
    flag: "https://flagsapi.com/TH/flat/32.png",
    length: 9,
  },
  {
    code: "TG",
    dialCode: "+228",
    flag: "https://flagsapi.com/TG/flat/32.png",
    length: 8,
  },
  {
    code: "TO",
    dialCode: "+676",
    flag: "https://flagsapi.com/TO/flat/32.png",
    length: 7,
  },
  {
    code: "TT",
    dialCode: "+1-868",
    flag: "https://flagsapi.com/TT/flat/32.png",
    length: 7,
  },
  {
    code: "TN",
    dialCode: "+216",
    flag: "https://flagsapi.com/TN/flat/32.png",
    length: 8,
  },
  {
    code: "TR",
    dialCode: "+90",
    flag: "https://flagsapi.com/TR/flat/32.png",
    length: 10,
  },
  {
    code: "TM",
    dialCode: "+993",
    flag: "https://flagsapi.com/TM/flat/32.png",
    length: 8,
  },
  {
    code: "TC",
    dialCode: "+1-649",
    flag: "https://flagsapi.com/TC/flat/32.png",
    length: 7,
  },
  {
    code: "TV",
    dialCode: "+688",
    flag: "https://flagsapi.com/TV/flat/32.png",
    length: 7,
  },
  {
    code: "UG",
    dialCode: "+256",
    flag: "https://flagsapi.com/UG/flat/32.png",
    length: 9,
  },
  {
    code: "UA",
    dialCode: "+380",
    flag: "https://flagsapi.com/UA/flat/32.png",
    length: 9,
  },
  {
    code: "AE",
    dialCode: "+971",
    flag: "https://flagsapi.com/AE/flat/32.png",
    length: 9,
  },
  {
    code: "UY",
    dialCode: "+598",
    flag: "https://flagsapi.com/UY/flat/32.png",
    length: 9,
  },
  {
    code: "UZ",
    dialCode: "+998",
    flag: "https://flagsapi.com/UZ/flat/32.png",
    length: 9,
  },
  {
    code: "VU",
    dialCode: "+678",
    flag: "https://flagsapi.com/VU/flat/32.png",
    length: 7,
  },
  {
    code: "VA",
    dialCode: "+379",
    flag: "https://flagsapi.com/VA/flat/32.png",
    length: 8,
  },
  {
    code: "VE",
    dialCode: "+58",
    flag: "https://flagsapi.com/VE/flat/32.png",
    length: 10,
  },
  {
    code: "VN",
    dialCode: "+84",
    flag: "https://flagsapi.com/VN/flat/32.png",
    length: 9,
  },
  {
    code: "WF",
    dialCode: "+681",
    flag: "https://flagsapi.com/WF/flat/32.png",
    length: 6,
  },
  {
    code: "YE",
    dialCode: "+967",
    flag: "https://flagsapi.com/YE/flat/32.png",
    length: 9,
  },
  {
    code: "ZM",
    dialCode: "+260",
    flag: "https://flagsapi.com/ZM/flat/32.png",
    length: 9,
  },
];

const Contact = () => {
  const [formLoading, setFormLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    countryCode: "+971",
    message: "",
  });

  const [phoneError, setPhoneError] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === "phone") {
      validatePhone(e.target.value);
    }
  };

  const handleCountryChange = (e) => {
    const selectedCountry = allCountryCodes.find(
      (country) => country.dialCode === e.target.value
    );
    setFormData({ ...formData, countryCode: selectedCountry.dialCode });
    validatePhone(formData.phone, selectedCountry.length);
  };

  const validatePhone = (phone, customLength = null) => {
    const selectedCountry = allCountryCodes.find(
      (country) => country.dialCode === formData.countryCode
    );
    const validLength = customLength || selectedCountry.length;
    if (phone.replace(/\D/g, "").length !== validLength) {
      setPhoneError(`Phone number must be ${validLength} digits.`);
    } else {
      setPhoneError(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (phoneError) {
      alert("Please fix the phone number error before submitting.");
      return;
    }

    try {
      setFormLoading(true);
      const response = await axios.post(
        "https://365luxuryhomes.com/api/contact",
        {
          ...formData,
          phone: `${formData.countryCode}${formData.phone}`,
        }
      );

      if (response.status === 200) {
        alert("Your message has been sent successfully!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          countryCode: "+880",
          message: "",
        });
      } else {
        alert("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form. Please try again later.");
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <>
      <DynamicSeoPage page="contact-us" />
      <h1 className="sr-only">
        Contact 365 Luxury Homes - Get in Touch With Us
      </h1>
      <section className="pb-10 bg-[#fdfcf5]">
        <div
          className="conact-banner w-full lg:h-96 md:h-72 h-56"
          style={{
            backgroundImage: `url(${img})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div className="-mt-20">
          <div className="container">
            <div className=" flex flex-col items-center justify-center text-[#aca9a5]">
              <div className="w-full text-center px-4">
                <div className="bg-white pb-10 p-4">
                  <div className="mb-8 pt-8">
                    <h2 className="text-4xl font-semibold">SAY HELLO</h2>
                    <p className="text-sm mt-2">DROP US A LINE</p>
                  </div>
                  <form
                    onSubmit={handleSubmit}
                    className="space-y-6 max-w-lg mx-auto"
                  >
                    <div>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="w-full border-b border-[#aca9a5] bg-transparent focus:outline-none focus:ring-0 text-sm placeholder-[#aca9a5] text-[#aca9a5] h-[50px]"
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                          placeholder="Email"
                          className="w-full border-b border-[#aca9a5] bg-transparent focus:outline-none focus:ring-0 text-sm placeholder-[#aca9a5] text-[#aca9a5] h-[50px]"
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className="flex">
                          <CustomDropdown
                            allCountryCodes={allCountryCodes}
                            formData={formData}
                            setFormData={setFormData}
                          />
                          <input
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder="Phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                            className="w-2/3 border-b border-[#aca9a5] bg-transparent focus:outline-none focus:ring-0 text-sm placeholder-[#aca9a5] text-[#aca9a5] h-[50px]"
                          />
                        </div>
                        {phoneError && (
                          <p className="text-red-500 text-xs mt-1">
                            {phoneError}
                          </p>
                        )}
                      </div>
                    </div>
                    <div>
                      <textarea
                        id="message"
                        name="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                        rows="4"
                        className="w-full border-b border-[#aca9a5] bg-transparent focus:outline-none focus:ring-0 text-sm placeholder-[#aca9a5] text-[#aca9a5] resize-none"
                      ></textarea>
                    </div>
                    <button
                      type="submit"
                      className="bg-[#aca9a5] text-white px-6 py-2 rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 text-sm"
                    >
                      {formLoading ? "Sending..." : "Submit"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-14">
        <div className="container">
          <div className="grid lg:grid-cols-3 md:grid-cols-1 gap-6 mt-24 text-xs">
            {/* Email */}
            <div className="flex text-[#aca9a5] gap-2 flex-col items-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={32}
                height={32}
                className="text-inherit"
                fill={"none"}
              >
                <path
                  d="M2 6L8.91302 9.91697C11.4616 11.361 12.5384 11.361 15.087 9.91697L22 6"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.01577 13.4756C2.08114 16.5412 2.11383 18.0739 3.24496 19.2094C4.37608 20.3448 5.95033 20.3843 9.09883 20.4634C11.0393 20.5122 12.9607 20.5122 14.9012 20.4634C18.0497 20.3843 19.6239 20.3448 20.7551 19.2094C21.8862 18.0739 21.9189 16.5412 21.9842 13.4756C22.0053 12.4899 22.0053 11.5101 21.9842 10.5244C21.9189 7.45886 21.8862 5.92609 20.7551 4.79066C19.6239 3.65523 18.0497 3.61568 14.9012 3.53657C12.9607 3.48781 11.0393 3.48781 9.09882 3.53656C5.95033 3.61566 4.37608 3.65521 3.24495 4.79065C2.11382 5.92608 2.08114 7.45885 2.01576 10.5244C1.99474 11.5101 1.99475 12.4899 2.01577 13.4756Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="leading-[24px] text-[16px] text-center">
                info@365luxuryhomes.com
              </p>
            </div>

            {/* Address */}
            <div className="flex text-[#aca9a5] gap-2 flex-col items-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={32}
                height={32}
                className="text-inherit"
                fill={"none"}
              >
                <path
                  d="M13.6177 21.367C13.1841 21.773 12.6044 22 12.0011 22C11.3978 22 10.8182 21.773 10.3845 21.367C6.41302 17.626 1.09076 13.4469 3.68627 7.37966C5.08963 4.09916 8.45834 2 12.0011 2C15.5439 2 18.9126 4.09916 20.316 7.37966C22.9082 13.4393 17.599 17.6389 13.6177 21.367Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
                <path
                  d="M15.5 11C15.5 12.933 13.933 14.5 12 14.5C10.067 14.5 8.5 12.933 8.5 11C8.5 9.067 10.067 7.5 12 7.5C13.933 7.5 15.5 9.067 15.5 11Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
              </svg>
              <p className="leading-[24px] text-[16px] text-center">
                Bluewaters Island, Dubai
              </p>
            </div>

            {/* Phone */}
            <div className="flex text-[#aca9a5] gap-2 flex-col items-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={32}
                height={32}
                className="text-inherit"
                fill={"none"}
              >
                <path
                  d="M5 9C5 5.70017 5 4.05025 6.02513 3.02513C7.05025 2 8.70017 2 12 2C15.2998 2 16.9497 2 17.9749 3.02513C19 4.05025 19 5.70017 19 9V15C19 18.2998 19 19.9497 17.9749 20.9749C16.9497 22 15.2998 22 12 22C8.70017 22 7.05025 22 6.02513 20.9749C5 19.9497 5 18.2998 5 15V9Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M11 19H13"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 2L9.089 2.53402C9.28188 3.69129 9.37832 4.26993 9.77519 4.62204C10.1892 4.98934 10.7761 5 12 5C13.2239 5 13.8108 4.98934 14.2248 4.62204C14.6217 4.26993 14.7181 3.69129 14.911 2.53402L15 2"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="leading-[24px] text-[16px] text-center">
                +971 52 143 4186
              </p>
            </div>
          </div>
          <div className="mt-12 rounded-xl overflow-hidden">
            <MapView />
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
