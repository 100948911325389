import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { DataContext } from "../../Context/Context";
import logoUrl from "../../Assets/Vector Smart Object.png";

const UserReservations = () => {
  const { user } = useContext(DataContext);
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://365luxuryhomes.com/api/user/reservations/${user._id}`
        );
        setReservations(response.data);
      } catch (error) {
        // console.error("Error fetching reservations:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user?._id) {
      fetchReservations();
    }
  }, [user]);

  if (loading) {
    return (
      <section className="w-full h-full flex items-center justify-center">
        <span className="loading loading-spinner loading-lg"></span>
      </section>
    );
  }

  if (reservations.length === 0) {
    return <p>No reservations found.</p>;
  }

  return (
    <section className="p-4 mt-20">
      <div className="p-4 bg-white rounded-md">
        <h2 className="text-2xl font-thin mb-4 text-[#aca9a5]">
          My Reservations
        </h2>
        <div className="overflow-x-auto rounded-lg">
          <table className="table-auto w-full border-collapse rounded-lg overflow-hidden">
            <thead>
              <tr className="bg-gray-100">
                <th className="border p-2 text-left text-[#aca9a5] text-[14px] md:text-[16px]">
                  Reservation ID
                </th>
                <th className="border p-2 text-left text-[#aca9a5] text-[14px] md:text-[16px]">
                  Check-in
                </th>
                <th className="border p-2 text-left text-[#aca9a5] text-[14px] md:text-[16px]">
                  Check-out
                </th>
                <th className="border p-2 text-left text-[#aca9a5] text-[14px] md:text-[16px]">
                  Guests
                </th>
                <th className="border p-2 text-left text-[#aca9a5] text-[14px] md:text-[16px]">
                  Total Price
                </th>
                <th className="border p-2 text-left text-[#aca9a5] text-[14px] md:text-[16px]">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {reservations.map((reservation) => (
                <tr
                  key={reservation._id}
                  className="hover:bg-gray-50 transition"
                >
                  <td className="border p-2 text-[#aca9a5] text-[14px] md:text-[16px]">
                    {reservation._id}
                  </td>
                  <td className="border p-2 text-[#aca9a5] text-[14px] md:text-[16px]">
                    {reservation.reservationData.arrivalDate}
                  </td>
                  <td className="border p-2 text-[#aca9a5] text-[14px] md:text-[16px]">
                    {reservation.reservationData.departureDate}
                  </td>
                  <td className="border p-2 text-[#aca9a5] text-[14px] md:text-[16px]">
                    {reservation.reservationData.adults} Adults,{" "}
                    {reservation.reservationData.children || 0} Children
                  </td>
                  <td className="border p-2 text-[#aca9a5] text-[14px] md:text-[16px]">
                    ${reservation.payment.amount / 100}
                  </td>
                  <td className="border p-2 text-[#aca9a5] text-[14px] md:text-[16px]">
                    <button
                      onClick={() => ""}
                      className="bg-[#aca9a5] text-white py-1 px-2 rounded hover:bg-gray-500 transition"
                    >
                      Invoice
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default UserReservations;
