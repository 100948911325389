import Select from "react-select";

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
    borderColor: "#aca9a5",
    height: "50px",
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    padding: "10px",
    backgroundColor: state.isSelected ? "#f5f5f5" : "white",
    color: "black",
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
  }),
};

const CustomDropdown = ({ allCountryCodes, formData, setFormData }) => {
  const options = allCountryCodes.map((country) => ({
    value: country.dialCode,
    label: (
      <div className="flex items-center min-w-20">
        <img
          src={country.flag}
          alt={country.code}
          style={{ width: "15px", height: "15px", marginRight: "10px" }}
        />
        <span className="text-[12px] leading-none font-medium text-[#aca9a5]">{`${country.dialCode}`}</span>
      </div>
    ),
  }));

  const handleChange = (selectedOption) => {
    setFormData({ ...formData, countryCode: selectedOption.value });
  };

  return (
    <Select
      options={options}
      styles={customStyles}
      value={options.find((opt) => opt.value === formData.countryCode)}
      onChange={handleChange}
    />
  );
};

export default CustomDropdown;
