import React, { useState } from "react";
import logo from "../Assets/Vector Smart Object.png";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa6";
import axios from "axios";
import toast from "react-hot-toast";

const Footer = () => {
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const email = { email: e.target.email.value };
    try {
      setLoading(true);
      // Sending data to the backend
      const response = await axios.post(
        "https://365luxuryhomes.com/api/newsletter",
        email
      );

      if (response.status === 200) {
        toast.success("Your request has been sent successfully!");
        setLoading(false);
        e.target.email.value = "";
      } else {
        toast.error("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
      toast.error("Error submitting form. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <section className="bg-[#aaa9a5] text-white pt-10">
      <div className="container">
        <div className="grid lg:grid-cols-3 gap-4 mb-8">
          <div>
            <img src={logo} alt="" className="w-32 invert brightness-0" />
            <div className="flex items-start gap-2 mt-6 text-[#fff]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="text-inherit"
                fill={"none"}
              >
                <path
                  d="M13.6177 21.367C13.1841 21.773 12.6044 22 12.0011 22C11.3978 22 10.8182 21.773 10.3845 21.367C6.41302 17.626 1.09076 13.4469 3.68627 7.37966C5.08963 4.09916 8.45834 2 12.0011 2C15.5439 2 18.9126 4.09916 20.316 7.37966C22.9082 13.4393 17.599 17.6389 13.6177 21.367Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
                <path
                  d="M15.5 11C15.5 12.933 13.933 14.5 12 14.5C10.067 14.5 8.5 12.933 8.5 11C8.5 9.067 10.067 7.5 12 7.5C13.933 7.5 15.5 9.067 15.5 11Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
              </svg>
              <p className="leading-[24px] text-[16px] text-[#fff]">
                Bluewaters Island, Dubai
              </p>
            </div>
          </div>
          <div>
            <Link
              to={"/"}
              className="block text-white font-thin text-[16px] hover:no-underline hover:text-[#ddd] visited:text-[#fff]"
            >
              Home
            </Link>
            <Link
              to={"/about"}
              className="block text-[#fff] font-thin text-[16px] hover:no-underline hover:text-[#ddd] visited:text-[#fff]"
            >
              About Us
            </Link>
            <Link
              to={"/properties"}
              className="block text-[#fff] font-thin text-[16px] hover:no-underline hover:text-[#ddd] visited:text-[#fff]"
            >
              Properties
            </Link>
            <Link
              to={"/experiences"}
              className="block text-[#fff] font-thin text-[16px] hover:no-underline hover:text-[#ddd] visited:text-[#fff]"
            >
              Experiences
            </Link>
            <Link
              to={"/contact"}
              className="block text-[#fff] font-thin text-[16px] hover:no-underline hover:text-[#ddd] visited:text-[#fff]"
            >
              Contact Us
            </Link>
          </div>
          <div>
            <p className="font-medium text-[16px] mb-4 text-[#fff]">
              Subscribe Newsletter
            </p>
            <form onSubmit={handleSubmit}>
              <div className="flex items-center">
                <input
                  type="text"
                  name="email"
                  placeholder="Your Email"
                  className="h-[50px]
                  input border text-[15px] border-[#fff] bg-transparent placeholder:text-[#fff] w-full max-w-xs rounded-none focus:outline-none"
                />
                <button
                  type="submit"
                  className=" h-[50px]
                   bg-[#fff] hover:bg-[#ddd] px-4 text-base text-[#aaa9a5] font-normal rounded-none border-none"
                >
                  {loading ? (
                    <span className="loading loading-spinner loading-md"></span>
                  ) : (
                    "Subscribe"
                  )}
                </button>
              </div>
            </form>
            <div className="flex items-center gap-4 justify-end mt-4">
              <span className="text-[#fff] font-thin">Follow us</span>
              <div className="flex items-center gap-4">
                <Link
                  to={"https://www.facebook.com/365luxuryhomes.dxb"}
                  target="_blank"
                  className=" rounded-md text-[#fff] hover:text-[#ddd] text-[20px] focus:text-white active:text-white"
                >
                  <FaFacebookF />
                </Link>
                <Link
                  to={
                    "https://www.instagram.com/365luxuryhomes.dxb/profilecard/?igsh=MWh2dnZza3d4cHlsdw%3D%3D"
                  }
                  target="_blank"
                  className=" rounded-md text-[#fff] hover:text-[#ddd] text-[20px] focus:text-white active:text-white"
                >
                  <FaInstagram />
                </Link>
                <Link
                  to={
                    "https://www.linkedin.com/company/101251413/admin/dashboard/"
                  }
                  target="_blank"
                  className=" rounded-md text-[#fff] hover:text-[#ddd] text-[20px] focus:text-white active:text-white"
                >
                  <FaLinkedinIn />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t-[2px] border-[#fff] pt-4 pb-4 flex lg:flex-row md:flex-col flex-col items-center justify-between gap-2">
          <div className="flex items-center gap-4">
            <Link
              className="uppercase text-[#fff] text-xs hover:no-underline hover:text-[#ddd] visited:text-[#fff] focus:text-white active:text-white"
              to="/privacy-policy"
              rel="noopener noreferrer"
            >
              privacy Policy
            </Link>
            <div className="w-[1px] h-[10px] bg-[#fff]"></div>
            <Link
              className="uppercase text-[#fff] text-xs hover:no-underline hover:text-[#ddd] visited:text-[#fff] focus:text-white active:text-white"
              to={"/terms-and-conditions"}
              rel="noopener noreferrer"
            >
              Terms & conditions
            </Link>
          </div>
          <p className="text-xs">
            © 2025 365 Luxury Homes. All rights reserved.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
