import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";

// Create a custom icon for the marker
const customIcon = new L.Icon({
  // iconUrl: "https://i.ibb.co.com/fDq3wjp/Group-7.png", // Replace with your custom icon URL
  iconUrl: "https://i.ibb.co.com/fp1NLC0/Group-4.png", // Replace with your custom icon URL
  iconSize: [50, 50], // Adjust size as needed
  iconAnchor: [20, 40], // Anchor so it points correctly
});

const MapView = ({ lat, lng }) => {
  const position = [lat || 25.079539387772083, lng || 55.12148983415934]; // Default to Dubai if no coordinates are provided

  return (
    <MapContainer
      center={position}
      zoom={13}
      style={{ height: "400px", width: "100%", fontSize: "16px" }}
    >
      <TileLayer url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic2hhbWltczM5OSIsImEiOiJjbTJkMTZldWkwY3ZmMnJzYjY3ajB2aTJmIn0.bouAZxiGv_HbAPtsH0nm8A" />
      <Marker position={position} icon={customIcon}>
        <Popup>Property location.</Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapView;
