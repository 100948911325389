import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { getAccessToken } from "../Components/getAccessToken";
import toast from "react-hot-toast";

export const DataContext = createContext();

const Context = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [discount, setDiscountValue] = useState(null);

  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);

  const [listings, setListings] = useState([]);
  const [currentDatePrice, setCurrentDatePrice] = useState([]);
  const [loading, setLoading] = useState(true);
  const [calender, setCalender] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("365_user") || null)
  );

  useEffect(() => {
    const fetchListings = async () => {
      try {
        // Step 1: Get the Access Token from the Backend
        const tokenResponse = await axios.post(
          "https://365luxuryhomes.com/api/accessToken"
        );
        const accessToken = tokenResponse.data.access_token;

        // Step 2: Use the Token to Fetch Listings
        const listingsResponse = await axios.get(
          "https://api.hostaway.com/v1/listings",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        setListings(listingsResponse?.data?.result || []); // Adjust based on API response structure
      } catch (error) {
        // console.error("Error fetching listings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchListings();
  }, [setListings]);

  // Login Function
  const login = async (email, password) => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://365luxuryhomes.com/api/auth/login",
        {
          email,
          password,
        }
      );

      setUser(response.data);
      localStorage.setItem("365_user", JSON.stringify(response.data));
      toast.success("Login successful!");
      setLoading(false);
      document.getElementById("login_modal").close();
    } catch (error) {
      alert(error.response?.data?.message || "Login failed!");
      setLoading(false);
    }
  };

  // Register Function
  const register = async (formData) => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://365luxuryhomes.com/api/auth/register",
        formData
      );
      setUser(response.data);
      localStorage.setItem("365_user", JSON.stringify(response.data));
      toast.success("Registration successful!");
      setLoading(false);
    } catch (error) {
      alert(error.response?.data?.message || "Registration failed!");
      setLoading(false);
    }
  };

  // Logout Function
  const logout = () => {
    setUser(null);
    localStorage.removeItem("365_user");
  };

  const userInfo = {
    open,
    setOpen,
    listings,
    loading,
    login,
    register,
    logout,
    setListings,
    setLoading,
    user,
    setCalender,
    calender,
    currentDatePrice,
    setCurrentDatePrice,
    endDate,
    setEndDate,
    setStartDate,
    startDate,
    blogs,
    setBlogs,
    discount,
    setDiscountValue,
  };
  return (
    <DataContext.Provider value={userInfo}>{children}</DataContext.Provider>
  );
};

export default Context;
